#mega-menu {

    background-color: $color-white;
    border-top: 1px solid $color-ice;
    border-bottom: 1px solid $color-ice;

    @include media-breakpoint-up(xs) {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        display: block;
    }

        position: relative;
        
        #categories-menu-list {
            max-width: 800px;
            width: $percent-100;
            margin-left: auto;
            margin-right: auto;
            padding-left: 15px;
            padding-right: 15px;

            ul.categories-menu-list {

                display: flex;
                align-items: center;
                justify-content: center;

                li {

                    padding: 0 1.5625rem;

                    &:first-child {
                        margin-left: 0;
                    }

                    

                    a {
                        font-size: 0.75rem;
                        font-weight: $fw700;
                        color: $color-blue;
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 3.125rem;

                        .category-name {
                            font-family: $font-nunito !important;
                        }

                        .icon {
                            margin-left: 0.375rem;
                            
                            svg {
                                height: 13px;
                                width: auto;
                                -moz-transform: rotate(0deg);
                                -webkit-transform: rotate(0deg);
                                -o-transform: rotate(0deg);
                                -ms-transform: rotate(0deg);
                                transform: rotate(0deg);
                                -webkit-transition: all 0.25s ease-in-out;
                                -moz-transition: all 0.25s ease-in-out;
                                -ms-transition: all 0.25s ease-in-out;
                                -o-transition: all 0.25s ease-in-out;
                                transition: all 0.25s ease-in-out;

                                path {
                                    fill: $color-blue;
                                }
                            }
                        }

                    }


                    
                    .wrap-main-list {
                        background-color: #FCFCFC;
                        padding: 0.9375rem 0;
                        position: absolute;
                        top: 48px;
                        left: 0;
                        height: auto;
                        width: $percent-100;
                        z-index: 9999;
                        -webkit-box-shadow: 0 8px 10px 4px rgba(100,100,100,0.1);
                        box-shadow: 0 8px 10px 4px rgba(100,100,100,0.1);
                        // display: none;
                        visibility: hidden;
                        -prefix-animation: slide 1s ease 3.5s forwards;
                        
                        
                        
                        .main-list {
                        width: $percent-100;
                        max-width: 780px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        margin: 0 auto;
            
                        .wrapper {
                        .mega-menu-content {
                            max-width: 800px;
                            width: $percent-100;
                            margin-left: auto;
                            margin-right: auto;
                            padding-left: 15px;
                            padding-right: 15px;
                            // display: flex;
                            align-items: flex-start;
                            justify-content: flex-start;
                            
                            .list {
                                padding-left: 15px;
                                padding-right: 15px;
                                min-width: 180px;
                                
                                &:first-child {
                                    margin-left: 0;
                                }
                                
                                &:last-child {
                                    padding-right: 0;
                                }
            
                                .menu-title {
                                    font-size: 0.75rem;
                                    font-weight: $fw700;
                                    color: $color-blue;
                                    font-family: $font-nunito !important;
                                    text-transform: uppercase;
                                    letter-spacing: 0.125rem;
                                    margin-bottom: 0.875rem;
                                }
            
                                ul {
                                    display: block;
                                    li {
                                        padding: 0;
                                        margin-left: 0;
                                        a {
                                            font-size: 0.8125rem;
                                            font-weight: $fw700;
                                            color: $color-black;
                                            opacity: 0.9;
                                            display: inline-block;
                                            margin-bottom: 0.5rem;
                                            text-transform: none;
                                            height: auto;
                                        }
                                        
                                        &:last-child {
                                            font-family: $font-nunito !important;
                                            
                                            a {
                                                font-size: 0.875rem;
                                                opacity: 1;
                                                color: $color-blue;
                                            }
                                        }
                                    }
                                    
                                }
                            }
                        }
                        }
                    }
                    }

                    @keyframes anim-menu {
                        from { opacity: 0; top: 0;}
                        to {opacity: 1; top: 48px;}
                    }

                    &:hover {
                        .wrap-main-list {
                            display: block;
                            visibility: visible;
                            animation-name: anim-menu;
                            animation-duration: 0.30s;
                        }

                        a {
                                .icon {
    
                                    svg {
                                        -moz-transform: rotate(180deg);
                                        -webkit-transform: rotate(180deg);
                                        -o-transform: rotate(180deg);
                                        -ms-transform: rotate(180deg);
                                        transform: rotate(180deg);
                                        -webkit-transition: all 0.25s ease-in-out;
                                        -moz-transition: all 0.25s ease-in-out;
                                        -ms-transition: all 0.25s ease-in-out;
                                        -o-transition: all 0.25s ease-in-out;
                                        transition: all 0.25s ease-in-out;
                                    }
                                }
                        }
                        
                    }

                    &:nth-child(3) {
                        
                        .wrap-main-list {

                            .main-list {
                                max-width: 990px;

                                .wrapper {

                                    .mega-menu-content {

                                        .list {

                                            ul {
                                                .wrapper {
                                                    margin-bottom: 30px;

                                                    .mega-menu-content {
                                                        padding: 0;

                                                        .list {
                                                            padding-left: 0;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        #panel-mega-menu {
            background-color: #FCFCFC;
            padding: 0.9375rem 0;
            position: absolute;
            top: 60px;
            left: 0;
            height: auto;
            width: $percent-100;
            z-index: 99;
            -webkit-box-shadow: 0 8px 10px 4px rgba(100,100,100,0.1);
            box-shadow: 0 8px 10px 4px rgba(100,100,100,0.1);

            .mega-menu-content {
                max-width: 800px;
                width: $percent-100;
                margin-left: auto;
                margin-right: auto;
                padding-left: 15px;
                padding-right: 15px;
                // display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                
                .list {
                    padding-left: 15px;
                    padding-right: 15px;
                    min-width: 180px;
                    
                    &:first-child {
                        margin-left: 0;
                    }
                    
                    &:last-child {
                        padding-right: 0;
                    }

                    .menu-title {
                        font-size: 0.75rem;
                        font-weight: $fw700;
                        color: $color-blue;
                        font-family: $font-nunito !important;
                        text-transform: uppercase;
                        letter-spacing: 0.125rem;
                        margin-bottom: 0.875rem;
                    }

                    ul {
                        li {
                            a {
                                font-size: 0.8125rem;
                                font-weight: $fw700;
                                color: $color-black;
                                opacity: 0.9;
                                display: inline-block;
                                margin-bottom: 6px;
                            }
                            
                            &:last-child {
                                font-family: $font-nunito !important;
                                
                                a {
                                    font-size: 0.875rem;
                                    opacity: 1;
                                    color: $color-blue;
                                }
                            }
                        }
                    }
                }
            }
        }
    
}