#view_search {
  // position: fixed;
  // top: 0;
  // left: 0;
  height: $percent-100;
  width: $percent-100;
  background-color: $color-white;
  z-index: 999;
  overflow-x: hidden;

  ._wrap-search {
    height: $percent-100;
    margin-top: 15px;

    ._header {
      position: relative;
      height: 50px;
      // box-shadow: 0 1px 8px 4px rgba(120, 120, 120, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;

      button.btn {
        max-width: none;
        width: auto;
        height: 50px;
        width: 38px;
        position: absolute;
        left: 0;
        top: 0;
        display: none;

        svg {
          width: 8px;
          height: auto;

          path {
            fill: $color-gray;
          }
        }
      }

      ._title-view {
        text-align: center;
        font-weight: $fw700;
        font-size: 14px;
        color: $color-gray-bolder;
      }

    }

    ._form {
      .form-group {
        margin-bottom: 0;
        ._wrap-input-group {
          border-bottom: 1px solid $color-border;

          .input-group {
            .input-group-prepend {

                padding-left: 5px;
                padding-right: 5px;

              .input-group-text {
                border: 0;
                padding-right: 0.75rem;
              }
            }

            .form-control {
              border: 0;

              height: calc(1.462em + 0.75rem + 21px);

              &:focus,
              &:active {
                border: 0 !important;
              }
            }
          }
        }
      }

      ._suggestions-wrapper {
        ._suggestions-list {
          li {
            border-bottom: 1px solid $color-ice;

            ._suggestions-list-item {
              @include padding-lt-rt;
              padding-top: 10px;
              padding-bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              ._wrap-suggestion {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                ._icon-left {
                  svg {
                    width: 14px;
                    height: auto;

                    path {
                      fill: $color-gray-bolder;
                      opacity: 0.7;
                    }
                  }
                }

                ._suggestion {
                  font-size: 13px;
                  font-weight: $fw400;
                  color: $color-gray;
                  margin-left: 10px;

                  span.bold {
                    font-weight: $fw700;
                    color: $color-black;
                  }
                }
              }

              ._icon-right {
                svg {
                  width: 14px;
                  height: auto;

                  path {
                    fill: $color-gray-bolder;
                    opacity: 0.7;
                  }
                }
              }
            }
          }
        }
      }
    }

    ._wrap-categories {
      @include padding-lt-rt;
      margin-right: 15px;
      margin-top: 15px;

      .row {
        .col-6 {
          padding-right: 0;
          padding-bottom: 15px;

          ._category-search-item {
            display: block;
            border: 1px solid $color-ice;
            border-radius: 5px;
            height: 156px;
            overflow: hidden;

            ._item-image {
              overflow: hidden;
              height: 120px;
              background-color: $color-ice;
              display: flex;
              justify-content: center;

              img {
                height: auto;
                width: 100%;
              }
            }

            ._item-footer {
              font-size: 12px;
              font-weight: $fw700;
              color: $color-black;
              text-align: center;
              padding: 8px 0;
            }
          }
        }

        .col-6 {
          &:nth-child(1) {
            ._category-search-item {
              ._item-image {
                background-color: $color-red;
                align-items: center;

                img {
                  width: 105px;
                }
              }
            }
          }

          &:nth-child(2) {
            ._category-search-item {
              ._item-image {
                background-color: $color-blue;
                align-items: center;

                img {
                  width: 80px;
                }
              }
            }
          }

          &:nth-child(3) {
            ._category-search-item {
              ._item-image {
                background-color: $color-violet;
                align-items: flex-end;

                img {
                  width: 50px;
                }
              }
            }
          }

          &:nth-child(4) {
            ._category-search-item {
              ._item-image {
                background-color: $color-orange;
                align-items: flex-end;

                img {
                  width: 55px;
                }
              }
            }
          }

          &:nth-child(5) {
            ._category-search-item {
              ._item-image {
                background-color: $color-yellow;

                align-items: center;

                img {
                  width: 65px;
                }
              }
            }
          }

          &:nth-child(6) {
            ._category-search-item {
              ._item-image {
                background-color: $color-blue-light;

                align-items: center;

                img {
                  width: 65px;
                }
              }
            }
          }
        }
      }
    }
  }
}
