form {

    .MuiFormControl-root {
        
            &:hover {
                border-width: 0 !important;
            }

        .MuiFormLabel-root {
            font-size: 0.8rem;
            &:hover {
                border-width: 0 !important;
            }
            

        }

        .MuiFormLabel-root.Mui-focused {
            color: #858589;
        }
    }

}

.MuiOutlinedInput-notchedOutline {
    border-color: $color-border !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 107, 107, 0.1) !important;
    border-width: 1px !important;
}


.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 107, 107, 0.1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-marginDense {
    @include media-breakpoint-up(md) {
        transform: translate(14px, 13px) scale(1);
    }
}

.MuiOutlinedInput-inputMarginDense {
    @include media-breakpoint-up(md) {
        padding-top: 11.9px !important; // Default 10.5
        padding-bottom: 11.5px !important; // Default 10.5
    }
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    @include media-breakpoint-up(md) {
        padding: 0px !important; // Default 6px
    }
}

.MuiInputAdornment-root.MuiInputAdornment-positionStart {
    svg {
        height: 14px;
        width: auto;

        path {
            fill: $color-gray;
        }
    }
}

.MuiOutlinedInput-root-287.MuiOutlinedInput-error-292 .MuiOutlinedInput-notchedOutline-294 {
    border-color: #c2403f !important;
}

.MuiFormLabel-root-280.MuiFormLabel-error-283 {
    color: #c2403f !important;
}

.MuiFormLabel-root.Mui-error {
    color: #c2403f !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #c2403f !important;
}

.MuiInputBase-root {
    font-size: 0.875rem !important;
}

.MuiInputLabel-formControl {
    @include media-breakpoint-up(md) {
        top: 2px !important;
    }
}

// Testing Inputs Size

.MuiInputLabel-formControl {
    top: 2px !important;
}

.MuiInputBase-input {
    // height: 1.500em !important;
}

.MuiInputLabel-outlined {
    //transform: translate(14px, 16px) scale(1);
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
    padding-left: 12px !important;
}

.MuiFormLabel-root {
    font-weight: $fw400 !important;
}

.MuiFilledInput-root.Mui-disabled {
    background-color: $color-white !important;
}

.MuiFilledInput-underline.Mui-disabled:before,
.MuiInput-underline.Mui-disabled:before {
    border-bottom: 0 !important;
}

.MuiFormLabel-root {
    // font-family: $font-comfortaa !important;
    // font-weight: $fw600 !important;
}