#view_messenger {
  max-width: $med-container;
  @include push--auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include media-breakpoint-up(xs) {
    padding-top: 30px;
  }

  @include media-breakpoint-up(md) {
    padding-top: 40px;
    @include padding-lt-rt;
    padding-bottom: 80px;
  }

  ._wrap-messages {
    width: 100%;

    .tab-content {
      ._action-buttons {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

#view_product,
#view_messenger {
  ._wrap-messenger._max-width {
    @include media-breakpoint-up(lg) {
      min-width: 620px;
    }
    @include media-breakpoint-up(xl) {
      min-width:  620px //720px;
    }
  }

  ._wrap-messenger {
    width: 100%;
    height: $percent-100;
    background-color: $color-white;
    border: 1px solid $color-ice-bold;
    border-top-color: #dee2e6;

    @include media-breakpoint-up(lg) {
      margin-top: 39px;
    }

    ._chat-header {
      border-bottom: 1px solid $color-ice;
      background-color: $color-white;

      ._cols {
        display: flex;
        align-items: center;
        justify-content: space-between;

        ._col._col-left {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 10px;

          ._back {
            max-width: 30px;
            width: 100%;

            button {
              max-width: none;
              width: auto;
              padding: 0.282rem 0.482rem;
              padding-left: 0;

              svg {
                height: 16px;
                width: auto;

                path {
                  fill: $color-gray;
                }
              }
            }
          }

          ._current-user {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: 10px;

            .username {
              display: inline-block;
              font-size: 13px;
              font-weight: $fw700;
              color: $color-gray-bolder;
              margin-left: 6px;
              white-space: nowrap;
              width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        ._col._col-right {
          ._chat-config {
            position: relative;
            padding: {
              right: 5px;
              top: 10px;
              bottom: 10px;
            }

            button {
              max-width: none;
              width: auto;
              padding: 0.282rem 0.482rem;
              padding-left: 0;

              svg {
                height: 16px;
                width: auto;

                path {
                  fill: $color-gray;
                }
              }
            }

            ._dropdown-menu {
              padding: 10px;
              width: 160px;
              top: 45px;
              left: auto;
              right: 0;
              z-index: map-get($z-index, messengerDropdown);

              &::before,
              &:after {
                left: auto;
                right: 7px;
              }

              ._list-nav {
                ul {
                  li {
                    a {
                      font-size: 10px;
                    }

                    &:last-child {
                      a {
                        color: $color-red;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    ._chat-product {
      border-bottom: 1px solid $color-ice-bold;
      background-color: $color-white;
      position: relative;

      ._cols {
        ._col._col-left {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          ._product {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 10px;

            .product-image {
              height: 40px;
              width: 40px;
              overflow: hidden;
              border-radius: 4px;

              img {
                width: auto;
                height: 100%;
              }
            }

            ._product-info {
              margin-left: 10px;

              ._product-tag {
                font-size: 11px;
                color: $color-gray-bolder;

                ._tag {
                  display: inline-block;
                  color: $color-white;
                  padding: 0 0.537rem;
                  border-radius: 4px;
                  margin-right: 5px;
                }

                ._tag.proposal {
                  background-color: $color-nude;
                }

                ._tag.purchase {
                  background-color: $color-green;
                }

                ._tag.doubt {
                  background-color: $color-red;
                }

                ._tag-username {
                  display: inline-block;
                  margin-left: 5px;
                  font-weight: $fw700;
                }
              }

              ._product-item {
                font-size: 11px;
                color: $color-gray-bolder;
                display: flex;
                align-items: flex-end;

                ._product-item-tag {
                  display: inline-block;
                  font-size: 13px;
                  font-weight: $fw700;
                  white-space: nowrap;
                  width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  position: relative;
                  top: 1px;
                  margin-left: 3px;
                }
              }

              ._product-price {
                .ui-item__price {
                  .price-tag-fraction {
                    font-size: 16px;
                  }
                }
              }
            }
          }

          ._wrap-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $color-white;
            z-index: map-get($z-index, messengerProposal);

            @include media-breakpoint-up(xs) {
              position: absolute;
              top: 60px;
              right: 10px;
              padding: {
                top: 2px;
                bottom: 2px;
                left: 15px;
                right: 6px;
              }
              border: 1px solid $color-ice;
              -webkit-box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.04);
              box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.04);
              min-width: 158px;
              border-radius: 100px;
            }

            @include media-breakpoint-up(lg) {
              // position: relative;
              // top: inherit;
              // right: inherit;
              // border: 0;
              // box-shadow: none;
              // padding: 10px 15px;
            }

            ._proposal-value {
              font-size: 10px;
              font-weight: $fw700 !important;
              color: rgba(61, 61, 64, 0.8);
              margin-right: 10px;

              .hide-this {
                @include media-breakpoint-up(xs) {
                  display: none;
                }

                @include media-breakpoint-up(lg) {
                  display: inline-block;
                }
              }

              ._product-price._1x {
                margin-top: 0;

                .ui-item__price {
                  .price-tag-fraction {
                    @include media-breakpoint-up(xs) {
                      font-size: 14px !important;
                    }

                    @include media-breakpoint-up(lg) {
                      font-size: 16px !important;
                    }
                  }
                }
              }
            }

            ._actions {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              background-color: transparent;

              button.btn {
                max-width: none;
                width: auto;
                background-color: $color-gray;
                font-size: 10px;
                margin-left: 10px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include media-breakpoint-up(xs) {
                  width: 36px;
                  height: 36px;
                }

                @include media-breakpoint-up(lg) {
                  width: 40px;
                  height: 40px;
                }

                svg {
                  width: auto;

                  @include media-breakpoint-up(xs) {
                    height: 16px;
                  }

                  @include media-breakpoint-up(lg) {
                    height: 16px;
                  }

                  path {
                    fill: $color-white;
                  }
                }

                &:first-child {
                  margin-left: 0;
                }
              }

              button.btn.decline {
                background-color: $color-red;
              }

              button.btn.accept {
                background-color: $color-green;
              }

              button.btn.edit {
                background-color: $color-blue;
              }
            }
          }

          ._wrap-actions._link-to-buy {
            flex-direction: row-reverse;
            padding-right: 15px;
            padding-left: 6px;
            background-color: $color-red;
            cursor: pointer;

            ._proposal-value {
              margin-right: 0;
              margin-left: 10px;
              font-size: 13px;
              color: $color-white;

              .sub-text {
                font-size: 10px;
              }
            }

            ._actions {
              button.btn.accept {
                background-color: $color-white;

                svg {
                  path {
                    fill: $color-green;
                  }
                }
              }
            }
          }
        }
      }
    }

    ._chat-conversation {
      padding: {
        top: 42px;
        left: 15px;
        right: 15px;
        bottom: 30px;
      }
      width: 100%;
      background-color: #f9f9f9;
      overflow: hidden;
      overflow-y: scroll;
      
      @include media-breakpoint-up(xs) {
        height: calc(100% - 217px);
      }
      
      @include media-breakpoint-up(md) {
        height: 400px;
      }

      .wrap-messages {
        width: $percent-100;

        .message-item {
          display: flex;
          width: $percent-100;
          margin-bottom: 5px;

          .message {
            -webkit-box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.01);
            box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.01);
            display: table;
            color: $color-white;
            padding: 0.5rem;
            border-radius: 6px;
            font-weight: $fw700;
            font-size: 11.9px;
            max-width: 200px;
          }
        }

        .message-item.left {
          justify-content: flex-start;

          .message {
            background-color: $color-white;
            color: $color-gray-bolder;
          }
        }
        .message-item.right {
          justify-content: flex-end;

          .message {
            color: $color-white;
            background-color: $color-blue;
          }
        }
      }

      // Message Success ***

      .wrap-messages.success {
        .message-item {
          .message {
            display: block;
            max-width: 300px;
            width: $percent-100;
            background-color: $color-white;
            padding: 0;

            .message-head {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid #f9f9f9;
              padding: 0.3rem 0.5rem;
              width: $percent-100;

              ._left {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .icon {
                  height: 28px;
                  width: 28px;
                  min-width: 28px;
                  background-color: $color-green;
                  border-radius: 100px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg {
                    width: 14px;
                    height: auto;

                    path {
                      fill: $color-white;
                    }
                  }
                }

                .text {
                  font-size: 12px;
                  font-weight: $fw700;
                  color: $color-gray-bolder;
                  margin-left: 8px;
                }
              }
            }

            .message-content {
              ._product {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                padding: {
                  top: 15px;
                  left: 15px;
                  right: 15px;
                  bottom: 5px;
                }

                ._product-image {
                  height: 40px;
                  width: 40px;
                  border-radius: 4px;
                  border: 1px solid $color-ice;
                  overflow: hidden;

                  img {
                    width: auto;
                    height: $percent-100;
                  }
                }

                ._product-info {
                  margin-left: 10px;
                  ._store-title,
                  ._product-title,
                  ._product-price {
                    color: $color-gray-bolder;
                    font-weight: $fw700;
                  }

                  ._store-title {
                    font-size: 11px;
                    margin-bottom: 2px;
                  }

                  ._product-title {
                    font-size: 13px;
                  }

                  ._product-price {
                    margin-top: 0;
                  }
                }
              }
            }

            .message-link {
              border-top: 1px solid #f9f9f9;

              a {
                padding: 0.45rem 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $color-white;
                font-size: 12px;
                text-align: center;
                font-weight: $fw700;
                background-color: $color-red;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;

                ._right {
                  svg {
                    width: 6px;
                    height: auto;
                    position: relative;
                    top: 2px;

                    path {
                      fill: $color-white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    ._chat-footer {
      ._chat-send {
        ._wrap-send {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding-top: 10px;

          .send-text {
            width: 100%;
            padding: 5px 0;

            .form-control {
              width: 100%;
              font-size: 13px;
              font-weight: $fw700;
              color: $color-gray-bolder;
              border: 0;

              &:focus {
                border: 0 !important;
              }

              &::placeholder {
                color: $color-gray;
              }
            }
          }

          .send-message {
            width: 44px;
            button {
              max-width: none;
              width: auto;
              background-color: $color-white;

              svg {
                width: 20px;
                height: auto;
                path {
                  fill: $color-gray;
                }
              }
            }
          }
        }
      }

      ._chat-attachments {
        ._wrap-attachments {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .attachment-file {
            .attachment {
              .form-control-file {
                display: none;
                visibility: hidden;
              }

              button {
                max-width: none;
                width: auto;

                svg {
                  width: auto;
                  height: 18px;
                  path {
                    fill: $color-gray;
                    opacity: 0.7;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#view_messenger {
  //   position: relative;
  z-index: map-get($z-index, messenger);
  overflow: hidden;

  ._wrap-messenger {
    @include media-breakpoint-up(xs) {
      right: 0;
      max-width: none;
      width: $percent-100;
      position: fixed;
      top: 0;
      left: auto;
      margin-top: 0;
      background-color: $color-ice;
    }
    
    @include media-breakpoint-up(xs) {
      z-index: map-get($z-index, messengerProposal);
    }
    
    @include media-breakpoint-up(lg) {
      z-index: map-get($z-index, messengerProposalMob);
      //   right: 15px;
      height: $percent-100;
      max-width: 770px;
      position: relative;
      margin-top: 39px;
    }

    form._chat-form {
      position: relative;
      height: $percent-100;
      padding-bottom: 0 !important;

      ._chat-header {
        background-color: $color-white;

        ._cols {
          ._col._col-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }

      ._chat-content {
        position: relative;
        overflow-x: hidden;
        border-width: 1px 1px 1px 0;
        border-style: solid;
        border-color: #e6e6e6;
        height: calc(100% - 226px);
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;

        ._chat-messages {
          .message-date {
            padding-top: 20px;
          }
        }
      }

      ._chat-footer {
        width: 100%;
        height: 80px;
        background-color: $color-white;
        border-top: 1px solid $color-ice;

        &::before {
          content: "";
          display: block;
          position: relative;
        }

        @include media-breakpoint-up(xs) {
          //   position: absolute;
        }

        @include media-breakpoint-up(md) {
          position: relative;
        }

        ._chat-send {
          height: 100%;

          ._wrap-send {
            height: 100%;
          }
        }
      }
    }
  }
}

// Message Bubble

._wrap-chat-messages {
  .chat-message-bubble {
    background-color: $color-white;
    border-radius: 6px;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.09);
    color: $color-black;
    font-size: 12px;
    font-weight: $fw700;
    max-width: 340px;
    width: auto;
    padding: 10px;
    display: table;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .chat-message-bubble.received {
    border-top-left-radius: 0;
  }

  .chat-message-bubble.sent {
    border-top-right-radius: 0;
    margin: 0 auto;
    margin-right: 15px;
    margin-bottom: 5px;
  }
}

// Message Item

// #wrap-messages-items {
//   ._action-buttons {
//     padding-left: 0;
//     padding-right: 0;
//   }

._wrap-message-item {
  background-color: $color-white;
  border-bottom: 1px solid $color-ice;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  overflow: hidden;

  ._message-item,
  a._message-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;

    ._message-image {
      margin-right: 12px;
      .user-image {
        ._wrap-image {
          width: 50px;
          height: 50px;
          min-width: 50px;
          min-height: 50px;
          overflow: hidden;
          border-radius: 100px;
          img {
            width: auto;
            height: $percent-100;
            border-radius: 0;
          }
        }
      }
    }

    ._message-content {
      padding-right: 10px;

      ._content {
        ._message-status {
          color: $color-gray-bolder;
          font-weight: $fw700;
          font-size: 12px;

          ._tag {
            display: inline-block;
            color: $color-white;
            padding: 0.032rem 0.537rem;
            border-radius: 4px;
            margin-right: 5px;
            min-width: 121px;
            max-width: 123px;
            width: $percent-100;
            text-align: center;
          }

          ._tag.proposal {
            background-color: $color-nude;
          }

          ._tag.doubt {
            background-color: $color-violet;
          }

          ._tag.purchase {
            background-color: $color-green;
          }
        }

        ._message-date {
          font-size: 12px;
          font-weight: $fw700;
          color: $color-gray;
          margin: 2px 0;
        }

        ._message-title {
          font-size: 13px;
          color: $color-gray;
          font-weight: $fw700;
        }

        ._message-text {
          font-size: 13px;
          color: $color-gray-bolder;

          ._message-username {
            text-transform: capitalize;
            font-weight: $fw700;
          }

          ._the-message {
            margin: 0 4px 0 0;
          }

          ._message-product-title {
            font-weight: $fw700;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @media (min-width: 290px) {
              width: 204px;
            }

            @media (min-width: 350px) {
              width: 220px;
            }

            @media (min-width: 520px) {
              width: auto;
            }
          }
        }

        ._message-text._news {
          ._the-message {
            font-size: 12px;
          }
        }
      }
    }
  }

  ._actions {
    button {
      svg {
        width: auto;
        height: 18px;

        path {
          fill: #cccccc;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    ._actions {
      position: relative;
      right: -100px;
      opacity: 0;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      ._actions {
        display: block;
        right: 0;
        opacity: 1;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
// }

// Add margin to message date
._chat-content > ._wrap-proposal-accepted > ._chat-messages > .message-date {
  margin-top: 50px;
}
