#view_shopping-cart {
    background-color: $color-white;

    ._title-box {
        border-bottom: 1px solid #F9FAFE;
    }

    ._advertiser-card {
        border-bottom: 1px solid #F1F1F1;
        padding: 15px;
    }

    .shopping-cart-items {

        ._product-thumbnail {
            border-bottom: 1px solid #F1F1F1;
            padding: 15px;

            ._col {

                ._product-info {

                    .title {
                        margin-bottom: 2px;
                    }
                }
            }
        }

    }

    .checkout-button {
        
        text-align: center;
        padding: 1rem 0;

        button {
            svg {
                margin-left: 8px;

                path {
                    fill :$color-white;
                }
            }
        }
    }

}