#view_store {
  background-color: $color-white;
  padding-bottom: 60px;

  #view_my-account-profile--header {
    margin: 0;
    ._hero {
      @include media-breakpoint-up(xs) {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 30px;
      }
    }

  }

  ._store-content {
    max-width: $med-container;
    @include push--auto;
    @include padding-lt-rt;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include media-breakpoint-up(xs) {
      flex-direction: column;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    ._wrap-filters {
      overflow: hidden;
      width: 100%;

      @include media-breakpoint-up(lg) {
        overflow: auto;
        width: auto;
      }

      ._filters-title {
        color: $color-black;
        font-weight: $fw700;
        margin-bottom: 15px;

        @include media-breakpoint-up(xs) {
          font-size: 14px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 15px;
        }
      }

      ._form._sidebar {
          @include media-breakpoint-up(xs) {
            overflow-x: scroll;
            }
        @include media-breakpoint-up(md) {
          overflow-x: hidden;
          }
      }
    }

    ._store-products {
      width: 100%;

      ._products {
        width: 100%;

        .products-loader {
          margin-top: 15px;
        }
      }

      ._wrap-load-more {
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}
