#view_product {
  background-color: $color-white;
  position: relative;

  @include media-breakpoint-up(xs) {
    padding-top: 0;
  }

  #breadcrumbs {
    @include media-breakpoint-up(xs) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .layout-main,
  .layout-main-informations {
    .layout-col.layout-col-left,
    .layout-col.layout-col-right {
      background-color: $color-white;
    }

    .layout-col.layout-col-left {
      width: 100%;
      position: relative;
      overflow: hidden !important;

      @include media-breakpoint-up(xs) {
        z-index: 0;
        max-width: 100%;
      }
      @include media-breakpoint-up(lg) {
        z-index: 1;
        max-width: 576px;
        min-width: 511px;
      }
    }

    .layout-col.layout-col-right {
      width: 100%;

      @include media-breakpoint-up(xs) {
        max-width: 100%;
      }
      @include media-breakpoint-up(lg) {
        max-width: 483px;
      }
    }
  }

  .layout-main {
    display: flex;
    max-width: $med-container;
    @include push--auto;

    @include media-breakpoint-up(xs) {
      flex-direction: column;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      @include padding-lt-rt;
    }

    .layout-col.layout-col-left {
      @include media-breakpoint-up(lg) {
        border-right: 1px solid $color-ice;
        padding-right: 30px;
      }

      ._wrap-product-gallery {
        position: relative;

        @include media-breakpoint-up(lg) {
          padding-bottom: 15px;
        }

        .go-back {
          position: absolute;
          left: 0;
          top: 0;
          z-index: map-get($z-index, z-default);

          @include media-breakpoint-up(xs) {
            display: block;
          }

          @include media-breakpoint-up(lg) {
            display: none;
          }

          a {
            svg {
              width: 18px;
              height: auto;

              path {
                fill: $color-white;
                -webkit-box-shadow: 4px 0 10px 6px rgba(200, 200, 200, 0.5);
                box-shadow: 4px 0 10px 6px rgba(200, 200, 200, 0.5);
              }
            }
          }
        }

        .ImageGallery {
          @include media-breakpoint-up(xs) {
            display: none;
          }

          @include media-breakpoint-up(lg) {
            display: block;
          }

          .slick-slider.product-gallery {
            @include media-breakpoint-up(lg) {
              padding-right: 30px;
              padding-left: 0;
            }

            .slick-list {
              .slick-track {
                margin: 0;

                .slick-slide {
                  div {
                    .product-gallery-item {
                      // width: 576px !important;
                      height: auto;
                      display: inline-block;
                      position: relative;
                      overflow: hidden;
                      border-radius: 4px;

                      img {
                        height: 100%;

                        @include media-breakpoint-up(xs) {
                          width: 100%;
                        }

                        @include media-breakpoint-up(lg) {
                          width: $percent-100;
                        }
                      }
                    }
                  }
                }
              }
            }

            .slick-dots {
              bottom: 30px;

              @include media-breakpoint-up(xs) {
                display: block !important;
              }

              @include media-breakpoint-up(lg) {
                display: none !important;
              }
            }
          }
        }

        .slider-thumbnails {
          margin-top: 10px;

          @include media-breakpoint-up(xs) {
            display: none !important;
          }

          @include media-breakpoint-up(lg) {
            display: block !important;
          }

          .slick-list {
            .slick-track {
              .slick-slide {
                width: 50px !important;
                height: 50px !important;
                margin-left: 10px;
                overflow: hidden;
                border-radius: 5px;
                opacity: 0.8;

                &:first-child {
                  margin-left: 0;
                }

                .slider-thumbnail-item {
                  background-color: rebeccapurple;
                  height: $percent-100;
                  width: $percent-100;
                  overflow: hidden;
                  cursor: pointer;
                  border: 1px solid #f9f9f9;
                  border-radius: 6px;

                  img {
                    height: 100%;

                    @include media-breakpoint-up(xs) {
                      width: 100%;
                    }

                    @include media-breakpoint-up(lg) {
                      width: $percent-100;
                    }
                  }
                }
              }

              .slick-slide.slick-current {
                border-bottom: 4px solid $color-nude;
                opacity: 1;
              }
            }
          }
        }

        .SlickSliderRoot {
          @include media-breakpoint-up(xs) {
            display: block;
          }

          @include media-breakpoint-up(lg) {
            display: none;
          }
          
          .slider-container.product-gallery {
            .slick-list {
              .slick-track {
                .slick-slide {
                  .item {
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      border: 0;
                      max-width: 100%;
                      display: inline-block;
                      vertical-align: middle;
                    }
                  }
                }
              }
            }

            .slick-dots {
              bottom: 35px;
            }
          }
        }
      }
    }

    .layout-col.layout-col-right {
      border-bottom: 1px solid $color-ice;
      padding-bottom: 30px;

      @include media-breakpoint-up(xs) {
        @include padding-lt-rt;
        padding-top: 20px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        -webkit-box-shadow: 0 -4px 10px 2px rgba(150, 150, 150, 0.1);
        box-shadow: 0 -4px 10px 2px rgba(150, 150, 150, 0.1);
        position: relative;
        margin-top: -30px;
        z-index: map-get($z-index, column-mobile);
      }

      @include media-breakpoint-up(lg) {
        padding-left: 30px;
        box-shadow: none;
        margin-top: 0;
        z-index: inherit;
      }

      ._buttons-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .share {
          position: relative;

          button {
            max-width: none;
            width: 24px;
            padding: 0;

            svg {
              width: auto;
              height: 15px;

              path {
                fill: $color-blue;
              }
            }
          }

          ._social-share-list {
            border: 1px solid #f1f1f1;
            background-color: $color-white;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            top: -40px;
            left: -42px;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            -webkit-box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.1);
            box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.1);
            border-radius: 100px;
            min-width: 110px;
            padding: 0 5px;

            li {
              width: 30px;
              height: 30px;
              background-color: $color-white;
              display: flex;
              align-items: center;
              justify-content: center;

              &:first-child {
                border-top-left-radius: 100px;
                border-bottom-left-radius: 100px;
              }

              &:last-child {
                border-top-right-radius: 100px;
                border-bottom-right-radius: 100px;
              }

              a {
                svg {
                  height: 14px;
                  width: auto;

                  path {
                    fill: $color-blue;
                    opacity: 0.7;
                  }
                }

                &:hover {
                  svg {
                    path {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }

        button.action {
          width: auto;
          padding: 0.5rem 0.8rem;
          background: $color-white;
          line-height: 0;
          position: relative;

          &:last-child {
            padding-right: 0.4rem;
          }

          svg {
            width: auto !important;
            height: 18px !important;
            -webkit-transition: all 0.15s ease-in-out;
            -moz-transition: all 0.15s ease-in-out;
            -ms-transition: all 0.15s ease-in-out;
            -o-transition: all 0.15s ease-in-out;
            transition: all 0.15s ease-in-out;

            path {
              fill: $color-red;
              opacity: 0.7;
            }
          }

          .check {
            background-color: $color-blue;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 16px;
            width: 16px;
            border-radius: 100px;
            position: absolute;
            top: 4px;
            right: -1px;
            z-index: 1;

            svg {
              width: 8px !important;
              height: auto;
              position: relative;

              path {
                fill: $color-white;
              }
            }
          }

          &:first-child {
            svg {
              width: auto;
              height: 16px !important;
            }
          }
        }

        button.action.active {
          svg {
            transform: scale(1.1) !important;
            -webkit-transition: all 0.15s ease-in-out;
            -moz-transition: all 0.15s ease-in-out;
            -ms-transition: all 0.15s ease-in-out;
            -o-transition: all 0.15s ease-in-out;
            transition: all 0.15s ease-in-out;
          }
        }
      }

      ._short-description {
        .small-link-store {
          display: inline-block;
        }

        .item-title {
          margin: {
            top: 8px;
            bottom: 15px;
          }
          .item-title--primary {
            text-transform: lowercase;
            @include media-breakpoint-up(xs) {
              font-size: 18px;
              font-weight: $fw700;
            }

            @include media-breakpoint-up(lg) {
              font-size: 30px;
              font-weight: $fw400;
            }
          }
        }

        .item-price {
          margin-bottom: 5px;

          ._product-price._2x {
            .ui-item__price {
              .price-tag-fraction {
                @include media-breakpoint-up(xs) {
                  font-size: 24px;
                }
                @include media-breakpoint-up(md) {
                  font-size: 30px;
                }
              }

              .price-tag-symbol {
                @include media-breakpoint-up(xs) {
                  top: 4px;
                }
              }
            }
          }
        }

        .tax-scamb {
          span {
            display: inline-block;
            margin-right: 4px;
          }

          button {
            width: auto;
            padding: 0.2rem 0.3rem;
            margin-left: -4px;

            svg {
              path {
                fill: $color-gray;
              }
            }
          }
        }

        .tax-scamb,
        .insufficient-points {
          margin-bottom: 10px;
        }

        .insufficient-points {
          .insufficient-points--title {
            a {
              display: inline-block;
              margin-left: 5px;
            }
          }
        }

        .shipping-options {
          margin-bottom: 30px;

          .shipping-options--title {
            margin-bottom: 5px;

            button {
              width: auto;
              padding: 0.2rem 0.3rem;

              svg {
                path {
                  fill: $color-gray;
                }
              }
            }
          }

          .shipping-options-items {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            .shipping-options-item {
              opacity: 0.5;
              width: 90px;
              height: 85px;
              border: 1px solid $color-ice;
              border-radius: 3px;
              text-align: center;
              margin-left: 10px;

              &:first-child {
                margin-left: 0;
              }

              .item-icon {
                width: 32px;
                height: 32px;
                margin: 0 auto;
                margin-top: 10px;
                border-radius: 100px;
                background-color: #f9fafc;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  width: auto;
                  height: 18px;

                  path {
                    fill: $color-gray;
                  }
                }
              }

              &:nth-child(1) {
                .item-icon {
                  svg {
                    height: 16.3px;
                  }
                }
              }

              .item-info {
                color: $color-gray;
                font-size: 10px;
                font-weight: $fw700;
                margin-top: 4px;
              }
            }

            .shipping-options-item._active {
              opacity: 1;
              border-color: rgba(20, 35, 62, 0.6);

              .item-icon {
                background-color: $color-blue;

                svg {
                  path {
                    fill: $color-white;
                  }
                }
              }

              .item-info {
                color: $color-blue;
              }
            }
          }
        }

        ._wrap-actions {
          max-width: 360px;
          // display: flex;

          // @include media-breakpoint-up(xs) {
          //   flex-direction: column;
          //   align-items: center;
          // }

          // @include media-breakpoint-up(md) {
          //   flex-direction: row;
          // }

          a,
          button {
            width: $percent-100;
            max-width: none;
            font-weight: $fw700;
            @include media-breakpoint-up(xs) {
              font-size: 13px;
              padding: 0.669rem 0.75rem;

              &:last-child {
                margin-bottom: 0;
              }
            }

            @include media-breakpoint-up(md) {
              margin-bottom: 0;
              font-size: 14px;
              padding: 0.469rem 0.75rem;

              &:first-child {
                margin-left: 0;
              }
            }

            &:nth-child(2) {
              @include media-breakpoint-up(xs) {
                margin: 10px 0;
              }

              @include media-breakpoint-up(md) {
                // margin: 0;
              }
            }
          }

          ._wrap-chat-button {
            width: $percent-100;
            button._btn-chat {
              display: block;
              background-color: $color-blue;
              font-weight: $fw400;

              @include media-breakpoint-up(xs) {
                max-width: none;
                width: $percent-100;
              }

              svg {
                position: relative;
                top: 1px;

                @include media-breakpoint-up(xs) {
                  margin-left: 10px;
                  width: 16px;
                  height: auto;
                }

                path {
                  fill: $color-white;
                }
              }
            }
          }
        }
      }
    }
  }

  .layout-main-informations {
    display: flex;

    @include media-breakpoint-up(xs) {
      flex-direction: column-reverse;
      margin-bottom: 40px;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      margin-bottom: 60px;
    }

    .layout-col.layout-col-left {
      padding-top: 20px;

      @include media-breakpoint-up(lg) {
        padding: {
          right: 30px;
        }
      }

      @include media-breakpoint-up(lg) {
        border-top: 1px solid $color-ice;
        border-right: 1px solid $color-ice;
        padding-left: 0;
      }

      ._long-description,
      ._characteristics {
        @include media-breakpoint-up(xs) {
          margin-bottom: 30px;
        }

        @include media-breakpoint-up(md) {
          margin-bottom: 50px;
        }
      }

      ._long-description {
        ._long-descriptionr-title {
          margin-bottom: 15px;
        }

        ._long-description-text {
          word-break: break-word;
          @include media-breakpoint-up(xs) {
            word-break: break-word;
            font-size: 12px;
          }

          @include media-breakpoint-up(md) {
            font-size: 14px;
          }
        }
      }

      ._characteristics {
        ._characteristics-title {
          margin-bottom: 15px;
        }

        ._characteristics-list {
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            div {
              font-weight: $fw700;

              @include media-breakpoint-up(xs) {
                font-size: 12px;
              }

              @include media-breakpoint-up(md) {
                font-size: 14px;
              }
            }

            ._key {
              color: $color-gray;
              text-transform: capitalize;
            }
            ._value {
              color: $color-black;
              text-transform: lowercase;
            }
          }
        }
      }

      ._payment-methods {
        ._payment-methods-title {
          margin-bottom: 15px;
        }

        ._small-heading {
          font-weight: $fw700;

          @include media-breakpoint-up(xs) {
            font-size: 13px;
          }

          @include media-breakpoint-up(lg) {
            font-size: 14px;
          }

          ._small-heading-bottom {
            display: block;

            button {
              max-width: none;
              width: auto;
              padding: 0.1rem 0.3rem;

              svg {
                path {
                  fill: $color-gray;
                }
              }
            }
          }
        }
      }
    }

    .layout-col.layout-col-right {
      padding-top: 20px;

      @include media-breakpoint-up(lg) {
        @include padding-lt-rt;
        padding-left: 30px;
      }

      ._advertiser {
        max-width: 360px;
        width: 100%;

        ._advertiser-title {
          margin-bottom: 15px;
        }

        ._advertiser-card {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          ._advertiser-card-logo {
            display: block;
            min-width: 50px;
            max-width: 50px;
            width: 50px;
            height: 50px;
            display: block;
            min-width: 50px;
            max-width: 50px;
            border-radius: 100px;
            overflow: hidden;
            background-color: $color-ice;
            position: relative;

            img {
              height: auto;
              width: 100%;
            }
          }

          ._advertiser-card-title--location {
            font-size: 14px;
            color: $color-black;
            font-weight: $fw700;
            margin-left: 10px;

            .title-location {
              display: block;
              font-size: 11px;
            }
          }

          ._advertiser-card-follow {
            @include media-breakpoint-up(xs) {
              margin-left: 10px;
            }

            @include media-breakpoint-up(sm) {
              margin-left: 88px;
            }

            .btn-follow {
              background-color: $color-blue;
              color: $color-white;
              font-size: 10px;
              padding: 0.219rem 0.889rem;
            }
          }

          ._advertiser-card-redirect {
            margin-left: 20px;

            @include media-breakpoint-up(xs) {
              //							display: none;
            }

            button {
              padding: 0.25rem;

              svg {
                position: relative;
                top: 2px;
                height: 16px;
                width: auto;
                opacity: 0.6;
              }
            }
          }
        }
      }

      .section-seller-info {
        margin-top: 30px;

        .seller-title {
          margin-bottom: 8px;
        }

        ._wrap-seller-status {
          max-width: 360px;
          width: 100%;

          .seller-status {
            margin-bottom: 15px;
          }

          .reputation-thermometer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .reputation-thermometer-item {
              max-width: 68px;
              width: 100%;
              height: 8px;
              background-color: $color-gray;
              margin-left: 5px;

              &:first-child {
                margin-left: 0;
              }

              span {
                display: none;
              }
            }

            .reputation-thermometer-item.reputation-level {
              height: 12px;
            }

            .reputation-thermometer-1 {
              background-color: #ffe7e6;
            }

            .reputation-thermometer-2 {
              background-color: #fff4e7;
            }

            .reputation-thermometer-3 {
              background-color: #fffde5;
            }

            .reputation-thermometer-4 {
              background-color: #f3fee0;
            }

            .reputation-thermometer-5 {
              background-color: #27ae60;
            }

            .reputation-thermometer-1.reputation-level {
              background-color: #e44742;
            }

            .reputation-thermometer-2.reputation-level {
              background-color: #fa9209;
            }

            .reputation-thermometer-3.reputation-level {
              background-color: #fdf648;
            }

            .reputation-thermometer-4.reputation-level {
              background-color: #4fe968;
            }

            .reputation-thermometer-5.reputation-level {
              background-color: #01a556;
            }
          }
        }

        .reputation-info-items {
          display: flex;
          margin-top: 10px;

          .reputation-info-item {
            max-width: none;
            width: auto;
            height: 82px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // margin-left: 5px;

            &:first-child {
              margin-left: 0;
            }

            &:nth-child(2) {
              &::before,
              &::after {
                display: block;
                content: "";
                height: 50px;
                width: 1px;
                background-color: $color-ice;
                margin: 0 10px;
              }

              .reputation-info-item--wrap {
                margin: 0 10px;
              }
            }

            .reputation-info-item--wrap {
              .item-icon {
                position: relative;
                width: 28px;
                margin: 0 auto;

                svg {
                  height: 20px;
                  width: auto;
                  opacity: 0.8;

                  path {
                    fill: $color-gray;
                  }
                }

                .check-mark {
                  position: absolute;
                  top: -2px;
                  right: 0;
                  display: block;
                  height: 14px;
                  width: 14px;
                  border-radius: 100px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: $color-green;

                  svg {
                    height: 8px;
                    width: auto;
                    opacity: 0.8;

                    path {
                      fill: $color-white;
                    }
                  }
                }
              }

              .item-text {
                margin-top: 5px;
                font-weight: $fw400;
                .tag {
                  display: block;
                  font-weight: $fw700;
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
  }

  ._wrap-more-products {
    ._med-container {
      .more-products {
        padding: 30px 0;
        border-top: 1px solid $color-ice;

        .headline {
          margin-bottom: 20px;
        }
      }
    }
  }
}

// Messenger

#view_product {
  ._wrap-messenger-inbox {
    position: relative;
    z-index: map-get($z-index, messenger);

    ._wrap-messenger {
      position: fixed;
      bottom: 0;
      left: auto;
      margin-top: 0;
      background-color: $color-ice;

      @include media-breakpoint-up(xs) {
        right: 0;
        height: 100vh;
        max-width: none;
      }

      @include media-breakpoint-up(md) {
        right: 15px;
        height: 455px;
        max-width: 300px;
      }

      ._chat-form {
        position: relative;
        height: $percent-100;

        ._chat-header {
          background-color: $color-white;

          ._cols {
            ._col._col-right {
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
          }
        }

        ._chat-content {
          position: relative;
          overflow-x: hidden;
          padding: 20px 10px 20px 0px;
          border-width: 1px 1px 1px 0;
          border-style: solid;
          border-color: #e6e6e6;
          height: calc(100% - 140px);
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          flex-direction: column;

          overflow-y: auto;

          ._chat-messages {
            .message-date {
              padding-top: 10px;
            }
          }
        }

        ._chat-footer {
          position: relative;
          width: 100%;
          background-color: $color-white;
        }
      }
    }
  }
}
