#breadcrumbs {
    padding-top: 15px;
    ._med-container {
        
        nav {
            
            ol.breadcrumb {
                background-color: transparent;
                padding-left: 0;
                padding-right: 0;
                
                .breadcrumb-item {
                    
                    font-size: 12px;
                    font-weight: $fw700;
                    
                    
                    a {
                        font-size: 12px;
                        font-weight: $fw700;
                        color: #b7b7b7;
                    }
                    
                }
                
                .breadcrumb-item.active {
                    color: $color-gray;
                }
                
            }
            
        }
        
    }
    
}

.breadcrumb-item + .breadcrumb-item::before {
    content: '';
    background-image: url('#{$path-image}/arrow-select-right.png');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 5px;
    opacity: 0.7;
    margin-right: 5px;

}
