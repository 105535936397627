// Defaults

$body-bg                    : #f9fafe;
$percent-100                : 100%;
$percent-50                 : 50%;
$fa-font-path:              "../webfonts";

// Font Family
$font-comfortaa             : 'Comfortaa', cursive;
$font-poppins               : 'Poppins', sans-serif;
$font-nunito                : 'Nunito', sans-serif;
$font-oswald                : 'Oswald', sans-serif;


// Font Weight

$fw200                      : 200;
$fw300                      : 300;
$fw400                      : 400;
$fw500                      : 500;
$fw600                      : 600;
$fw700                      : 700;


// Width Containers
$flu-container              :   100%;
$max-container              :   1360px;
$med-container              :   1090px;
$min-container              :   990px;
$mob-container              :   320px;


// Breakpoints

$break-small                : 576px;
$break-medium               : 768px;
$break-large                : 992px;
$break-max                  : 1200px;


// Colors

$color-white                :   #ffffff;
$color-light                :   #F9F9F9;
$color-ice                  :   #f6f6f9;
$color-ice-light            :   #fbfcfc;
$color-ice-bold             :   #f1f1f1;
$color-nude                 :   #e3b487;
$color-orange               :   #f25e35;
$color-violet               :   #681e36;
$color-brown-lt             :   #87604a;
$color-brown                :   #94847c;
$color-concrete             :   #f2f2f2;
$color-alto                 :   #dadada;
$color-yellow               :   #f3c14c;
$color-shock                :   #e185ae;
$color-silver               :   #9e9e9e;
$color-red                  :   #c2403f;
$color-gray                 :   #909093;
$color-gray-bolder          :   #515157;
$color-green                :   #27ae60;
$color-blue                 :   #14233e;
$color-blue-bolder          :   #032778;
$color-blue-light           :   #557ea1;
$color-black                :   #3d3d40;
$color-border               :   #eaeaea;
$color-box                  :   #fbfbfb;

// Asset paths
$path-image                :    '../../images';
$path-fonts                :    '../fonts';
$node-modules              :    './node_modules';