.review-product {
    @include media-breakpoint-up(xs) {
        position: fixed !important;
        top: 0;
        width: $percent-100;
        height: $percent-100;
        z-index: 999;
        overflow-y: scroll;
    }
    
    @include media-breakpoint-up(md) {
        position: relative !important;
        top: 0;
        width: $percent-100;
        height: auto;
        z-index: auto;
        overflow-y: auto;
        padding-bottom: 0;
    }

    ._alert-top {
        background-color: #f4f4f4;
        font-weight: $fw700;
        font-size: 13px;
        color: $color-black;
        text-align: center;
        
        @include media-breakpoint-up(xs) {
            padding: 5.5px 0;
        }
        
        @include media-breakpoint-up(md) {
            padding: 10.5px 0;
            margin-bottom: 15px;
        }
    }

    ._confirm-publish {
        background-color: #f9f9f9;
        @include padding-lt-rt;
        padding-top: 10px;
        padding-bottom: 10px;
        position: relative;
        z-index: 999;
        width: 100%;
        bottom: 0;
        -webkit-box-shadow: none; //0 -4px 8px 4px rgba(100,100,100,0.1);
        box-shadow: none; //0 -4px 8px 4px rgba(100,100,100,0.1);

        ._wrapper {
            max-width: $med-container;
            @include push--auto;

            @include media-breakpoint-up(md) {

                display: flex;
                align-items: center;
                justify-content: space-between;
            }

        ._wrap-confirm-publish {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            ._col.left {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                ._product-image {
                    background-color: #cccccc;
                    height: 50px;
                    width: 50px;
                    border-radius: 4px;
                    position: relative;
                    
                    ._wrap-image {
                        border: 1px solid $color-box;
                        height: 50px;
                        width: 50px;
                        overflow: hidden;
                        img {
                            width: 100%; //160%;
                            margin-left: 0; //-20%;
                            height: auto;
                        }
                    }

                    .tag-weight {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: -10px;
                        margin-top: auto;
                        margin-bottom: auto;
                        background-color: $color-nude;
                        text-transform: uppercase;
                        font-size: 13px;
                        font-weight: $fw400;
                        color: $color-white;
                        width: 24px;
                        height: 24px;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                ._wrap-info {
                    margin-left: 15px;

                    .product-title,
                    .product-category {
                        font-weight: $fw700;
                        color: $color-black;
                    }

                    .product-title {
                        font-size: 13px;
                    }

                    .product-category {
                        font-size: 11px;
                    }
                }

            }

            .item-price {
                @include media-breakpoint-up(md) {
                    margin-left: 30px;
                }
            }
        }

        ._confirm-publish-actions {
            margin-top: 5px;

            @include media-breakpoint-up(md) {
                display: flex;
                align-items: center;
            }


            .btn {
                font-weight:  $fw700;
                @include media-breakpoint-up(xs) {
                    max-width: none;
                    width: $percent-100;
                }

                @include media-breakpoint-up(md) {
                    max-width: 300px;
                    min-width: 120px;
                }

                svg {
                    path {
                        fill: $color-white;
                    }
                }
            }
            .btn.btn-cancel {
                
                @include media-breakpoint-up(xs) {
                    background-color: $color-ice-bold;
                    margin-top: 5px;
                    color: #737373;
                }
                
                @include media-breakpoint-up(md) {
                    background-color: $color-nude;
                    margin-top: 0px;
                    margin-left: 10px;
                    color: $color-white;
                }

                svg {
                    margin-left: 4px;
                    width: 15px;
                    height: auto;
                    path {
                        @include media-breakpoint-up(xs) {
                            fill: #737373;
                        }

                        @include media-breakpoint-up(md) {
                            fill: $color-white;
                        }
                    }
                }
            }
        }
        }
    }
    
    .layout-main {
        
        .layout-col.layout-col-left {
            @include media-breakpoint-up(md) {
                padding-bottom: 30px;
            }
        }

        .layout-col.layout-col-right {
            border-bottom: 0 !important;

            ._short-description {

                .shipping-options {
                    margin-bottom: 0 !important;
                }

            }
        }
    }

    .layout-main-informations {
        
        .layout-col.layout-col-left {
          max-width: none !important;
          min-width: auto !important;
          border-right: 0 !important;
        }
    }

}