.btn-scamb-action {
    display: inline-block;
    background-color: $color-ice-bold;
    max-width: 414px;
    width: $percent-100;
    color: #ffffff;
    padding: 0.5625rem 0.75rem;
    border: 0;
    border-radius: 0.25rem;
    border: 0.0625rem solid $color-ice-light;
    font-size: 0.8125rem;
    font-weight: $fw700;

    a {
        display: block;
        color: $color-white;
    }
}

.btn-scamb-action.color-red {
    background-color: $color-red;
    border-color: $color-red;
}

.btn-scamb-action.color-blue {
    background-color: $color-blue;
    border-color: $color-blue;
}

.btn-scamb-action.bordered.color-red {
    background-color: $color-white;
    border-color: rgba(194, 64, 63, 0.9);

    a {
        color: $color-red;
    }
}

.btn-scamb-action.bordered.color-blue {
    background-color: $color-white;
    border-color: $color-blue;
    color: $color-blue;

    a {
        color: $color-blue;
    }
}