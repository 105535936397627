#view_results {
    
    background-color: $color-white;
    padding-bottom: 30px;
    
    @include media-breakpoint-up(lg) {
        // padding-top: 30px;
    }

    #filtros-desktop {

        .filtro {

            .content {
                display: none;
            }
        }

        .filtro.active {

            .content {
                display: block;
            }
        }
    }

    ._only-desk,
    ._only-mobl {
        
        ._title-results {
            font-weight: $fw400;
            color: $color-black;
            
            @include media-breakpoint-up(xs) {
                font-size: 14px;
                margin: 15px 0;
            }
            
            @include media-breakpoint-up(lg) {
                font-size: 18px;
                margin: 25px 0;
            }
        }
    }

    ._only-desk {
        width: $percent-100;
        @include media-breakpoint-up(xs) {
            display: none;
        }
        
        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    ._only-mobl {
        @include media-breakpoint-up(xs) {
            display: block;
        }
        
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    
    ._med-container {

        ._tabs {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: $percent-100;
            border-bottom: 1px solid $color-ice;
            margin-bottom: 20px;
            overflow: hidden;
            overflow-x: auto;

            button {
                max-width: none;
                min-width: 100px;
                width: auto;
                font-size: 13px;
                font-weight: $fw700;
                flex: none;
                color: $color-gray;
                text-transform: lowercase;
                margin-left: 20px;
                padding: 5px 2px;
                border-radius: 0;
                
                &:first-child {
                    margin-left: 0;
                }
            }

            button._active {
                color: $color-gray-bolder;
                border-bottom: 2px solid $color-blue;
            }
        }

    }

    ._store-content {
        
        max-width: $med-container;
        @include push--auto;
        @include padding-lt-rt;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        
        // @include media-breakpoint-up(xs) {
        // }
        
        // @include media-breakpoint-up(lg) {
        //     flex-direction: row;
        // }
        
        ._wrap-filters {

            @include media-breakpoint-up(xs) {
                width: $percent-100;
                // overflow-x: scroll;
            }
            
            @include media-breakpoint-up(lg) {
                // overflow: auto;
                width: $percent-100;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
                
            ._form._sidebar {
                
                @include media-breakpoint-up(xd) {
                    // overflow-x: scroll;
                }
                
                @include media-breakpoint-up(lg) {
                    // overflow-x: hidden;
                }
            }

            ._filters-title {

                    color: $color-black;
                    font-weight: $fw700;
                    margin-bottom: 15px;

                    @include media-breakpoint-up(xs) {
                        font-size: 14px;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 15px;
                    }
                }

            ._wrap-filter {

                ._filter-content {
                    ._form-filter-content {
                        ._mui-slider {
                            margin-top: 40px;
                            padding-left: 15px;
                        }
                    }
                }
            } 
            
        }
        
        ._store-products {
            width: 100%;
            
            ._products {
                width: 100%;
                
                .no-results {
                    max-width: 725px;
                    margin: 0 auto;
                    
                    @include media-breakpoint-up(xs) {
                        padding: 3rem 0;
                    }
                    
                    @include media-breakpoint-up(md) {
                        padding: 6rem 0;
                    }

                    .icon {
                        text-align: center;
                        margin-bottom: 15px;
                        img {
                            width: 100%;
                            
                            @include media-breakpoint-up(xs) {
                                max-width: 285px;
                            }
                            
                            @include media-breakpoint-up(md) {
                                max-width: 485px;
                            }
                        }
                    }
                    
                    .content {
                        .title {
                            text-align: center;
                            font-weight: $fw600;
                            color: $color-blue;
                            font-family: $font-nunito;
                            margin-bottom: 15px;
                            
                            @include media-breakpoint-up(xs) {
                                font-size: 18px;
                            }
                            
                            @include media-breakpoint-up(md) {
                                font-size: 30px;
                            }
                        }
                        ul {
                            margin: 0 auto;
                            
                            @include media-breakpoint-up(xs) {
                                max-width: 300px;
                            }
                            
                            @include media-breakpoint-up(md) {
                                max-width: 405px;
                            }

                            li {
                                text-align: left;
                                font-weight: $fw700;
                                color: $color-blue;
                                font-size: 13px;
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }
            
            ._wrap-load-more {
                text-align: center;
                margin-top: 20px;
            }

            ._wrap-load-more button{
                //max-width: 100px;
                margin: 5px;
            }
        }
    }
    
}

._store {
    
    ._store-content {
        
        ._store-products {
            margin-top: 20px;
        }
    }
}