//====================================
// UI * Extends Bootstrap Forms
//====================================

._form {
  .form-group {
    position: relative;

    label {
      font-weight: $fw700;
      color: $color-gray-bolder;
      margin-bottom: 4px;

      @include media-breakpoint-up(xs) {
        font-size: 13px;
        min-width: 80px;
      }

      @include media-breakpoint-up(md) {
        font-size: 13px;
        min-width: 140px;
      }

      span {
        opacity: 0.5;
        display: inline-block;
      }
    }

    ._character-counter {
      position: absolute;
      top: 13px;
      right: 13px;
      z-index: map-get($z-index, z-default);
      font-size: 12px;
      font-weight: $fw700;
      color: #cac5bf;
    }

    .form-control {
      border-color: #eaeaea;
      font-size: 13px;
      height: calc(1.462em + 0.75rem + 9px);
      font-weight: $fw700;
      color: $color-gray;
      position: relative;

      &:focus,
      &:active {
        outline: 0 !important;
        box-shadow: none !important;
        color: $color-gray-bolder;
      }

      &::placeholder {
        font-weight: $fw700;
      }
    }

    .form-control:disabled,
    .form-control[readonly] {
      background-color: $color-white !important;
      border: 0;
      padding-left: 0;
    }

    textarea.form-control {
      padding-top: 0.745rem;
      height: auto;
    }

    select.form-control {
      position: relative;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      background-image: url("#{$path-image}/arrow-select.png");
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 14px;
      color: $color-gray;

    }

    .form-text.text-muted {
      padding-left: 0.845rem;
      font-size: 10px;
    }
  }

  .form-group-button {
    text-align: center;
    margin-top: 20px;

    button {
      font-weight: $fw700;
    }
  }

  .row {
    @include media-breakpoint-up(xs) {
      margin-left: 0;
    }
  }

  ._wrap-categories {
    ._categories {
      select.form-control {
        height: auto;
        overflow: hidden;
        border-color: #eaeaea;
        padding: 0;

        option {
          height: 46px;
          display: flex;
          align-items: center;
          font-size: 13px;
          color: $color-gray;
          border-bottom: 1px solid #eaeaea;
          padding: 0.375rem 0.75rem;
          background-image: url("#{$path-image}/arrow-select-right.png");
          background-repeat: no-repeat;
          background-position: right 10px center;
          background-size: 8px;
          font-weight: $fw700;
          cursor: pointer;

          &:last-child {
            border: 0;
          }
        }
      }
    }

    ._categories._sub-categories {
      select.form-control {
        option {
          background-image: url("#{$path-image}/check.png");
          background-size: 14px;

          &:focus {
            background-color: rebeccapurple !important;
          }

          &:first-child {
            padding-left: 30px;
            background-image: url("#{$path-image}/arrow-long-left.png");
            background-position: left 10px center;
          }
        }
      }
    }
  }
}

._form._inline-disable {
  .form-group {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    margin-bottom: 0.5rem;

    label {
      margin-bottom: 0;
      margin-right: 10px;
    }

    ._wrap-custom-url {
      .custom-url {
        background-color: $color-white !important;
        font-weight: $fw400;
        background-color: #ffffff !important;
        height: auto !important;
        width: auto;
        border: 0 !important;
        padding-right: 0;
      }
    }

    ._character-counter {
      display: none;
    }

    textarea {
      height: calc(1.462em + 0.75rem + 9px);
      resize: none;

      &::placeholder {
        color: $color-gray-bolder;
      }
    }
  }
}

.form-check._checkbox {
  padding-left: 0;

  .form-check-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $color-gray;
    font-size: 12px;
    font-weight: $fw700;

    .form-check-input {
      display: none;
    }

    .check {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      min-width: 20px;
      border-radius: 3px;
      border: 1px solid rgba(204, 204, 204, 0.6);
      background-color: $color-white;
      margin-right: 10px;
      cursor: pointer;
      
      svg {
        width: 11px;
        height: auto;
        opacity: 0;
        
        path {
          fill: #a1a1a4;
        }
      }
    }
    
    .form-check-input:checked ~ .check {
      background-color: $color-blue;
      border-color: $color-blue;
      svg {
        opacity: 1;
        path {
          fill: $color-white;
        }
      }
    }
  }
}

._label {
  font-weight: $fw700;
  color: $color-black;

  @include media-breakpoint-up(xs) {
    font-size: 13px;
  }

  @include media-breakpoint-up(md) {
    font-size: 13px;
  }

  button {
    max-width: none;
    width: auto;
    padding: 0.169rem 0.275rem;

    svg {
      width: 14px;
      height: auto;

      path {
        fill: $color-silver;
        opacity: 0.5;
      }
    }
  }

  button._btn-info {
    background-color: transparent;
    padding: 0;
    border: 0;
    position: relative;
    top: 1px;

    svg {

      path {
        fill: $color-silver;
        opacity: 0.8;
      }
    }
  }
}

._form {
  ._row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .input-group {
      margin-left: 10px;

      &:first-child {
        margin-left: 0 !important;
      }

      &:nth-child(1) {
        width: 30%;
      }

      &:nth-child(2) {
        width: 30%;
      }

      &:nth-child(3) {
        width: 40%;
      }
    }
  }

  .input-group {
    .input-group-prepend {
      .input-group-text {
        border-color: #eaeaea;
        background-color: $color-white;
        border-right: 0;
        padding-right: 1px;

        svg {
          width: auto;
          height: 15px;

          path {
            fill: $color-gray;
          }
        }
      }
    }

    .form-control {
      border-color: #eaeaea;
      background-color: $color-white;
      border-left: 0;
      color: $color-gray;
      height: calc(1.462em + 0.75rem + 9px);
      font-weight: $fw700;

      @include media-breakpoint-up(xs) {
        font-size: 12px;
      }

      @include media-breakpoint-up(md) {
        font-size: 13px;
      }

      &:focus,
      &:active {
        border-left: 0 !important;
      }
    }
  }
}


//====================================
// UI * Validatin Forms
//====================================

.form-control.required {
  border-color: $color-red !important;
}

._form._validate {
  ._checkbox,
  .form-group {
    position: relative;
    ._error {
      display: flex !important;
      align-items: center;
      justify-content: center;
      height: 14px;
      width: 14px;
      padding: 0;
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      color: white;
      border-radius: 100px;
      font-size: 8px;
      background-color: $color-red;
    }

    ._error-message {
      position: absolute;
      display: none;
    }

    .form-check-label {
      .check.error {
        border-color: $color-red;
      }
    }

    &:hover {
      ._error-message {
        display: block;
      }
    }
  }

  .form-group {
    ._wrap-custom-url,
    ._wrap-input {
      position: relative;
      width: $percent-100;
      ._error {
        display: flex !important;
        align-items: center;
        justify-content: center;
        height: 14px;
        width: 14px;
        padding: 0;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        color: white;
        border-radius: 100px;
        font-size: 8px;
        background-color: $color-red;
      }

      ._error-message {
        position: absolute;
        display: none;
      }

      .form-check-label {
        .check.error {
          border-color: $color-red;
        }
      }

      &:hover {
        ._error-message {
          display: block;
        }
      }
    }
  }

  .input-group.error {
    border: 1px solid $color-red !important;
    border-radius: 0.2rem;
  }

  ._wrap-custom-url.error {
    border: 1px solid $color-red !important;
    border-radius: 0.2rem;
  }

  .input-group {
    position: relative;
    ._error {
      display: flex !important;
      align-items: center;
      justify-content: center;
      height: 14px;
      width: 14px;
      padding: 0;
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      color: white;
      border-radius: 100px;
      font-size: 8px;
      background-color: $color-red;
      z-index: 99;
    }
  }

  .form-group {
    .form-control.error {
      border-color: $color-red !important;

      &:focus,
      &:active {
        border-color: $color-red !important;
      }
    }
  }
}

//====================================
// UI * Swal styles
//====================================

.swal-modal {
  .swal-title {
    font-size: 18px;
  }

  .swal-text {
    text-align: center;
    padding: 0;
    
    @include media-breakpoint-up(xs) {
      font-size: 13px;
      font-weight: $fw400;
    }

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }
  }

  .swal-footer {
    .swal-button-container {
      .swal-button {
        font-weight: $fw700 !important;
      }

      .swal-button.swal-button--confirm {
        background-color: $color-blue !important;
        color: $color-white;
      }

      .swal-button.swal-button--cancel {
        background-color: $color-ice-bold !important;
        color: #777777 !important;
      }
    }
  }
}

.swal2-modal {
  .swal2-title {
    font-size: 1.075em;
  }

  .swal2-content {
    .swal2-html-container {
      font-size: 0.875rem;
      line-height: 1.6;
    }
    .swal2-input {
      height: calc(1.462em + 0.75rem + 6px);
      font-size: 14px;
    }

    .swal2-validation-message {
      font-size: 0.785rem;
    }
  }

  .swal2-actions {
    button {
      font-size: 13px;
    }

    .swal2-confirm.swal2-styled {
      background-color: $color-blue;
    }

    .swal2-styled.swal2-cancel {
      background-color: #f1f1f1;
      color: #777777;

      &:hover {
        background-color: $color-white;
        opacity: 0.8;
        background-image: none;
      }
    }
  }
}

.swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAACABAMAAABTk5LXAAAAJFBMVEVHcEweKkQeKkQeKkQeKkQeKkQeKkQeKkQeKkQeKkQeKkQeKkRg4osrAAAAC3RSTlMAuntZQPCZ2BAgMPFHB+MAAAQ2SURBVFjD7ZfPTxNBFMenu0t/XtoioO5FjSDKBYmCsZeqiJpeEA0S9gISQ7QXfiRq0kuDggcvFRIvXoi/Ey70x7bB+ed80/31ZnZm3YOJF9+l3c1nZ+e9ee/73hLC25exwkizULxYJxH22aSu9daUYHadImtOyim9RjmzL8ehwJ7HoigdF6mMKaForyxg16nU2jyVlFPUbmAqbSoweg5j71QUzePFsJfFa7OzU0MyDC226MZ+2nKuVxAW7GzbP8kdh2vI3FxAz+6xGy10o+SfNpcV9yAeyNG0JfWekKkLN2ShfRyViqTiRfwwEvP8XI6kDO+dk5FYwqW6kRT55GIb0diEi5WjMffYm9FUxl3sVzSmSzJGYslY4fDjIWjBVwEbkHmQqdLeC+5OzsFa4XLcwi84crAOpznuuTRC+fFLen51ETuWbAQnfSRGz8TD7En+1rFCUbo8dsKFzQo4t55TkoBgGevVsfN8eDProtho8nTbN/nduRGnoTys8kVuKRLJV2Nn21VVWuoWdqIic7Vv33BMUsqSydRQWmjqLP+GVvB0a0mtG2VUzz11qefR5jg15rX7BC/dVRZxF0eOvlYpR49rC3ZYbdwV+jqa9dKr2RCxI3ysvuJ3xfVS+CR1qhoYEpxEllAHLRSeBqjGVYrOd8cFEfPy4q6i2Ro8luHnhhMBa8ubfUvAgoL6wQ0+wt5Q3c3EwzDXEuLGVcBVH1sSJLcjreGgZVYkCgN2e4TdPStmyHF41pudehBcWbF6i67Of0ke/akFleQtSDCvhJvx+lk73jvn3TR6RhclQ0hWSL/r8oU94bLrKKlCTvt12cI7DTV7P/FdNXgvrXmNCuNOReZ2MC97UXvvXp/GkR2lwjv9k7ODETx9KajvQyEPqO2N9Nlnsnl7wr/38iZcvj2POiAKFFekm+rhvaoctrmo60rqUZzPBdo7lPYd0U4LB6hZMmo7lDUfJNSCpAZuhahuQ5bScwK1painHeyHvaYeR6d9sBg536bvjBUK8N3ZIP/tv/1NS+VjYbnjf4l9NIdBddLnn5xi2j/SBClMD35/9YKMbY4zbH9ouOGof8uZ4Vb67QdE1LDh53t/MM61rf4HbqJX1q1yujaYGW0R3b5imMvQTxYPTLp1UMvD58Q2uQpPJkAO9+tJmAuzZ0kJnkvadQPELwUtaPeE5NioXd0gWl/2c85sYLImVjtnQJ/VYO5MdEiOzRapNrTWhxehgziDnMVqvbpiwKMabFjrOJ4m4f9HC15fcc6k/9VQymOMPc/+g3LY9co8Wo3D2G4SLXdLA854Y7JJooaxFBP83Q5JQmwnVpLMoUNSabMmgTEdgpGubZBK8/4ba5JUt37OwE26dmBaGCPVhQczsIbunELCaXVwCk3OhX4HYj1wZ7XIxH+6wOTRWC2W98oE/mbga9dgrW6uUKyT3xvMsOE5/4deAAAAAElFTkSuQmCC");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 0 !important;
  //   animation: none;
  width: 5.5em;
  height: 3.5em;
}

.swal-overlay--show-modal .swal-modal {
  will-change: auto !important;
}

._wrapper-drawer {
  .MuiDrawer-paper {
    position: absolute !important;
    width: $percent-100;
    height: calc(100% + 240px);
    padding-left: 15px;
    padding-right: 15px;
  }
}

.swal2-popup.swal2-toast.swal2-icon-success.swal2-show {

  .swal2-header {

    .swal2-title {
      
      font-size: 13px;
    }
  }
}

//====================================
// UI * Image Gallery Slider
//====================================

.image-gallery {
  .image-gallery-content {
    .image-gallery-slide-wrapper {
      .image-gallery-swipe {
        .image-gallery-slides {
          .image-gallery-slide {
            div {
              img.image-gallery-image {
					@include media-breakpoint-up(lg) {
						border-radius: 5px;
				  }
              }
            }
          }
        }
	  }
	  
	  .image-gallery-bullets {

		@include media-breakpoint-up(xs) {
			display: block !important;
			bottom: 40px;
		}
		
		@include media-breakpoint-up(lg) {
			bottom: 20px;
			display: none !important;
		  }

		  .image-gallery-bullets-container {
			  .image-gallery-bullet {
				  margin: 0 3px;
				  padding: 0;
				  box-shadow: none;
				  background-color: $color-ice;
				  border-color: $color-ice;
				  height: 6px;
				  width: 6px;
			  }

			  .image-gallery-bullet.active {
					border-color: $color-nude;
				  background-color: $color-nude;
				  width: 16px;
				  border-radius: 6px;
			  }
		  }
	  }
    }

    .image-gallery-thumbnails-wrapper {
      @include media-breakpoint-up(xs) {
        display: none !important;
      }

      @include media-breakpoint-up(md) {
        display: block !important;
      }
      .image-gallery-thumbnails {
        .image-gallery-thumbnails-container {
          button.image-gallery-thumbnail {
            transition: none !important;
            width: 50px;
            height: 50px;
            overflow: hidden;
            border-radius: 5px;
            background-color: rebeccapurple;
            border: 0;

            &:focus,
            &:hover {
              border-color: transparent !important;
              transition: none !important;
            }
            .image-gallery-thumbnail-inner {
              background-color: $color-ice;
              img.image-gallery-thumbnail-image {
                height: 50px;
                width: 50px !important;
				display: block;
              }
            }
          }

          .image-gallery-thumbnail + .image-gallery-thumbnail {
            margin-left: 6px !important;
          }

          .image-gallery-thumbnail.active {
            border-color: transparent !important;

            .image-gallery-thumbnail-inner {

				position: relative;
				
				&::after {
					content: '';
					display: block;
					border-bottom: 3px solid $color-nude;
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 999;
					width: $percent-100;
					height: $percent-100;

				}
              img.image-gallery-thumbnail-image {
                width: 50px !important;
                margin: 0;
                overflow: hidden !important;
                position: relative;
              }
            }
          }
        }
      }
    }
  }
}


@media (min-width: 960px) {
  .MuiGrid-grid-md-3 {
      max-width: 20% !important;
  }
}

.MuiDialogActions-root {
  justify-content: center !important;
}