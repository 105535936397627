//=======================================
// UI * Filter
//=======================================

._filter {
    position: relative;

    &:first-child {
        margin-top: 0;
    }

    .wrap-back-list {
        position: relative;
        width: $percent-100;
        display: block;
        line-height: 0;
        border-bottom: 1px solid rgba(204, 204, 204, 0.2);
        
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            height: $percent-100;
            width: 50px;
            background: rgb(246,246,249);
            background: -moz-linear-gradient(90deg, rgba(246,246,249,0) 0%, rgba(249,249,251,0.7) 43%, rgba(255,255,255,0.7959558823529411) 61%);
            background: -webkit-linear-gradient(90deg, rgba(246,246,249,0) 0%, rgba(249,249,251,0.7) 43%, rgba(255,255,255,0.7959558823529411) 61%);
            background: linear-gradient(90deg, rgba(246,246,249,0) 0%, rgba(249,249,251,0.7) 43%, rgba(255,255,255,0.7959558823529411) 61%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f6f9",endColorstr="#ffffff",GradientType=1);
        }

        .back-list {
            background-color: $color-ice-light;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            width: $percent-100;
            overflow: scroll;
            position: relative;

            .back-list-item {
                border: 0;
                padding: 1rem 0.4688rem 1rem 0.4688rem;
                background-color: $color-ice-light;
                display: flex;
                align-items: center;
                justify-content: center;

                &:first-child {
                    padding-left: 0.9375rem;
                }

                .text {
                    font-weight: $fw700;
                    color: $color-black;
                    font-size: 0.6875rem;
                    margin-left: 0.625rem;
                    opacity: 0.9;
                    text-align: left;
                    white-space: nowrap;
                }

                .icon {
                    svg {
                        height: 13px;
                        width: auto;

                        path {

                            fill: $color-black;
                            opacity: 0.5;
                        }
                    }
                }
            }

        }
    }
    
    button._filter-button,
    button._apply-price  {
        background-color: $color-white;
        border: 0;
        font-weight: $fw700;
    }
    
    button._apply-price {
        
        @include media-breakpoint-up(xs) {
            display: none; //inline-block;
            color: #fff;
            border: 1px solid #14233e;
            padding: 0.532rem 0.999rem;
            border-radius: 4px;
            font-weight: 700;
            font-size: 13px;
            background-color: #14233e;
            width: $percent-100;

            svg {
                path {
                    fill: $color-white;
                }
            }
        }
        
        @include media-breakpoint-up(lg) {
            position: absolute;
            right: 0;
            top: 4px;
            background-color: $color-white;
            color: $color-blue;
            font-size: 11px;
            width: auto;
            border: 0;
            padding: 0;

            svg {
                path {
                    fill: $color-blue;
                }
            }
        }

        svg {
            width: 11px;
            height: auto;
            margin-left: 4px;
        }
    }
    
    button._filter-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $color-gray-bolder;
        font-size: 16px;
        font-family: $font-nunito;
        font-weight: $fw600;
        
        @include media-breakpoint-up(xs) {
            width: $percent-100;
        }
        
        @include media-breakpoint-up(lg) {
            width: auto;
            margin-bottom: 0 !important;
        }

        ._cols {
            width: $percent-100;
            ._col-1 {

                display: flex;
                align-items: center;
                justify-content: space-between;
                width: $percent-100;
            
                .button-title {
                    font-weight: $fw700;
                    color: $color-black;
                    font-size: 12px;
                }

                svg {
                    height: 13px;
                    width: auto;

                    path {

                        fill: $color-black;
                        opacity: 0.5;
                    }
                }
            }

            ._col-2 {

                .categories-selecteds {
                    margin-top: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    span {
                        display: block;
                        font-size: 10px;
                        font-weight: $fw700;
                        color: $color-black;
                        opacity: 0.9;
                        // margin-left: 10px;

                        
                        &::after {
                            content: "/";
                            display: inline-block;
                            font-size: 12px;
                            margin: 0 5px;
                        }
                        
                        &:last-child {
                            margin-left: 0;

                            &::after {
                                content: "";
                                display: none;
                            }
                        }
                    }
                }
            }

            .header-open {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: $color-ice;
                padding: 5px 15px;
                
                .back {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    min-width: 100px;

                    .button-title {
                        font-weight: $fw700;
                        color: $color-blue;
                        font-size: 11px;
                        text-transform: uppercase;
                        font-family: $font-nunito !important;
                        margin-left: 10px;
                    }
    
                    svg {
                        height: 20px;
                        width: auto;
    
                        path {
    
                            fill: $color-blue;
                            opacity: 1;
                        }
                    }
                }

                .default-title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding-left: 20px;

                    .title {
                        font-weight: $fw700;
                        color: $color-blue;
                        font-size: 11px;
                        text-transform: uppercase;
                        font-family: $font-nunito !important;
                        margin-right: 5px;
                    }
    
                    svg {
                        height: 16px;
                        width: auto;

                        path {
    
                            fill: $color-blue;
                            opacity: 1;
                        }
                    }
                }

                .close-filter {
                    min-width: 100px;
                    text-align: right;
                }
            }
        }
        
        @include media-breakpoint-up(xs) {
            padding: 14px 15px;
            border-bottom: 1px solid $color-ice;
        }

        @include media-breakpoint-up(lg) {
            padding: 0;
            border-bottom: 0;
            margin-bottom: 10px;
        }
    }

    button._filter-button.active {
        
        @include media-breakpoint-up(lg) {
            background-color: $color-blue;
        }

        ._cols {
            ._col-1 {

                .button-title {
                    @include media-breakpoint-up(lg) {
                        color: $color-white;
                    }
                }

                svg {
                    path {
                        @include media-breakpoint-up(lg) {
                            fill: $color-white;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    button.head-mb._filter-button {
        @include media-breakpoint-up(xs) {
            display: flex;
        }   
        @include media-breakpoint-up(lg) {
            display: none;
        }   
    }

    ._filter-item {
        background-color: $color-white;

        @include media-breakpoint-up(xs) {
            padding: 0;
            display: none;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 0;
            display: none;
        }

        // Load more in modal
        ._loadmore-in-modal {
            border: 0;
            padding: 0;
            background-color: transparent;
            color: $color-blue-light;
            font-size: 13px;
            font-weight: $fw700;
        }

        .wrap-history-filters {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            width: $percent-100;
            overflow: hidden;
            overflow-x: auto;
            position: relative;
            padding: 15px 15px;
            border-bottom: 1px solid rgba(204, 204, 204, 0.2);
            

            .btn-remove-filter {
                margin-left: 10px;
                padding: 0.375rem 0.4688rem 0.375rem 0.4688rem;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        //Filter price
        .form-group {

            ._min-max {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.75rem 0.9375rem;

                .icon {
                    margin: 0 10px;
                    svg {
                        width: auto;
                        height: 13px;

                        path {
                            fill: $color-blue;
                        }
                    }
                }

                .input-group {
                    .form-control {
                        border-left: 1px solid #eaeaea;
                        height: calc(1.417em + 0.75rem + 9px);
                        font-size: 14px;
                        font-weight: $fw700;
                        color: $color-black;
                        
                        &:focus {
                            border-left: 1px solid #eaeaea !important;
                        }

                        &::placeholder {
                            color: #B0B0B6 !important;
                            font-family: $font-nunito !important;
                        }
                    }
                }
            }

        }

        ._filter-selected {
            border: 0;
            background-color: #F1F1F1;
            font-size: 11px;
            font-weight: $fw700;
            color: $color-blue;
            padding: 0.4873rem 0.600rem;
            border-radius: 4px;
            margin-right: 10px;
            margin-bottom: 10px;
            text-transform: lowercase;
            text-align: left;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;

            svg {
                width: 8px;
                height: auto;
                position: relative;
                top: 1px;
                margin-left: 4px;

                path {
                    fill: $color-red;
                }
            }
        }
        
        ._filter-label {

            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            margin: 0;
            margin-bottom: 5px;
            padding: 0.75rem 0.9375rem;
            border-bottom: 1px solid rgba(204, 204, 204, 0.2);

            input[type=radio],
            input[type=checkbox] {
                display: none;
                visibility: hidden;
            }
            
            ._filter-title {
                font-weight: $fw700;
                color: $color-black;
                font-size: 12px;
            }

            ._filter-count {
                font-size: 11px;
                opacity: 0.7;
                display: inline-block;
                margin-left: 6px;
            }

            .chevron-icon {
                svg {
                    height: 13px;
                    width: auto;

                    path {

                        fill: $color-black;
                        opacity: 0.5;
                    }
                }
            }
            
            .check-mark {
                border: 1px solid #D5E1E3;
                background-color: $color-white;
                height: 18px;
                width: 18px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    height: 10px;
                    width: auto;

                    path {

                        fill: $color-black;
                        opacity: 0;
                    }
                }
            }

            input:checked ~ .check-mark {
                background-color: $color-blue;
                border-color: $color-blue;

                svg {

                    path {
                        fill: $color-white;
                        opacity: 1;
                    }
                }
            }

        }

        .filter-actions {

            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $color-white;
            padding: 1.25rem 0.9375rem;
            width: $percent-100;
            bottom: 0; 
            left: 0;
            z-index: 999;
            
            @include media-breakpoint-up(xs) {
                -webkit-box-shadow: 0 -8px 12px 6px rgba(150,150,150,0.1);
                box-shadow: 0 -8px 12px 6px rgba(150,150,150,0.1);
                position: fixed;
            }
            
            @include media-breakpoint-up(lg) {
                box-shadow: none;
                position: relative;
            }

            button {

                font-size: 0.75rem;
                padding: 0.625rem 0.75rem;

                &:first-child {
                    background-color: $color-ice;
                    color: #DC3545;
                }

                &:last-child {
                    margin-left: 10px;
                }

                svg {
                    margin-left: 0.375rem;
                    position: relative;
                    top: 1px;

                    path {
                        fill: $color-white;
                    }
                }
            }


        }
    }

    ._filter-item._filter-show {
        display: block;
    }
}

._filter._filter-wrapper {
    width: $percent-100;
    height: $percent-100;
    z-index: 999;
    background-color: $color-white;
    overscroll-behavior: contain;
    
    @include media-breakpoint-up(xs) {
        overflow: hidden;
        overflow-y: scroll;
        position: absolute;
        top: 0;
        left: 0;
    }
    
    @include media-breakpoint-up(lg) {
        position: relative;
        top: auto;
        left: auto;
        overflow: inherit;
        overflow-y: inherit;
    }

    ._filter-button {
        z-index: 9;
        padding: 0;
        
        @include media-breakpoint-up(xs) {
            position: fixed;
        }
        
        @include media-breakpoint-up(lg) {
            position: inherit;
        }
    }

    ._filter-item._filter-show {
        margin-top: 50px;
        border-top: 1px solid #f1f1f1;
        padding-bottom: 4.625rem;
        
        @include media-breakpoint-up(lg) {
            // margin-top: 0;
            padding-bottom: 0;
            // padding-bottom: 74px;
            position: absolute;
            top: 0;
            left: 0;
            min-width: 290px;
            min-height: auto;
            border: 0.0625rem solid $color-ice-bold;
            overflow: hidden;
            border-radius: 0.3125rem;
            -webkit-box-shadow: 0 8px 8px 6px rgba(100,100,100,0.1);
            box-shadow: 0 8px 8px 6px rgba(100,100,100,0.1);
        }
        
        .wrap-filter-results {
            max-height: 402px;
            overflow: hidden;
            overflow-y: auto;
        }
    }
}


//=======================================
// UI * Filter - Brands
//=======================================
._filter._filter-wrapper.filter-brand {

    ._filter-item._filter-show {

        .wrap-back-list {
            margin-top: 44px;
        }
        .form.form-filter._brands {
            margin-left: 0 !important;


            .search {
                padding: 0;
                max-width: none;
                margin-top: -1px;
                margin-bottom: 0;
                left: 0;

                @include media-breakpoint-up(lg) {
                    border-bottom: 1px solid $color-ice-bold;
                }
                
                @include media-breakpoint-up(xs) {
                    position: fixed;
                    top: 51px;
                }
                
                @include media-breakpoint-up(lg) {
                    position: relative;
                    top: 0;
                }

                .form-group.search.mobile {

                    @include media-breakpoint-up(lg) {
                        border-bottom: 0;
                    }

                    input {
                        padding: 0.6875rem 0.9375rem;
                        border-radius: 0;
                        
                        @include media-breakpoint-up(xs) {
                            padding-left: 0;
                        }
                        
                        @include media-breakpoint-up(lg) {
                            padding-left: 0.9375rem;
                            border: 0;
                        }
                    }
                    
                    button[type=submit] {
                        border-radius: 0;
                        padding: 11px 15px;
                        
                        @include media-breakpoint-up(lg) {
                            border: 0;
                        }

                        svg {
                            path {
                                fill: $color-blue;
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .brand-list {
                
                @include media-breakpoint-up(xs) {
                    margin-top: 46px;
                }
                
                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                }

                .brand-item {
                    label {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0.75rem 0.9375rem;
                        border-bottom: 1px solid rgba(204, 204, 204, 0.2);
                        margin: 0;

                        input[type=radio],
                        input[type=checkbox] {
                            display: none;
                            opacity: 0;
                            visibility: hidden;
                        }

                        .brand-title {
                            font-weight: $fw700;
                            color: $color-black;
                            font-size: 12px;
                        }

                        .check-mark {
                            border: 1px solid #D5E1E3;
                            background-color: $color-white;
                            height: 18px;
                            width: 18px;
                            border-radius: 100px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
            
                            svg {
                                height: 10px;
                                width: auto;
            
                                path {
            
                                    fill: $color-black;
                                    opacity: 0;
                                }
                            }
                        }
            
                        input:checked ~ .check-mark {
                            background-color: $color-blue;
                            border-color: $color-blue;
            
                            svg {
            
                                path {
                                    fill: $color-white;
                                    opacity: 1;
                                }
                            }
                        }
            
                    }
                }
            }
        }
    }
}

//=======================================
// UI * Filter - Sizes
//=======================================
._filter._filter-wrapper.filter-sizes {

    ._filter-item._filter-show {
        
        @include media-breakpoint-up(xs) {
            position: relative;
        }
        
        @include media-breakpoint-up(lg) {
            position: absolute;
            display: table;
            overflow: inherit;
            // height: 100vh;
        }

        .wrap-size {
            border-radius: 0;
            border: 0;
            // max-height: 402px;
            // overflow: hidden;
            // overflow-y: auto;
            // display: none;

            button.btn-filter-size {
                background-color: transparent;
                border: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.75rem 0.9375rem;
                width: $percent-100;
                border-bottom: 1px solid rgba(204, 204, 204, 0.2);

                ._filter-title {
                    font-weight: $fw700;
                    color: $color-black;
                    font-size: 12px;
                }
    
    
                .chevron-icon {
                    svg {
                        height: 13px;
                        width: auto;
    
                        path {
    
                            fill: $color-black;
                            opacity: 0.5;
                        }
                    }
                }
            }

            button.back-list-sizes {
                justify-content: flex-start;
                border-bottom: 1px solid rgba(204, 204, 204, 0.2);

                ._filter-title {
                    margin-left: 15px;
                }
            }

            .size-panel {
                padding: 0.625rem 0.9375rem;

                .wrap-history-filters {
                    padding-left: 0;
                    padding-right: 0;
                    padding-top: 5px;
                }
                
                .wrap-filter-results {
                    overflow-y: inherit;
                    overflow: inherit;
                .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1,
                .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 {

                    .MuiGrid-root.MuiGrid-item {

                        label {
                            border: 1px solid $color-ice;
                            height: $percent-100;
                            width: $percent-100;
                            min-height: 32px;
                            border-radius: 0.3125rem;
                            position: relative;
                            overflow: hidden;

                            input[type=radio],
                            input[type=checkbox] {
                                display: none;
                                visibility: hidden;
                                opacity: 0;
                            }

                            span.title {
                                font-size: 0.6875rem;
                                font-weight: $fw700;
                                color: $color-black;
                                text-align: center;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: $percent-100;
                                position: relative;
                                z-index: 99;
                                white-space: nowrap;
                            }

                            span.overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                background-color: $color-white;
                                height: $percent-100;
                                width: $percent-100;
                            }

                            input:checked ~ span.overlay {
                                background-color: $color-blue;
                            }

                            input:checked ~ span.title {
                                color: $color-white;
                            }


                        }
                    }
                }
                }

                .filter-actions {
                    @include media-breakpoint-up(lg) {
                        position: absolute;
                        bottom: inherit;
                        -webkit-box-shadow: 0 8px 8px 6px rgba(100,100,100,0.1);
                        box-shadow: 0 8px 8px 6px rgba(100,100,100,0.1);
                    }
                }
            }
        }

        .wrap-size.active-size {
            position: absolute;
            top: 0;
            left: 0;
            width: $percent-100;
            z-index: 999;
            
            @include media-breakpoint-up(xs) {
                height: 100vh;
            }
            
            @include media-breakpoint-up(lg) {
                height: 100%;
                display: table;
            }

            .list-size-item {
                display: none;
            }
        }
    }
}

.__form._sidebar {
    
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include media-breakpoint-up(xs) {
        max-width: none;
        margin-bottom: 15px;
    }
    
    @include media-breakpoint-up(lg) {
        max-width: 220px;
        min-width: 220px;
        display: block;
        margin-bottom: 0;
    }

    ._form-filter-footer {

        @include media-breakpoint-up(xs) {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            display: block;
        }

        button {
            display: block;
            font-size: 12px;
        }
    }

._wrap-filter {

    @include media-breakpoint-up(xs) {
        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        margin-left: 0;
    }

    ._filter {

        
        
        ._filter-label {
            font-size: 13px;
            font-weight: $fw700;
            color: $color-gray-bolder;
            margin-bottom: 6px;
            @include media-breakpoint-up(xs) {
                display: none;
            }
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        ._filter-bar {
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
            margin-bottom: 6px;
            background-color: $color-white;
            padding: 0.438rem 0.466rem;
            border-radius: 4px;
            
            @include media-breakpoint-up(xs) {
                border: 1px solid #eaeaea;
                cursor: pointer;
                display: flex;
            }
            
            @include media-breakpoint-up(lg) {
                display: none;
                border: 0;
                cursor: default;
            }

            a {
                max-width: none;
                width: auto;
                padding: 0.169rem 0.075rem;
                padding-top: 0;
                padding-bottom: 0;
                
                svg {
                    height: 15px;
                    width: auto;

                    path {
                        fill: $color-gray;
                    }
                }
            }

            ._filter-title {
                font-weight: $fw700;
                color: $color-gray-bolder;
                
                @include media-breakpoint-up(xs) {
                    font-size: 11px;
                }
                
                @include media-breakpoint-up(lg) {
                    font-size: 13px;
                }
            }
        }

        ._filter-content {
            

            ._form-filter-content {
                margin-bottom: 15px;

                ._mui-slider {
                    margin-top: 40px;
                    padding-left: 15px;
                }

                ._filter-item {
        
                    label {
                        input { display: none; }
                    }
        
                    ._name {
                        color: $color-gray-bolder;
                        font-size: 13px;
                        cursor: pointer;
                    }
        
                }

                ._form-filter-content._type-default {
                border: 0;
                
                .form-group {
                    
                    ._wrap-min-max {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;

                        ._wrap-input-group {
                            
                            ._label {
                                font-size: 12px;
                                color: $color-gray;
                            }

                            padding-left: 10px;
                            
                            &:first-child {
                                padding-left: 0;
                            }

                            .input-group {

                                .input-group-prepend {
                                    .input-group-text {
                                        font-size: 12px;
                                        font-weight: $fw700;
                                        color: $color-gray-bolder;
                                    }
                                }

                                .form-control {
                                    &:focus,
                                    &:active {
                                        border-color: $color-border !important;
                                        border-left: 0 !important;
                                    }
                                }
                            }

                        }
                    }

                }
            }
        
            }

            ._form-filter-content._type-default {
                border: 0;
                
                @include media-breakpoint-up(xs) {
                    @include padding-lt-rt;
                }
                
                @include media-breakpoint-up(lg) {
                    padding: 0;
                }
                
                .form-group {
                    
                    ._wrap-min-max {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;

                        ._wrap-input-group {
                            width: $percent-100;

                            ._label {
                                font-size: 12px;
                                color: $color-gray;
                            }

                            padding-left: 10px;
                            
                            &:first-child {
                                padding-left: 0;
                            }

                            .input-group {

                                .input-group-prepend {
                                    .input-group-text {
                                        font-size: 12px;
                                        font-weight: $fw700;
                                        color: $color-gray-bolder;
                                    }
                                }

                                .form-control {
                                    &:focus,
                                    &:active {
                                        border-color: $color-border !important;
                                        border-left: 0 !important;
                                    }
                                }
                            }

                        }
                    }

                }
            }

            ._form-filter-footer {
                button {
                    font-size: 12px;
                }
            }
            
        }

    }

    ._filter._active {

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-white;
        z-index: 9999;

        ._filter-bar {
            background-color: $color-white;
            width: $percent-100;
            height: 50px;
            border: 0;
            border-bottom: 1px solid $color-ice;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include padding-lt-rt;
            flex-direction: row;
            border-radius: 0;
            
            a {
                
                .arrow-left {
                    display: block;
                }

                .chevron-down {
                    display: none;
                }

            }
            

            ._filter-title {
                font-size: 12px;
            }
        }

        ._filter-content {

            display: block;

            ._form-filter-content {

                ._filter-item {
                    border-bottom: 1px solid $color-ice;
                    .form-check-label {
                        padding: 0.625rem 0.75rem;
                    }

                }

            }

            ._form-filter-footer {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                
                button {
                    font-size: 13px;

                }
            }

        }
    }

    ._filter._inactive {

        ._filter-bar {

            a {

                svg {
                    margin-left: 5px;
                }

                .arrow-left {
                    display: none;
                }

                .chevron-down {
                    
                    @include media-breakpoint-up(xs) {
                        display: block;
                    }

                    @include media-breakpoint-up(lg) {
                        display: none;
                    }

                }
            }

        }

        ._filter-content {

            @include media-breakpoint-up(xs) {
                display: none;
            }

            @include media-breakpoint-up(lg) {
                display: block;
            }

            ._form-filter-content._type-select {
                border: 1px solid $color-border;
                border-radius: 5px;

                ._filter-item {
                    border-bottom: 1px solid $color-border;

                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }

            ._form-filter-content {

                ._filter-item {

                    display: flex;
                    align-items: center;

                    .form-check-label {
                        position: relative;
                        width: 100%;
                        padding: 0.625rem 0.75rem;


                        .check {
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            border: 0;

                            svg {
                                display: none;
                            }

                        }

                        ._name {
                            position: relative;
                            z-index: 999;
                        }

                        .form-check-input:checked ~ .check {
                            background-color: $color-ice;
                        }

                        
                    }
                    &::after {
                        display: block;
                        content: '';
                        background-image: url('#{$path-image}/arrow-select-right.png');
                        background-repeat: no-repeat;
                        background-size: 8px;
                        height: 20px;
                        width: 20px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin-top: auto;
                        margin-bottom: auto;
                        opacity: 0.7;
                    }
                }

            }

            ._form-filter-footer {
                @include media-breakpoint-up(xs) {
                    display: flex;
                }
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

        }

    }
    
}
}

._form-order-by {

    ._apply-finter-button {

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

//=======================================
// UI * Filter - Modal Categories
//=======================================

.form.form-filter {

    .search {
        padding: 0 15px;
        max-width: 430px;
        width: $percent-100;
        margin: 0 auto;
        margin-top: 15px;
        margin-bottom: 30px;

    }

    ._wrap-items {
        overflow: hidden;
        overflow-y: scroll;
        height: 100%;
        background-color: $color-white;
        padding-bottom: 15px;

        @include media-breakpoint-up(xs) {
            max-height: none;
        }

        @include media-breakpoint-up(md) {
            max-height: 360px;
        }
    }

}

.form.form-filter._brands {
    margin-left: 15px !important;
}

//=======================================
// UI * Filter - Mobile Filters Head
//=======================================

.wrapper-mobile-filters-head {
    z-index: 99;

    @include media-breakpoint-up(xs) {
        display: block;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }

    .mobile-filters-head {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        background-color: $color-ice;
        width: $percent-100;

        button {
            width: $percent-100;
            border: 0;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 14.5px 15px;

            &:nth-child(1) {
                border-right: 1px solid #ededed;
            }

            // &:nth-child(2) {
            //     justify-content: flex-end;
            // }

            .text {
                font-size: 11px;
                font-weight: $fw700;
                font-family: $font-nunito !important;
                color: $color-blue;
                text-transform: uppercase;
            }
        
            .icon {
                margin-left: 5px;

                svg {
                    width: auto;
                    
                    path {
                        fill: $color-blue;
                    }
                }
            }
        
            .icon.chevron {
                line-height: 1;

                svg {
                    height: 11px;
                }
            }
        
            .icon.tune {

                svg {
                    height: 16px;
                }
            }
        }
    }

    .current-selected-filters {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        width: $percent-100;
        overflow: scroll;
        position: relative;
        padding: 0 15px;
        background-color: $color-white;
        border-bottom: 1px solid $color-ice;

        .current-filters {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            h2 {
                font-size: 10px;
                font-weight: $fw700;
                color: $color-black;
            }

            .wrap-history-filters {
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;
                width: $percent-100;
                padding: 9px 15px;
    
                .btn-remove-filter {
                    margin-left: 10px;
                    padding: 0.375rem 0.4688rem 0.375rem 0.4688rem;
    
                    &:first-child {
                        margin-left: 0;
                    }

                    .text {
                        font-size: 11px;
                        text-align: left;

                        span {
                            display: block;
                            font-size: 9px;
                            text-align: left;
                        }
                    }

                    .icon {
                        margin-left: 6px;
                    }
                }
            }
        }

        .clear-filters {
            width: 100%;

            button {
                font-weight: $fw700;
                color: #DC3545;
                font-size: 12px;
                white-space: nowrap;
                padding-left: 0.9375rem;
                padding-right: 1.5625rem;
                max-width: 130px;
            }

            svg {
                height: 20px;
                width: auto;

                path {

                    fill: $color-blue;
                    opacity: 1;
                }
            }
        }
    }
}

.wrapper-mobile-filters-head.desktop {
    width: $percent-100;

    @include media-breakpoint-up(xs) {
        display: none;
    }
    
    @include media-breakpoint-up(lg) {
        display: block !important;
        margin-bottom: 20px;

    }

    .current-selected-filters {
        overflow: hidden;
        overflow-y: auto;
    }
}

//=======================================
// UI * Filter - Helpers
//=======================================

.btn-remove-filter {
    border: 0;
    border: 1px solid #E5EAEB;
    background-color: $color-white;
    padding: 6px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);

    .text {
        font-size: 12px;
        font-weight: $fw700;
        color: $color-black;
        white-space: nowrap;
    }

    .icon {
        height: 14px;
        width: 14px;
        min-width: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        background-color: $color-blue;
        margin-left: 4px;

        svg {
            height: 12px;
            width: auto;

            path {
                fill: $color-white;
            }
        }
    }
}