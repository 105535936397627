#view_my-wallet {

  ._title-box {
    margin-bottom: 1px;
  }

  ._title-box._i-wallet {

    span {
      position: relative;
      top: -0.4375rem;
    }
  }

  ._columns {

    ._column._points {
      background-color: #F9F9F9;
      margin-bottom: 1px;

      ._block {
        ._list-content {
          li {
            border-bottom: 0;
            padding-top: 20px;
            padding-bottom: 0;

            ._item-content {
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;

              @include media-breakpoint-up(md) {
                max-width: 200px;
              }

              svg {
                height: 16px;
                width: auto;
                path {
                  fill: $color-gray;
                  opacity: 0.8;
                }
              }

              ._product-price {
                .ui-item__price {
                  .price-tag-fraction {
                    @include media-breakpoint-up(xs) {
                      font-size: 28px;
                    }
                  }

                  .price-tag-symbol {
                    @include media-breakpoint-up(xs) {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }

        ._list-content.__list-dsc {
          
          li {
            padding: 8px 15px;
            border-bottom: 1px dashed #ccc;

            &:last-child {
              border-bottom: 0;
            }

            ._item-title {
              margin-bottom: 4px;
            }

            ._item-content {

              ._price.real {

                .ui-item__price {

                  .price-tag-symbol {
                    top: -2px;
                  }

                  .price-tag-fraction {
                    line-height: 1;
                  }
                }
              }
            }

          }
        }

        button.btn-scamb-action {
          max-width: 146px;
          margin: 0.3125rem 0.9375rem 1.25rem 0.9375rem;
          padding: 0.4063rem 0.75rem;
        }

        button.btn-scamb-action.button-transfer {
          &:disabled {
            opacity: 0.5;
          }
        }

        .last-update {
          background-color: #F9F9F9;
          padding: 15px;
          color: $color-black;
          font-weight: $fw700;
          font-size: 0.6875rem;
        }
      }
    }

    ._column._actions {
      background-color: #F9FAFE;
      
      ._med-container {
        
        padding-left: 0;
        padding-right: 0;
        
        .MuiTabs-root._material-tabs {
          border-bottom: 1px solid $color-ice;

          .MuiTabs-scroller {

            .MuiTabs-flexContainer {
              justify-content: flex-start;
            }
          }
        }

        .MuiTabs-root._material-tabs._material-tabs-pj {
          .MuiTabs-scroller {

            .MuiTabs-flexContainer {
              justify-content: flex-start;

              button {
                @include media-breakpoint-up(xs) {
                  width: 50%;
                }

                @include media-breakpoint-up(md) {
                  width: auto;
                }
              }
            }
          }
        }

        .tab-panel {
          background-color: $color-white;
          padding-top: 15px;
          padding-bottom: 20px;

          ._filters {
            // border-top: 1px solid $color-ice;
            overflow: hidden;

            .select-filter {
              padding: 0.9375rem 0.9375rem 0.9375rem 0.9375rem;
              
              .title {
                font-size: 0.625rem;
                font-weight: $fw700;
                color: $color-black;
                margin-bottom: 0.375rem;
              }
              .wrap-filters-button {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                overflow-y: auto;

                .filter-button {
                  margin-left: 10px;
                  
                  &:first-child {
                    margin-left: 0;
                  }
                  
                  label {
                    cursor: pointer;
                    background-color: $color-white;
                    -webkit-box-shadow: 0 2px 8px 1px  rgba(100,100,100,0.1);
                    box-shadow: 0 2px 8px 1px  rgba(100,100,100,0.1);
                    padding: 0.5rem 0.9375rem;
                    margin-bottom: 0;
                    position: relative;
                    overflow: hidden;
                    border-radius: 0.3125rem;
                    
                    input[type=radio],
                    input[type=checkbox] {
                      display: none;
                      visibility: hidden;
                      opacity: 0;
                    }
                    
                    .text {
                      font-size: 12px;
                      font-weight: $fw700;
                      color: $color-black;
                      position: relative;
                      z-index: 1;
                      white-space: nowrap;
                    }
                    
                    .overlay {
                      position: absolute;
                      top: 0;
                      left: 0;
                      height: $percent-100;
                      width: $percent-100;
                      background-color: $color-white;
                      border: 0.0625rem solid #E5EAEB;
                      border-radius: 0.3125rem;
                    }
                    
                    input:checked ~ .overlay {
                      background-color: $color-blue;
                      border: 0.0625rem solid $color-blue;
                    }

                    input:checked ~ .text {
                      color: $color-white;
                    }
                  }
                }
              }
            }
          }

          .content {
            padding: 5px 15px;

            ._history-block {
              margin: 0;
              margin-bottom: 15px;
              cursor: pointer;

              &:last-child {
                margin-bottom: 0;
              }
            }

            ._order-timeline.points {

              ._timeline-item {

                ._col {
                  width: $percent-100;

                  .icon {

                    &::before,
                    &::after {
                      height: 40px;
                    }
                  }

                  .title {

                    .expiration-date {

                      span {
                        display: inline-block;
                        margin-left: 5px;
                      }
                    }

                    .icon-points {

                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      margin-top: 2px;

                      .icon-coins {

                        svg {
                          height: 16px;
                          width: auto;
                          position: relative;
                          top: 2px;
    
                          path {
                            fill: $color-black;
                            opacity: 1;
                          }
                        }
                      }
    
                      .title {
                        font-weight: $fw700;
                        color: $color-blue;
                        font-size: 11px;
                        
                        @include media-breakpoint-up(xs) {
                          margin-left: 4px;
                        }
                        
                        @include media-breakpoint-up(md) {
                          margin-left: 6px;
                        }
    
                        ._product-price {
    
                          .ui-item__price {
    
                            .price-tag-fraction {
                              font-size: 16px;
                              line-height: 1;
                            }
    
                            .price-tag-symbol {
                              top: 0;
                              font-size: 10px;
                            }

                            .price-tag-fraction,
                            .price-tag-symbol {
                              color: $color-blue;
                            }
                          }
                        }
    
                        ._product-price.green {
    
                          .ui-item__price {
    
                            .price-tag-fraction,
                            .price-tag-symbol {
                              color: $color-green;
                            }
                          }
                        }
    
                        ._product-price.red {
    
                          .ui-item__price {
    
                            .price-tag-fraction,
                            .price-tag-symbol {
                              color: $color-red;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              ._timeline-item.no-active {

                ._col {

                  .title {

                    .icon-points {


                      .icon-coins {

                        svg {
                          path {
                            opacity: 0.5;
                          }
                        }
                      }
    
                      .title {
                        opacity: 0.5;
                      }
                    }
                  }
                }
              }

            }

            // * Button Load More
            .wrap-button-load-more {
              text-align: center;
              margin-top: 20px;

              .btn.load-more {
                font-size: 11px;
                font-weight: $fw700;
                color: $color-black;
                background-color: $color-ice-bold;
                max-width: 145px;
                width: $percent-100;
                margin: 0 auto;
              }
            }
          }
        }

      }
    }

    ._column._history {
      background-color: $color-white;
      padding-bottom: 0.9375rem;

      ._block {

        ._block-title {
          padding-bottom: .9375rem;
          display: flex;
          align-items: center;
          justify-content: space-between;

          button.filters {
            padding: .350rem;
            max-width: 86px;
            background-color: $color-ice-bold;
            color: $color-black;
            font-size: 13px;
            
            &:focus,
            &:active {
              background-color: $color-ice !important;
            }

            svg {
              margin-left: 4px;
              path {
                fill: $color-gray-bolder;
              }
            }
          }
        }

        ._history-block {
          padding: 0.769rem 0.9375rem;
          padding-right: 0;
          margin: 0.9375rem;
          margin-top: 0;
          margin-bottom: 0.625rem;
          -webkit-box-shadow: 0 4px 6px 1px rgba(200,200,200,0.1);
          box-shadow: 0 4px 6px 1px rgba(200,200,200,0.1);
          border: 1px solid #F9F9F9;
          border-radius: 4px;

          ._cols {
            
            margin-top: 4px;

            ._col {

              .title {
                font-weight: $fw700;
                color: $color-blue;
                font-size: 13px;
              }

              .items {

                margin-top: 5px;
                display: flex;

                .item {

                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  
                  @include media-breakpoint-up(xs) {
                    margin-left: 10px;
                  }
                  
                  @include media-breakpoint-up(md) {
                    margin-left: 15px;
                  }

                  &:first-child {
                    margin-left: 0;
                  }

                  .icon {

                    svg {
                      height: 16px;
                      width: auto;
                      position: relative;
                      top: 2px;

                      path {
                        fill: $color-black;
                        opacity: 0.5;
                      }
                    }
                  }

                  .title {
                    font-weight: $fw700;
                    color: $color-blue;
                    font-size: 11px;
                    
                    @include media-breakpoint-up(xs) {
                      margin-left: 4px;
                    }
                    
                    @include media-breakpoint-up(md) {
                      margin-left: 6px;
                    }

                    ._product-price {

                      .ui-item__price {

                        .price-tag-fraction {
                          font-size: 14px;
                        }

                        .price-tag-symbol {
                          top: 0;
                        }
                      }
                    }

                    ._product-price.green {

                      .ui-item__price {

                        .price-tag-fraction,
                        .price-tag-symbol {
                          color: $color-green;
                        }
                      }
                    }

                    ._product-price.red {

                      .ui-item__price {

                        .price-tag-fraction,
                        .price-tag-symbol {
                          color: $color-red;
                        }
                      }
                    }
                  }

                  &:last-child {
                    
                    @include media-breakpoint-up(xs) {
                      display: none;
                    }
                    
                    @include media-breakpoint-up(md) {
                      display: flex;
                    }
                  }
                }
              }

              .btn {

                padding: 0.469rem .9375rem;

                svg {
                  height: 24px;
                  width: auto;
                }
              }
            }
          }
        }

        ._wrap-load-more {
          text-align: center;
          margin-top: 15px;

          button.load-more {
            padding: .350rem;
            max-width: 145px;
            background-color: $color-ice-bold;
            color: $color-gray;
            font-size: 11px;
            
            &:focus,
            &:active {
              background-color: $color-ice-bold !important;
            }
          }
        }
      }
    }
  }
}

#_view_activity-history,
#_my-wallet {
  ._title-box._with-breadbrumb {
    span {
      font-size: 12px;
      color: $color-gray;
      display: inline-block;
      margin-left: 5px;
    }
  }

  ._wrap-cards._flex {
    display: flex;
    align-items: flex-start;

    @include media-breakpoint-up(xs) {
      flex-direction: column;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  ._wrap-cards {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    .ui-card {
      .ui-card-content {
        .my-points {
          ._product-price {
            .ui-item__price {
              .price-tag-fraction {
                font-size: 24px;
                font-weight: $fw400;
                line-height: 1.4;
              }

              .price-tag-symbol {
                top: 3px;
              }
            }
          }
        }

        .points_informations {
          margin-top: 15px;

          ._list-info {
            li {
              border-bottom: 1px solid #f9f9f9;
              padding: 6px 0;
              display: flex;
              justify-content: space-between;

              @include media-breakpoint-up(xs) {
                align-items: flex-start;
                flex-direction: column;
              }

              @include media-breakpoint-up(md) {
                align-items: center;
                flex-direction: row;
              }

              &:first-child {
                border-top: 2px solid #f9f9f9;
              }

              .key,
              .val {
                font-size: 13px;
                font-weight: $fw700;
                color: $color-gray;
              }

              .val {
                color: $color-gray-bolder;
                @include media-breakpoint-up(xs) {
                  font-size: 11px;
                  margin-top: 5px;
                }

                @include media-breakpoint-up(md) {
                  font-size: 13px;
                }
              }
            }
          }
        }

        .add-points {
          margin-top: 30px;

          .add-points-title {
            font-size: 14px;
            font-weight: $fw700;
            color: $color-black;
          }

          .add-points-content {
            display: flex;
            margin-top: 15px;
            padding-bottom: 25px;

            @include media-breakpoint-up(xs) {
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
            }

            @include media-breakpoint-up(md) {
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            }

            .link-button {
              @include media-breakpoint-up(xs) {
                margin-top: 10px;
              }

              @include media-breakpoint-up(md) {
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    .ui-card.activity-history {
      .ui-card-content {
        padding-left: 0;
        padding-right: 0;

        // Accordion
        ._activity-history {
          .card {
            .card-header {
              button.btn {
                ._activity-history-info {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;

                  ._activity-date {
                    text-transform: uppercase;
                    font-size: 15px;
                    font-weight: $fw700;
                    color: $color-blue;
                    width: 80px;
                    height: auto;

                    .the-day {
                      display: block;
                      font-size: 12px;
                    }
                  }

                  ._activity-info {
                    color: $color-black;
                    font-weight: $fw700;
                    text-align: left;

                    ._activity-type {
                      font-size: 12px;
                      margin-bottom: 6px;

                      ._amount {
                        font-size: 14px;
                        span {
                          color: $color-blue;
                        }
                      }
                    }

                    ._activity-payment-type {
                      font-size: 12px;
                      margin-bottom: 3px;
                    }

                    ._activity-amount {
                      font-size: 14px;
                      color: $color-blue-light;
                      margin-bottom: 0px;
                    }

                    ._activity-status {
                      display: inline-block;
                      font-size: 10px;
                      color: $color-gray;
                      border-radius: 2px;
                      padding: 0.094rem 0.5rem;

                      svg {
                        width: 8px;
                        height: auto;
                        margin-left: 4px;
                      }
                    }

                    ._activity-status.pending {
                      svg {
                        path {
                          fill: $color-blue;
                        }
                      }
                    }

                    ._activity-status.approved {
                      svg {
                        path {
                          fill: $color-green;
                        }
                      }
                    }

                    ._activity-status.canceled {
                      svg {
                        path {
                          fill: $color-red;
                        }
                      }
                    }
                  }
                }

                ._activity-image {
                  width: 48px;
                  height: 48px;
                  overflow: hidden;
                  border-radius: 5px;
                  border: 1px solid $color-ice;

                  img {
                    height: auto;
                    width: 100%;
                  }
                }
              }
            }

            .collapse {
              .card-body {
                ._wrap-data-info {
                  margin-bottom: 20px;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  ._data-info-title {
                    font-weight: $fw700;
                    font-size: 13px;
                    color: $color-gray;
                  }

                  ._data-info-content {
                    font-weight: $fw700;
                    font-size: 13px;
                    color: $color-gray-bolder;
                    margin-top: 5px;
                  }
                }
              }
            }
          }
        }

        ._load-more-activities {
          border-top: 1px solid $color-ice-bold;
          text-align: center;
          padding: 15px 0;

          .link-button._load-more {
            border-radius: 4px;
          }
        }
      }
    }

    .ui-card._invite {
      .ui-card-content {
        padding-bottom: 25px;

        ._invite-label {
          .form-group {
            display: flex;

            .form-control {
              font-size: 13px;
              font-weight: $fw700;
              color: $color-black;
              border-right: 0;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }

            .btn {
              max-width: none;
              width: auto;
              border: 1px solid #eaeaea;
              border-left: 0px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;

              svg {
                width: 15px;
                height: auto;

                path {
                  fill: $color-gray;
                }
              }
            }
          }
        }

        ._guests-list {
          li {
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            .guests-info {
              color: $color-gray;
              font-size: 12px;
              font-weight: $fw700;
            }

            .guests-count {
              color: $color-black;
              font-size: 13px;
              display: inline-block;
              margin-left: 4px;
            }
          }
        }

        .link-button {
          margin-top: 15px;
        }
      }
    }

    .ui-card._cc {
      .ui-card-content {
        padding: 0;
      }
    }
  }
}

// UI CARD DEFAULTS
._wrap-cards {
  @include media-breakpoint-up(xs) {
    margin-right: 15px;
    margin-left: 15px;
  }

  @include media-breakpoint-up(md) {
    margin-right: 0;
    margin-left: 0;
  }

  .ui-card._medium {
    max-width: 370px;
    width: 100%;
    margin: 0 auto;

    @include media-breakpoint-up(xs) {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
      margin-left: 15px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .ui-card._fluid {
    max-width: 770px;
    width: 100%;
    margin: 0 auto;
  }
}

// RESET
#view_my-wallet {
  #view_my-account-layout {
    ._wrap-content {
      ._cols {
        ._col._col-right {
          border: 0;
          background-color: transparent;
        }
      }
    }
  }
}

//UI
.ui-card {
  border: 1px solid #f1f1f1;
  background-color: $color-white;

  .ui-card-head {
    padding: 15px 15px 15px 15px;
    .ui-card-title {
      font-size: 14px;
      font-weight: $fw700;
    }

    button.btn,
    a.btn {
      max-width: none;
      width: auto;
      padding: 0.169rem 0.275rem;

      svg {
        height: 16my-pointspx;
        width: auto;

        path {
          fill: $color-gray;
        }
      }
    }
  }

  .ui-card-head._with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ui-card-content {
    padding: {
      top: 0;
      bottom: 0;
      left: 15px;
      right: 15px;
    }
  }
}

._update-cc {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include media-breakpoint-up(xs) {
    flex-direction: column;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  ._update-cc-informations {
    max-width: 350px;
    width: 100%;

    ._update-cc-title {
      font-size: 14px;
      font-weight: $fw700;
      color: $color-gray-bolder;
    }

    ._payment-methods-list {
      margin-top: 20px;
      margin-bottom: 15px;
    }

    ._form {
      .form-group-button {
        margin: 0;

        button.btn {
          max-width: 360px;
        }
      }
    }
  }

  ._update-address {
    max-width: 350px;
    width: 100%;

    @include media-breakpoint-up(xs) {
      margin-top: 15px;
    }

    @include media-breakpoint-up(md) {
      margin-top: 72px;
      margin-left: 15px;
    }

    ._user-location {
      max-width: none;
    }

    .link-button {
      width: 100%;
      text-align: center;
      color: $color-white;
      border-color: $color-ice-bold;
      margin-top: 10px;
      font-size: 11px;
      padding: 0.313rem 0;
    }
  }
}

._wrap-current-cc {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include media-breakpoint-up(xs) {
    align-items: flex-start;
    flex-direction: column;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
  }

  ._current-cc-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ._cc-flag {
      text-align: center;

      ._cc-flag-icon {
        width: 40px;
        height: 26px;
        border-radius: 2px;
        border: 1px solid $color-ice;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        img {
          width: 22px;
          min-width: 22px;
          height: auto;
        }
      }

      ._tag {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 13px;
        width: 56px;
        background-color: $color-green;
        border-radius: 100px;
        font-size: 7px;
        font-weight: $fw700;
        color: $color-white;
        text-transform: capitalize;
        margin: 0 auto;
        margin-top: 4px;
      }
    }

    ._cc-info {
      margin-left: 10px;
      ._cc-number-prev,
      ._cc-exp-date,
      ._cc-name {
        color: $color-gray-bolder;
        font-weight: $fw700;
        font-size: 12px;
        text-align: left;
      }

      ._cc-exp-date {
        font-size: 10px;
      }

      ._cc-name {
        margin-top: 8px;
      }
    }
  }

  .btn._delete-card {
    font-size: 10px;
    font-weight: $fw700;
    color: $color-white !important;
    background-color: $color-red;
    padding: 0.094rem 0.58rem !important;
    border-radius: 4px;
    justify-content: center !important;
    max-width: 100px !important;

    @include media-breakpoint-up(xs) {
      margin-left: 68px !important;
      margin-top: 5px !important;
    }

    @include media-breakpoint-up(md) {
      margin-left: 0 !important;
      margin-top: 0 !important;
    }
  }
}

// Button

.btn-add-payment {
  background-color: #f9f9f9;
  font-size: 13px;
  padding: 10px 15px;
  border-radius: 4px;
  font-weight: 700;
  color: $color-black;
  border: 0;

  @include media-breakpoint-up(xs) {
    max-width: 160px;
    font-size: 12px;
  }

  @include media-breakpoint-up(md) {
    max-width: none;
    font-size: 13px;
  }

  svg {
    margin-left: 10px;

    path {
      fill: $color-gray;
    }
  }
}
