.wrap-view.add-address,
.wrap-view.add-credit-card {
  ._view-content {
    height: calc(96% - 50px);
    position: relative;
    form {
      ._payment-methods-list {
        margin-bottom: 30px;
      }

      ._set-user-location {
        margin-top: 15px;
        margin-bottom: 10px;
      }

      .btn.btn-border {
        border: 1px solid #eaeaea;
        max-width: none;
        width: $percent-100;
        color: #7d7d81;
        height: 30px;
        font-size: 11px;
        padding: 0.409rem 0.75rem;
      }

      .btn.btn-step {
        margin-top: 30px;
        position: absolute;
        bottom: 15px;
        max-width: 300px;
        left: 15px;
      }
    }
  }
}

//====================================
// UI - Bootstrap Modal
//====================================
.full-width {
  div.modal-dialog {
    div.modal-content {
      div.modal-body {
        form {
          @include media-breakpoint-up(md) {
            padding-bottom: 30px;
            max-width: 360px;
            margin: 0 auto;
          }

          ._payment-methods-list {
            margin-bottom: 30px;
          }

          ._set-user-location {
            margin: 20px 0 10px 0;
          }

          .btn.btn-border {
            border: 1px solid #eaeaea;
            max-width: none;
            width: $percent-100;
            color: #7d7d81;
            height: 30px;
            font-size: 11px;
            padding: 0.409rem 0.75rem;
          }

          .btn.btn-submit-modal {
            display: block;

            @include media-breakpoint-up(md) {
              margin: auto;
              margin-top: 20px;
              max-width: 320px;
            }
          }
        }

        ._form-address-cc {
          @include media-breakpoint-up(md) {
            padding-bottom: 116px;
          }
        }
      }
    }
  }
}

//===========================================
// UI * Material ui Modal Diloag - Address
//===========================================
form {
  @include media-breakpoint-up(md) {
    padding-bottom: 10px;
  }
  
  .MuiDialogContent-root._modal-add-address {
    padding: 8px 15px;

    @include media-breakpoint-up(md) {
      padding-top: 10px !important;
    }

    @include media-breakpoint-up(xs) {
      padding-top: 0 !important;
    }

    .MuiTypography-root {
      ._content-title {
        font-size: 13px;
        color: $color-gray-bolder;
        line-height: 24px;
        max-width: 320px;
        width: $percent-100;
        margin: 0 auto;
        margin-bottom: 20px;
        text-align: center;
        margin-top: 5px;
        font-family: $font-comfortaa;

        @include media-breakpoint-up(xs) {
          font-weight: $fw700;
        }

        @include media-breakpoint-up(md) {
          font-weight: $fw400;
        }
      }
    }
  }
}

//===========================================
// UI * Material ui Modal Diloag - Cdt Card
//===========================================
form {
  @include media-breakpoint-up(md) {
    padding-bottom: 10px;
  }

  .MuiDialogContent-root._modal-add-credit-card {
    padding: 8px 15px;

    @include media-breakpoint-up(md) {
      padding-top: 10px !important;
    }

    @include media-breakpoint-up(xs) {
      padding-top: 0 !important;
    }

    .MuiTypography-root {
      ._content-title {
        font-size: 13px;
        color: $color-gray-bolder;
        line-height: 24px;
        max-width: 320px;
        width: $percent-100;
        margin: 0 auto;
        margin-bottom: 20px;
        text-align: center;
        margin-top: 5px;
        font-family: $font-comfortaa;

        @include media-breakpoint-up(xs) {
          font-weight: $fw700;
        }

        @include media-breakpoint-up(md) {
          font-weight: $fw400;
        }
      }

      ._payment-methods-list {
        margin-bottom: 30px;
      }

      ._set-user-location {
        margin-top: 15px;
        margin-bottom: 10px;
        max-width: 100%;
        font-family: $font-comfortaa;
      }

      .btn.btn-border {
        border: 1px solid #eaeaea;
        max-width: none;
        width: $percent-100;
        color: #7d7d81;
        height: 30px;
        font-size: 11px;
        padding: 0.409rem 0.75rem;
        font-family: $font-comfortaa;
        background-color: $color-white;
      }
    }
  }
}
