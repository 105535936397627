#my-profile {
  ._title-box {
    margin-bottom: 10px;
  }

  ._add-payment-method {
	background-color: $color-white;
	  ._wrapper {
		  text-align: left;
	  }
  }
}

._wrap-form-header {
  background-color: $color-ice;
  padding-left: 15px;
  padding-right: 0;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $color-ice-bold;

  ._title-box {
    font-weight: $fw700;
    padding: 0 !important;
    opacity: 0.7;
    background-color: $color-ice;
    margin-bottom: 0 !important;
    font-size: 13px;
    // font-family: 'Nunito', sans-serif;
  }

  ._title-box._link {

    a {
      color: $color-black;

      svg {

        width: auto;
        height: 13px;
        margin-left: 5px;
        position: relative;
        top: 1px;

        path {
          fill: $color-black;
          opacity: 0.6;
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      max-width: none;
      width: auto;
      height: 50px;
      padding: 0 0.650rem;
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-gray-bolder;
      font-weight: $fw700;

      svg {
        width: 22px;
        height: 22px;

        path {
          fill: $color-gray;
        }
      }

      a {
        line-height: 1;
        background-color: transparent;

        svg {
          height: 20px;
          width: auto;
          position: relative;
          top: 1px;
        }
      }
    }

    button._confirm {
      align-items: center;

      span {
        position: relative;
        display: block;
        top: 1px;
        margin-left: 5px;
      }
      svg {
        width: 16px;
        path {
          fill: $color-green;
        }
      }
    }
  }
}
