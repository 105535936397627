#view_err404 {
    background-color: $color-white;
    
    @include media-breakpoint-up(xs) {
        padding: 4rem 0;
    }
    
    @include media-breakpoint-up(md) {
        padding: 8rem 0 12rem 0;
    }

    ._med-container {

        .content-error {

            .title {
                font-weight: $fw600;
                color: $color-blue;
                font-family: $font-nunito;
                text-align: center;
                
                @include media-breakpoint-up(xs) {
                    font-size: 46px;
                }
                
                @include media-breakpoint-up(md) {
                    font-size: 76px;
                }
            }

            .content {

                text-align: center;

                .subtitle {
                    font-weight: $fw600;
                    color: $color-blue;
                    text-align: center;
                    margin-top: 5px;
                    
                    @include media-breakpoint-up(xs) {
                        font-size: 14px;
                    }
                    
                    @include media-breakpoint-up(md) {
                        font-size: 18px;
                    }

                    span {
                        display: inline-block;
                        margin-right: 6px;

                        @include media-breakpoint-up(xs) {
                            font-size: 18px;
                        }
                        
                        @include media-breakpoint-up(md) {
                            font-size: 26px;
                        }
                    }
                }

                .text {
                    font-size: 12px;
                    font-weight: $fw700;
                    color: $color-blue;
                    text-align: center;
                    opacity: 0.8;
                    margin-top: 5px;

                    @include media-breakpoint-up(xs) {
                        font-size: 12px;
                    }
                    
                    @include media-breakpoint-up(md) {
                        font-size: 14px;
                    }
                }

                button.back-home {
                    max-width: 184px;
                    margin-top: 15px;
                }

            }
        }
    }
 
}