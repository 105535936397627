._wrap-header {
    
    ._header {

        background-color: $color-white;
        position: relative;
        z-index: map-get($z-index, header);
        
        @include media-breakpoint-up(xs) {
            // -webkit-box-shadow: 0 4px 8px 4px rgba(0,0,0,1);
            // box-shadow: 0 4px 8px 4px rgba(0,0,0,1);
            padding: {
                top: 20px;
                bottom: 20px;
            }
        }
        

        ._med-container {

            .cols {

                display: flex;
                width: $percent-100;

                @include media-breakpoint-up(xs) {
                    flex-direction: column;
                }

                @include media-breakpoint-up(lg) {
                    flex-direction: row;
                }

                .col-left {

                    @include media-breakpoint-up(xs) {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: $percent-100;
                        margin-bottom: 20px;
                    }

                    @include media-breakpoint-up(lg) {
                        width: auto;
                        margin-bottom: 0;
                    }

                    .icon-nav-mobile {
                        max-width: 138px;
                        width: $percent-100;

                        button.icon-with-label {
                        padding: 0;
                            
                            .icon {
                                
                                button._mobile-nav-btn {
        
                                    @include mobile-button-icon;
        
                                    span {
                                        display: block;
                                        height: 2px;
                                        width: 20px;
                                        background-color: $color-blue;
                                        margin-bottom: 3px;
                                        border-radius: 12px;
                                        position: relative;
                                        padding-bottom: 2px;
        
                                        &:nth-child(2) {
                                            left: 0;
                                        }
                                    }
        
                                }
                            }

                            .text-label {
                                margin-top: -4px;
                            }
                        }


                        @include media-breakpoint-up(xs) {
                            display: block;
                        }

                        @include media-breakpoint-up(lg) {
                            display: none;
                        }

                    }

                    .logo {

                        a {
                            display: inline-block;
                            img {

                                @include media-breakpoint-up(xs) {
                                    width: 28px;
                                }

                            }
                        }

                    }

                    ._wrap-points-access {
                        align-items: center;
                        justify-content: flex-end;
                        max-width: 138px;
                        width: $percent-100;
                        
                        @include media-breakpoint-up(xs) {
                            display: flex;
                        }

                        @include media-breakpoint-up(lg) {
                            display: none;
                        }

                        .btn-scamb-action.color-red {
                            max-width: 62px;
                            padding: 0.125rem 0.25rem;
                            
                            a {
                                font-size: 0.5875rem;
                            }
                        }

                        .it-works-menu {

                            button,
                            a {

                                @include mobile-button-icon;

                                svg {
                                    width: 20px;
                                    height: 20px;
                                }

                            }

                            @include media-breakpoint-up(xs) {
                                display: none;
                            }

                            @include media-breakpoint-up(lg) {
                                display: none;
                            }

                        }

                        .scamb-points {
                            margin-right: 0;
                            margin-left: 10px;
                            margin-top: 4px;
                            min-width: 56px;

                            .title {
                                opacity: 0.8;
                            }

                            ._product-price._1x {

                                .ui-item__price {

                                    .price-tag-fraction {
                                        line-height: 1;
                                        font-size: 0.8125rem;
                                    }

                                    .price-tag-symbol {
                                        top: -2px;
                                    }
                                }
                            }
                        }

                        ._my-points {
                            font-weight: $fw700;
                            font-size: 8px;
                            color: $color-gray;
                            padding: 0 0;

                            @include media-breakpoint-up(xs) {
                                display: block //block;
                            }

                            @include media-breakpoint-up(lg) {
                                display: none;
                            }

                            ._product-price {
                                .ui-item__price {
                                    .price-tag-fraction {
                                        font-size: 13px;
                                    }
                                }
                            }
                        }

                        ._login {

                            @include media-breakpoint-up(xs) {
                                display: block;
                            }

                            @include media-breakpoint-up(lg) {
                                display: none;
                            }

                            a.btn {
                                color: $color-black;
                                font-weight: $fw700;
                                font-size: 13px;
                                padding-right: 0;
                            }
                        }
                    }

                }

                .col-right {

                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    @include media-breakpoint-up(lg) {
                        width: $percent-100;
                    }

                    .categories-menu,
                    .shopping-cart-alert,
                    .messages-menu,
                    .user-menu,
                    .it-works-menu,
                    .announce-button {

                        padding: 0 0.386rem;

                        @include media-breakpoint-up(xs) {
                            display: none;
                        }

                        @include media-breakpoint-up(lg) {
                            display: block;
                        }

                    }

                    .categories-menu,
                    .it-works-menu,
                    .announce-button {

                        a, button {
                            font-size: 13px;
                            font-weight: $fw700;
                        }

                    }

                    .shopping-cart-alert {
                        padding-right: 0;
                    }

                    .search-menu {
                        
                        @include media-breakpoint-up(xs) {
                            width: 100%;
                        }
                        
                        @include media-breakpoint-up(lg) {
                            padding-right: 0.806rem;
                            width: auto;
                        }

                        form {
                            padding-bottom: 0;
                        .form-group.search {

                            @include media-breakpoint-up(xs) {
                                max-width: inherit;   
                                min-width: inherit;   
                                width: 100%;
                            }

                            @include media-breakpoint-up(lg) {

                                max-width: 360px;
                                min-width: 360px;

                            }

                            @include media-breakpoint-up(xl) {

                                max-width: 420px;
                                min-width: 420px;

                            }

                        }
                        }

                    }
                    
                    .categories-menu,
                    .user-menu {

                        position: relative;

                    }

                    .user-menu {

                        ._dropdown-menu._user-menu-dropdown {
                            padding: 10px 15px 0 10px;
                            width: 300px;

                            .scamb-menu {

                                ul {

                                    li {
            
                                        &:last-child {
                                            a {
            
                                                .menu-item-columns {
            
                                                    .menu-item-column {
            
                                                        &:first-child {
            
                                                            .icon {
            
                                                                svg {
            
                                                                    path {
            
                                                                        fill: $color-red;
                                                                    }
                                                                }
                                                            }
            
                                                            .menu-description {
            
                                                                .title {
                                                                    color: $color-red;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                    .categories-menu {
                        
                        ._dropdown-menu._positions {
                            top: 40px;
                            left: 0;
                            
                            &::before {
                                left: 50px;
                            }
                            
                            &::after {
                                left: 50px;
                            }
                        }
                        
                    }

                    .messages-menu {
                        button {
                            padding: 0;
                        }
                    }

                    .it-works-menu > a > svg {
                        width: 20px;
                        height: auto;
                    }

                    .it-works-menu {

                        // border-left: 1px solid #f1f1f1;

                        ​a.btn {
                            display: block;
                        svg {
                            width: 20px;
                            height: auto;
                            display: block;
                            @include media-breakpoint-up(xs) {
                                display: block;
                            }

                            @include media-breakpoint-up(lg) {
                                // display: none;
                            }
                        }
                        }
                    }
                        
                    ._my-points {
                        font-weight: $fw700;
                        font-size: 10px;
                        color: $color-gray;
                        // border-left: 1px solid #f1f1f1;
                        padding: 0 0.806rem;

                        @include media-breakpoint-up(xs) {
                            display: none;
                        }

                        @include media-breakpoint-up(lg) {
                            display: block;
                        }

                        ._product-price {
                            .ui-item__price {
                                .price-tag-fraction {
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    ._login {

                        @include media-breakpoint-up(xs) {
                            display: none;
                        }

                        @include media-breakpoint-up(lg) {
                            display: block;
                        }

                        a.btn {
                            color: $color-black;
                            font-weight: $fw700;
                            font-size: 13px;
                        }

                    }

                    .announce-button._buy-points {
                        
                        a {
                            background-color: transparent;
                            border: 1px solid $color-red;
                            color: $color-red;
                        }
                    }

                    .announce-button._new-add {
                        padding-left: 0.806rem;
                    }

                    .announce-button {

                        .btn {
                            padding: 0.719rem 1.264rem;
                        }

                    }

                }

            }

        }

    }
    
}

#categories-menu {

    background-color: $color-white;
    border-top: 1px solid $color-ice;
    border-bottom: 1px solid $color-ice;

    @include media-breakpoint-up(xs) {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        display: block;
    }

    .content {
        position: relative;
        
        #categories-menu-list {
            max-width: 800px;
            width: $percent-100;
            margin-left: auto;
            margin-right: auto;
            padding-left: 15px;
            padding-right: 15px;

            ul.categories-menu-list {

                display: flex;
                align-items: center;
                justify-content: center;

                li {

                    padding: 0 1.5625rem;

                    &:first-child {
                        margin-left: 0;
                    }

                    

                    a {
                        font-size: 0.75rem;
                        font-weight: $fw700;
                        color: $color-blue;
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 3.125rem;

                        .category-name {
                            font-family: $font-nunito !important;
                        }

                        .icon {
                            margin-left: 0.375rem;
                            
                            svg {
                                height: 13px;
                                width: auto;
                                -moz-transform: rotate(0deg);
                                -webkit-transform: rotate(0deg);
                                -o-transform: rotate(0deg);
                                -ms-transform: rotate(0deg);
                                transform: rotate(0deg);
                                -webkit-transition: all 0.25s ease-in-out;
                                -moz-transition: all 0.25s ease-in-out;
                                -ms-transition: all 0.25s ease-in-out;
                                -o-transition: all 0.25s ease-in-out;
                                transition: all 0.25s ease-in-out;

                                path {
                                    fill: $color-blue;
                                }
                            }
                        }

                    }


                    
                    .wrap-main-list {
                        background-color: #FCFCFC;
                        padding: 0.9375rem 0;
                        position: absolute;
                        top: 48px;
                        left: 0;
                        height: auto;
                        width: $percent-100;
                        z-index: 9999;
                        -webkit-box-shadow: 0 8px 10px 4px rgba(100,100,100,0.1);
                        box-shadow: 0 8px 10px 4px rgba(100,100,100,0.1);
                        // display: none;
                        visibility: hidden;
                        -prefix-animation: slide 1s ease 3.5s forwards;
                        
                        
                        
                        .main-list {
                        width: $percent-100;
                        max-width: 780px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        margin: 0 auto;
            
                        .wrapper {
                        .mega-menu-content {
                            max-width: 800px;
                            width: $percent-100;
                            margin-left: auto;
                            margin-right: auto;
                            padding-left: 15px;
                            padding-right: 15px;
                            // display: flex;
                            align-items: flex-start;
                            justify-content: flex-start;
                            
                            .list {
                                padding-left: 15px;
                                padding-right: 15px;
                                min-width: 180px;
                                
                                &:first-child {
                                    margin-left: 0;
                                }
                                
                                &:last-child {
                                    padding-right: 0;
                                }
            
                                .menu-title {
                                    font-size: 0.75rem;
                                    font-weight: $fw700;
                                    color: $color-blue;
                                    font-family: $font-nunito !important;
                                    text-transform: uppercase;
                                    letter-spacing: 0.125rem;
                                    margin-bottom: 0.875rem;
                                }
            
                                ul {
                                    display: block;
                                    li {
                                        padding: 0;
                                        margin-left: 0;
                                        a {
                                            font-size: 0.8125rem;
                                            font-weight: $fw700;
                                            color: $color-black;
                                            opacity: 0.9;
                                            display: inline-block;
                                            margin-bottom: 0.5rem;
                                            text-transform: none;
                                            height: auto;
                                        }
                                        
                                        &:last-child {
                                            font-family: $font-nunito !important;
                                            
                                            a {
                                                font-size: 0.875rem;
                                                opacity: 1;
                                                color: $color-blue;
                                            }
                                        }
                                    }
                                    
                                }
                            }
                        }
                        }
                    }
                    }

                    @keyframes anim-menu {
                        from { opacity: 0; top: 0;}
                        to {opacity: 1; top: 48px;}
                    }

                    &:hover {
                        .wrap-main-list {
                            display: block;
                            visibility: visible;
                            animation-name: anim-menu;
                            animation-duration: 0.30s;
                        }

                        a {
                                .icon {
    
                                    svg {
                                        -moz-transform: rotate(180deg);
                                        -webkit-transform: rotate(180deg);
                                        -o-transform: rotate(180deg);
                                        -ms-transform: rotate(180deg);
                                        transform: rotate(180deg);
                                        -webkit-transition: all 0.25s ease-in-out;
                                        -moz-transition: all 0.25s ease-in-out;
                                        -ms-transition: all 0.25s ease-in-out;
                                        -o-transition: all 0.25s ease-in-out;
                                        transition: all 0.25s ease-in-out;
                                    }
                                }
                        }
                        
                    }

                    &:nth-child(3) {
                        
                        .wrap-main-list {

                            .main-list {
                                max-width: 990px;

                                .wrapper {

                                    .mega-menu-content {

                                        .list {

                                            ul {
                                                .wrapper {
                                                    margin-bottom: 30px;

                                                    .mega-menu-content {
                                                        padding: 0;

                                                        .list {
                                                            padding-left: 0;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        #panel-mega-menu {
            background-color: #FCFCFC;
            padding: 0.9375rem 0;
            position: absolute;
            top: 60px;
            left: 0;
            height: auto;
            width: $percent-100;
            z-index: 99;
            -webkit-box-shadow: 0 8px 10px 4px rgba(100,100,100,0.1);
            box-shadow: 0 8px 10px 4px rgba(100,100,100,0.1);

            .mega-menu-content {
                max-width: 800px;
                width: $percent-100;
                margin-left: auto;
                margin-right: auto;
                padding-left: 15px;
                padding-right: 15px;
                // display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                
                .list {
                    padding-left: 15px;
                    padding-right: 15px;
                    min-width: 180px;
                    
                    &:first-child {
                        margin-left: 0;
                    }
                    
                    &:last-child {
                        padding-right: 0;
                    }

                    .menu-title {
                        font-size: 0.75rem;
                        font-weight: $fw700;
                        color: $color-blue;
                        font-family: $font-nunito !important;
                        text-transform: uppercase;
                        letter-spacing: 0.125rem;
                        margin-bottom: 0.875rem;
                    }

                    ul {
                        li {
                            a {
                                font-size: 0.8125rem;
                                font-weight: $fw700;
                                color: $color-black;
                                opacity: 0.9;
                                display: inline-block;
                                margin-bottom: 6px;
                            }
                            
                            &:last-child {
                                font-family: $font-nunito !important;
                                
                                a {
                                    font-size: 0.875rem;
                                    opacity: 1;
                                    color: $color-blue;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
}

.website-link {

    @media (max-width: 400px) {
        font-size: 0.6em !important;
    }
}

#categories-menu > .content > .categories-menu-list > .categories-menu-list > li > a.text + .wrap-main-list {opacity: 0; visibility: hidden;}