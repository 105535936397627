.wrap-view {
  max-width: 620px;
  width: 100%;
  height: calc(100%);
  margin: 0 auto;
  background-color: $color-white;
  position: fixed;
  top: 0;
  z-index: 999;
  left: 0;

  ._view-head {
    // overflow: hidden;
    padding-bottom: 10px;

    ._view-head-content {
      height: 50px;
      background-color: $color-white;
      -webkit-box-shadow: 0 8px 8px 4px rgba(120, 120, 120, 0.1);
      box-shadow: 0 1px 8px 4px rgba(120, 120, 120, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      ._back-history {
        height: 50px;
        width: 38px;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 8px;
          height: auto;

          path {
            fill: $color-gray;
          }
        }
      }

      ._title-view {
        font-size: 13px;
        font-weight: $fw700;
        color: $color-gray-bolder;
      }
    }
  }

  ._view-content {
    background-color: $color-white;
    @include padding-lt-rt;

    ._content-title {
      font-size: 13px;
      font-weight: $fw700;
      color: $color-gray-bolder;
      line-height: 24px;
      max-width: 320px;
      width: $percent-100;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
}

.wrap-view._screen-conf {

  @include media-breakpoint-up(xs) {
    z-index: map-get($z-index, completeRegistration);
  }
  @include media-breakpoint-up(md) {
    position: relative;
    margin-top: 60px;
    margin-bottom: 60px;
    padding-top: 40px;
    padding-bottom: 200px;
    border: 1px solid rgba(241,241,241, 0.4);
    -webkit-box-shadow: 0 8px 8px 4px rgba(120, 120, 120, 0.1);
      box-shadow: 0 1px 8px 4px rgba(160, 160, 1260, 0.1);
      z-index: auto;
  }

  ._view-head {

    @include media-breakpoint-up(xs){
      padding-bottom: 5px;
    }

    @include media-breakpoint-up(md){
      padding-bottom: 0;
    }

    ._view-head-content {

      @include media-breakpoint-up(xs){
        height: 50px;
      }

      @include media-breakpoint-up(md){
        height: 40px;
      }

      @include media-breakpoint-up(xs){
        -webkit-box-shadow: 0 8px 8px 4px rgba(120, 120, 120, 0.1);
      box-shadow: 0 1px 8px 4px rgba(120, 120, 120, 0.1);
      }

      @include media-breakpoint-up(md){
        -webkit-box-shadow: none;
        box-shadow: none;
      }

      ._back-history {
        @include media-breakpoint-up(xs){
          display: flex;
        }

        @include media-breakpoint-up(md){
          display: none;
        }
      }

      ._title-view {
        @include media-breakpoint-up(xs){
          font-size: 13px;
        }

        @include media-breakpoint-up(md){
          font-size: 24px;
        }
      }

    }

  }

  ._view-content {
    height: calc(96% - 50px);
    position: relative;

    ._content-title {
      @include media-breakpoint-up(xs){
        margin-top: 20px;
      }

      @include media-breakpoint-up(md){
        margin-top: 0;
      }
    }

    ._box-check {
      background-color: $color-box;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 15px;
      margin-bottom: 10px !important;
      max-width: none;
      width: $percent-100;
      border: 0;
      text-align: left;

      @include media-breakpoint-up(xs){
        max-width: 100%;
      }
      
      @include media-breakpoint-up(md){
        max-width: 320px;
        margin: 0 auto;
      }

      ._col-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        ._wrap-icon {
          position: relative;
          width: 36px;

          ._check-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 15px;
            width: 15px;
            border-radius: 100px;
            background-color: $color-ice-bold;
            position: absolute;
            top: 0;
            right: 0;

            svg {
              width: 8px;
              height: auto;

              path {
                fill: $color-gray;
              }
            }
          }

          ._check-icon._checked {
            background-color: $color-green;

            svg {
              path {
                fill: $color-white;
              }
            }
          }

          ._icon {
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-white;
            border-radius: 100px;

            svg {
                path {
                    fill: $color-blue;
                }
            }
          }
        }

        ._text {
          font-size: 12px;
          font-weight: $fw500;
          color: $color-gray;
          max-width: 200px;
          width: $percent-100;
          margin-left: 10px;
        }
      }

      ._col-right {
        svg {
          width: 7px;
          height: auto;

          path {
            fill: $color-gray;
          }
        }
      }
    }

    button.btn-step {
      margin-top: 30px;
      bottom: 15px;
      display: block;
      
      @include media-breakpoint-up(xs){
        position: absolute;
        max-width: 93%;
      }
      
      @include media-breakpoint-up(md){
        position: relative;
        max-width: 320px;
        margin: 0 auto;
        margin-top: 30px;
      }

      svg {
        margin-left: 4px;
        width: 7px;
        height: auto;
        position: relative;
        top: 1px;

        path {
          fill: $color-white;
        }
      }
    }
  }
}