#view_institutional {
  background-color: $color-white;

  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $color-ice;
    background-color: $color-white;

    @include media-breakpoint-up(xs) {
      height: auto;
      padding: 5px 0;
    }

    @include media-breakpoint-up(md) {
      height: 100px;
    }

    ._med-container {
      width: $percent-100;

      ._btn-menu {
        border: 0;
        width: $percent-100;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px;
        border-radius: 6px;
        background-color: $color-white;

        @include media-breakpoint-up(xs) {
          display: flex;
        }

        @include media-breakpoint-up(md) {
          display: none;
        }

        span {
          font-weight: $fw700;
          color: $color-black;
          font-size: 13px;
        }

        svg {
          width: auto;
          height: 20px;
        }
      }

      ._nav {
        width: $percent-100;

        @include media-breakpoint-up(md) {
          display: flex !important;
          align-items: center;
          justify-content: center;
        }

        ul {
          display: flex;
          align-items: center;
          justify-content: center;

          @include media-breakpoint-up(xs) {
            flex-direction: column;
          }

          @include media-breakpoint-up(md) {
            flex-direction: row;
          }

          li {
            @include media-breakpoint-up(md) {
              margin-left: 40px;
            }

            &:first-child {
              margin-left: 0;
            }

            a {
              color: $color-black;
              font-weight: $fw700;
              font-size: 14px;

              @include media-breakpoint-up(xs) {
                display: inline-block;
                padding: 10px 0;
              }
            }
          }
        }
      }
    }
  }

  .point1 {
    ._med-container {
      text-align: center;

      .headline {
        display: block;
        text-align: center;
      }

      ._button {
        a {
          @include media-breakpoint-up(xs) {
            height: 38px;
            font-size: 14px;
          }

          @include media-breakpoint-up(lg) {
            height: 42px;
            font-size: 14px;
          }
        }
      }
    }
  }

  .point2 {
    ._med-container {
      hgroup._block-headline {
        h1,
        h2 {
          color: $color-white;
        }
      }
    }
  }

  .point3 {
    ._med-container {
      .image-gallery-content {
        max-width: 680px;
        width: $percent-100;
        margin: 0 auto;

        .image-gallery-icon.image-gallery-left-nav,
        .image-gallery-icon.image-gallery-right-nav {
          svg {
            width: 40px;
            height: auto;
          }

          &:hover {
            color: $color-red;
          }
        }

        .image-gallery-bullets {
          bottom: -24px !important;
          display: block !important;

          .image-gallery-bullets-container {
            .image-gallery-bullet {
              background-color: $color-nude;
              height: 8px;
              width: 8px;
              border-color: $color-nude;
            }

            .image-gallery-bullet.active {
              width: 20px;
              background-color: $color-violet;
              border-color: $color-violet;
            }
          }
        }
      }
    }
  }

  .point4 {
    ._med-container {
      hgroup._block-headline {
        h1,
        h2 {
          color: $color-white;
        }
      }
    }
  }

  // .point1 {
  //   @include media-breakpoint-up(xs) {
  //     padding: 60px 0;
  //   }

  //   @include media-breakpoint-up(md) {
  //     padding: 60px 0;
  //   }
  // }

  .point5,
  .point6 {
    @include media-breakpoint-up(xs) {
      padding: 30px 0;
    }

    @include media-breakpoint-up(md) {
      padding: 60px 0;
    }
  }

  .point1 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(xs) {
      max-height: 478px;
    }

    @include media-breakpoint-up(md) {
      max-height: none;
    }
  }

  .point2,
  .point3,
  .point4 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .point1,
  .point2,
  .point3,
  .point4,
  .point5,
  .point6 {
    // @include media-breakpoint-up(xs) {
    //   padding: 30px 0;
    // }

    // @include media-breakpoint-up(md) {
    //   padding: 60px 0;
    // }

    ._med-container {
      ._block-headline {
        max-width: 724px;
        margin: 0 auto;

        @include media-breakpoint-up(xs) {
          margin-bottom: 15px;
        }

        @include media-breakpoint-up(md) {
          margin-bottom: 30px;
        }

        .headline {
          display: block;
          text-align: center;
          font-weight: $fw700;
          margin-bottom: 5px;
        }

        h2 {
          text-align: center;
          line-height: 1.7;
          margin-top: 15px;

          @include media-breakpoint-up(xs) {
            font-size: 12px;
            font-weight: $fw700;
          }

          @include media-breakpoint-up(md) {
            font-weight: $fw500;
            font-size: 16px;
          }

          ul {
            li {
              text-align: left;
              margin-bottom: 15px;
            }
          }
        }
      }

      ._button {
        @include media-breakpoint-up(xs) {
          margin-top: 20px;
        }

        @include media-breakpoint-up(md) {
          margin-top: 28px;
        }

        a {
          color: $color-white;
        }
      }
    }
  }

  .point5 {
    ._med-container {
      ._block-headline {
        h2 {
          background-color: $color-ice-bold;
          padding: 30px 15px;
          border-radius: 12px;
          color: $color-blue;
          font-weight: $fw700;

          @include media-breakpoint-up(xs) {
            font-size: 13px;
          }

          @include media-breakpoint-up(lg) {
            font-size: 14px;
          }

          ul {
            li {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              color: $color-blue;

              &::before {
                content: "";
                display: block;
                height: 6px;
                width: 6px;
                min-width: 6px;
                background-color: $color-black;
                position: relative;
                top: 8px;
                margin-right: 10px;
                border-radius: 100px;
              }
            }
          }
        }
      }
    }
  }

  .with-video {
    ._med-container {
      .video {
        border-radius: 7px;
        overflow: hidden;
        margin: 0 auto;

        position: relative;
        overflow: hidden;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }

        @include media-breakpoint-up(xs) {
          width: 290px;
          height: 173px;
        }

        @include media-breakpoint-up(md) {
          width: 440px;
          height: 257px;
        }

        @include media-breakpoint-up(lg) {
          width: 640px;
          height: 360px;
        }
      }
    }
  }
}

#view_how-it-works {
  .tabs-container {
    .no-container {
      .MuiTabs-root._material-tabs {
        border-bottom: 1px solid $color-ice-bold;

        .MuiTabs-scroller.MuiTabs-fixed {
          .MuiTabs-flexContainer {
            justify-content: center;

            .MuiButtonBase-root {
              @include media-breakpoint-up(xs) {
                width: 50%;
              }

              @include media-breakpoint-up(md) {
                width: auto;
              }

              &:last-child {
                .MuiTab-wrapper {
                  color: $color-violet;
                }
              }
            }
          }
        }
      }
    }
  }

  .main-container {
    .no-container {
      .hdline {
        .x21line {
          h1 {
            font-family: $font-oswald !important;
            text-transform: uppercase;

            @include media-breakpoint-up(xs) {
              font-size: 1.625rem;
            }

            @include media-breakpoint-up(md) {
              font-size: 2.25rem;
            }
          }
        }
      }
    }

    .no-container,
    ._med-container {
      .hdline {
        background-color: $color-ice;

        @include media-breakpoint-up(xs) {
          padding-top: 20px;
          padding-bottom: 20px;
        }

        @include media-breakpoint-up(md) {
          padding-top: 30px;
          padding-bottom: 30px;
        }
      }

      ._Box {
        .MuiGrid-root.MuiGrid-container {
          .MuiGrid-root.MuiGrid-item {
            display: flex;
            align-items: center;
          }
        }
      }

      .MuiGrid-root.GridBlockLinks.MuiGrid-container {
        margin-bottom: 15px;

        .MuiGrid-root.MuiGrid-item {
          display: flex;
          align-items: center;
          justify-content: center;

          .block-link {
            display: block;
            max-width: 280px;
            width: $percent-100;
            text-align: center;
            padding: 0.625rem;
            font-size: 0.875rem;
            color: $color-blue;
            font-weight: $fw700;
            // opacity: 0.8;
            background-color: $color-white;
            border-radius: 0.375rem;
          }

          &:first-child {
            border-right: 1px solid $color-ice;
            justify-content: flex-end;
          }

          &:last-child {
            justify-content: flex-start;
          }

          &:hover {
            .block-link {
              opacity: 1;
            }
          }
        }
      }

      .MuiGrid-root.GridBlockLinks.Brands.MuiGrid-container {
        .MuiGrid-root.MuiGrid-item {
          .block-link {
            color: $color-violet;
          }
        }
      }
    }
  }
}

//===============================================================================================================
// * IMPORTANTE! NAO MEXER DAQUI PARA BAIXO
// * Essa parte e responsavel pelos estilos dos blocos com icones
//===============================================================================================================
._wrap-block-grid-columns {
  // max-width: 720px;
  width: $percent-100;
  // margin: 0 auto;
  background-color: $color-white;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid $color-ice;

  .hdline {
    background-color: $color-ice;
    justify-content: flex-start;
    padding: 0.9375rem 0.9375rem !important;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 0 !important;

    div {
      h1 {
        font-size: 18px;
      }
    }
  }

  ._block-grid-columns {
    background-color: $color-white;
    margin-top: 0;
    overflow: hidden;
    border-radius: 6px;

    ._column-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: $percent-100;
      padding-top: 10px;
      padding-bottom: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      .icon {
        width: 30px;
        min-width: 30px;
        min-height: 60px;
        text-align: center;
        background-color: $color-white;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-radius: 100%;
        margin-right: 10px;

        .pts {
          color: $color-blue;
          font-size: 14px;
          font-weight: $fw700;

          span {
            display: block;
            line-height: 1;
            text-transform: uppercase;
          }

          span.smallcase {
            text-transform: lowercase;
          }
        }

        svg {
          height: 20px;
          width: auto;

          path {
            fill: $color-blue;
          }
        }

        .bottom-icon {
          display: block;
          color: $color-blue;
          font-size: 12px;
        }
      }

      .info {
        padding-right: 15px;

        .title,
        .subtitle {
          color: $color-black;
          font-weight: $fw700;
          text-align: left;
          font-family: $font-nunito !important;
        }

        .title {
          font-size: 16px;
        }

        .subtitle {
          font-size: 13px;
          margin-top: 5px;
          font-weight: $fw500;
        }
      }
    }
  }
}

._wrap-block-grid-columns.brands {
  ._block-grid-columns {
    ._column-item {
      .icon {
        .pts {
          color: $color-violet;
        }

        svg {
          path {
            fill: $color-violet;
          }
        }
      }
    }
  }
}
