#view_buy {
  background-color: $color-white;

  @include media-breakpoint-up(md) {
    // padding-top: 20px;
    padding-top: 0;
  }

  ._block.countdown {
    background-color: $color-blue;
    padding: 20px 0 15px 0;

    @include media-breakpoint-up(xs) {
      display: block;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }

    ._med-container {
      display: flex;
      align-items: center;
      justify-content: center;

      ._title {
        color: $color-white;
        font-size: 13px;
        font-weight: $fw700;
        text-align: center;
        margin-bottom: 3px;
      }

      ._timer {
        align-items: center;
        justify-content: center;
        display: flex;

        @include media-breakpoint-up(xs) {
          margin-left: 10px;
        }

        @include media-breakpoint-up(md) {
          margin-left: 0;
        }

        svg {
          width: 18px;
          height: auto;
          position: relative;
          top: -2px;

          path {
            fill: $color-white;
          }
        }

        ._timer-clock {
          font-size: 16px;
          color: $color-white;
          font-weight: $fw400;
          background-color: transparent;
          display: flex;
          justify-content: flex-start;
          margin-left: 5px;
          width: 40px;
        }
      }
    }
  }

  // Globals
  form {
    ._med-container {
      @include media-breakpoint-up(xs) {
        padding: 0;
      }

      @include media-breakpoint-up(md) {
        @include padding-lt-rt;
      }

      ._columns {
        ._column {

          @include media-breakpoint-up(md) {
            min-width: 366px;
            max-width: 366px;
            width: $percent-100 !important;
          }

          ._block {
            border-bottom: 1px solid $color-ice;

            @include media-breakpoint-up(xs) {
              @include padding-lt-rt;
            }

            @include media-breakpoint-up(md) {
              padding: 0;
            }

            ._block-title {
              font-size: 16px;
              font-weight: $fw400;
              color: $color-blue;
              padding: 0.95rem 0;
              font-family: $font-nunito;
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .btn-add-payment {
                font-size: 12px;
                font-weight: $fw700;
                color: $color-white;
                font-family: $font-comfortaa;
                padding: 0.375rem 0.75rem;
                max-width: 122px;

                svg {
                  path {
                    fill: $color-white;
                  }
                }
              }
            }
          }

          .checkout-actions {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
              padding: 15px 0;
            }

            button {
              font-weight: $fw700;

              &:not(:first-child) {
                margin-left: 0;
                margin-top: 8px;
              }
            }

            button.accept {
              background-color: $color-red;
              svg {
                margin-left: 8px;
                path {
                  fill: $color-white;
                }
              }
            }

            button.cancel {
              background-color: #f1f1f1 !important;
              color: #777 !important;

              svg {
                margin-left: 8px;
                path {
                  fill: $color-gray;
                }
              }
            }
          }
        }

        // ._column._visiblity-column {
        //   @include media-breakpoint-up(xs) {
        //     display: none;
        //   }

        //   @include media-breakpoint-up(md) {
        //     display: block !important;
        //   }
        // }
      }
    }
  }

  form.CheckoutForm {
    @include media-breakpoint-up(md) {
      margin-top: 10px;
    }
  }

  ._title-with-price {
    border-bottom: 1px solid $color-ice;
    background-color: $color-white;

    ._med-container {
      ._cols {
        display: flex;
        align-items: center;
        justify-content: space-between;

        ._col._with-values {
          align-items: center;
          justify-content: space-between;
          width: $percent-100;

          ._column-item {
            width: $percent-100;
            display: flex;
            justify-content: flex-end;

            ._price.real {
              border-left: 1px solid $color-ice;
              padding-left: 10px;
            }
          }
        }

        ._col {
          ._title-box {
            padding-left: 0;
            margin-bottom: 0;

            @include media-breakpoint-up(xs) {
              font-size: 13px;
            }

            @include media-breakpoint-up(md) {
              font-size: 14px;
            }
          }

          ._column-item {
            // Price in points
            ._product-price.points {
              margin-left: 10px;

              margin-top: 0;

              @include media-breakpoint-up(xs) {
                display: flex;
              }

              @include media-breakpoint-up(md) {
                display: none;
              }

              .ui-item__price {
                margin-top: 2px;
                // border-left: 1px solid $color-ice;
                padding: 5px 0;
                // padding-left: 10px;

                .price-tag-fraction {
                  font-size: 16px;
                  color: $color-blue;
                }

                .price-tag-symbol {
                  font-size: 8px;
                  top: 0.185rem;
                }
              }
            }

            // Price in real
            ._price.real {
              margin-left: 10px;

              @include media-breakpoint-up(xs) {
                display: flex;
              }

              @include media-breakpoint-up(md) {
                display: none;
              }

              .ui-item__price {
                margin-top: 2px;

                .price-tag-fraction {
                  font-size: 16px;
                  color: $color-blue;
                }

                .price-tag-symbol {
                  font-size: 8px;
                  top: 0.265rem;
                }
              }
            }
          }

          button.btn {
            padding-right: 0;
            padding-left: 10px;
            // border-left: 1px solid $color-ice;

            @include media-breakpoint-up(xs) {
              display: flex;
            }

            @include media-breakpoint-up(md) {
              display: none;
            }

            svg {
              height: 18px;
              width: auto;

              path {
                fill: $color-black;
              }
            }
          }

          ._timer {
            align-items: center;
            justify-content: flex-start;

            @include media-breakpoint-up(xs) {
              display: none;
            }

            @include media-breakpoint-up(md) {
              display: flex;
            }

            svg {
              width: 18px;
              height: 18px;
              position: relative;
              top: -1px;

              path {
                fill: $color-blue;
              }
            }

            ._timer-clock {
              font-size: 16px;
              color: $color-blue;
              font-weight: $fw700;
              background-color: $color-white;
              width: 46px;
              display: flex;
              justify-content: flex-end;
            }
          }

          &:first-child {
            display: flex;
          }
        }
      }
    }
  }

  // Cols - Col
  ._columns {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @include media-breakpoint-up(xs) {
      flex-direction: column;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    ._column {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        ._block {
          @include media-breakpoint-up(xs) {
            padding-bottom: 20px;
          }

          &:last-child {
            @include media-breakpoint-up(md) {
              border-bottom: 0;
            }
          }
        }
      }

      &:nth-child(1) {
        @include media-breakpoint-up(xs) {
          width: $percent-100;
        }

        @include media-breakpoint-up(lg) {
          width: 40%;
          border-right: 1px solid $color-ice;
          padding-bottom: 200px;
          padding-right: 15px;
        }

        ._block {
          ._advertiser-card {
            padding-top: 0;

            @include media-breakpoint-up(xs) {
              padding-bottom: 0;
            }

            @include media-breakpoint-up(md) {
              padding-bottom: 20px;
            }
          }

          ._product-thumbnail.small {
            padding-right: 15px;
          }
        }

        .btn.change-address {
          margin-top: 15px;
        }
      }

      &:nth-child(2) {
        @include media-breakpoint-up(xs) {
          width: $percent-100;
        }

        @include media-breakpoint-up(md) {
          // border: {
          //   left: 1px solid $color-ice;
          //   right: 1px solid $color-ice;
          // }
          padding: 0 15px;
        }

        @include media-breakpoint-up(lg) {
          width: 50%;
        }

        ._block {
          &:nth-child(1) {
            @include media-breakpoint-up(md) {
              padding-bottom: 20px;
            }
          }

          .select-box._expand {
            margin-bottom: 0.625rem;
            cursor: pointer;
            border: 0.0625rem solid $color-ice;
            padding-top: 0.625rem;
            padding-bottom: 0.625rem;
            padding-right: 0.9375rem;
            border-radius: 0.375rem;

            ._cols {
              label {
                border: 0;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &:nth-child(3) {
        border-left: 1px solid $color-ice;

        @include media-breakpoint-up(xs) {
          width: $percent-100;
        }

        @include media-breakpoint-up(md) {
          padding-left: 15px;
        }

        @include media-breakpoint-up(lg) {
          width: 40%;
        }

        ._block {

          ._block-cupom {

            background-color: $color-white;
            margin-top: 15px;
            padding: 0 10px 15px 10px;
            border: 1px dashed #eaeaea;

            ._block-title {
              justify-content: flex-start;
              font-family: $font-nunito !important;
              font-weight: $fw400;
              color: $color-blue;
              font-size: 1rem;

              svg {
                margin-right: 6px;
                height: 14px;
                width: auto;
                
                path {
                  fill: $color-black;
                }
              }
            }

            .wrapper-field {
              display: flex;

              button {
                max-width: 80px !important;
                margin-left: 10px;
                font-size: 0.75rem !important;
              }
            }
          }

          &:nth-child(1) {
            @include media-breakpoint-up(md) {
              border-bottom: 0;
            }
          }

          &:nth-child(2) {
            border-bottom: 0;
          }

          ._list-content {
            li {
              padding: 8px 0;
              padding-left: 10px;
              position: relative;

              ._item-title {
                margin-bottom: 0;
              }
            }
          }

          ._list-content._values-list {
            li {
              ._item-content {
                ._product-price._1x.points {
                  .ui-item__price {
                    .price-tag-fraction {
                      font-size: 16px;
                    }
                  }
                }
              }

              &:last-child {
                background-color: $color-ice;
              }
              
              &:nth-child(2) {
                background-color: $color-ice;
              }
            }
          }

          .select-box {
            margin-bottom: 10px;

            label {
              cursor: pointer;

              ._cols {
                max-height: 60px;
              }
            }
          }

          .select-box.active._new-cc {
            ._cols {
              label {
                &:focus,
                &:active {
                  background-color: #f6f6f9 !important;
                }

                .icon {
                  position: relative;

                  .plus-add {
                    position: absolute;
                    background-color: $color-white;
                    height: 14px;
                    width: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100px;
                    top: 6px;
                    right: 12px;

                    svg {
                      width: 8px;
                      height: auto;
                    }
                  }
                }

                .text {
                  font-size: 0.8125rem;
                  font-weight: $fw700;
                  color: $color-black;
                }

                .check {
                  opacity: 0;
                  visibility: hidden;
                }

                .overlay {
                  opacity: 0;
                }
              }
            }
          }
        }

        .checkout-actions {
          @include media-breakpoint-up(xs) {
            @include padding-lt-rt;
            padding-bottom: 20px;
          }

          @include media-breakpoint-up(md) {
            padding: 0;
          }
          button.btn {
            max-width: 450px;
          }
        }
      }
    }
  }
}

//=======================================
// #1 * Adjust Product Thumbnail
//=======================================

._product-thumbnail.small {
  padding: 10px 0;
  border-bottom: 1px dashed #f9f9f9;

  &:last-child {
    border-bottom: 0;
  }

  ._col {
    ._image {
      width: 40px;
      min-width: 40px;
      height: 40px;
    }

    ._product-info {
      .title {
        margin-bottom: 0;
        font-size: 12px;
      }

      ._product-price {
        margin-top: 0;

        .ui-item__discount-price {
          margin-right: 5px;
        }

        .ui-item__price {
          .price-tag-fraction {
            font-size: 16px;
          }
        }
      }
    }

    &:last-child {
      .trash-item {
        svg {
          width: auto;
          height: 16px;
        }
      }

      button {
        &:last-child {
          cursor: default;
        }
      }
    }
  }
}
