#view_promotions {

    margin-bottom: 30px;

    ._wrap-current {
        background-color: $color-white;
        padding-top: 0.245rem;
        margin-bottom: 30px;
    }

    ._content {

        .promotion-box {
            background-color: $color-white;
            border-radius: 6px;
            margin-bottom: 15px;
            -webkit-box-shadow: 4px 4px 8px 4px rgba(200,200,200,0.2);
            box-shadow: 4px 4px 8px 4px rgba(200,200,200,0.2);
            
            &:last-child {
                margin-bottom: 0;
            }
            
            .promotion {
                padding: 0.9375rem;
                cursor: pointer;

                ._cols {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;

                    @include media-breakpoint-up(xs) {
                        flex-direction: column;
                    }

                    @include media-breakpoint-up(md) {
                        flex-direction: row;
                    }

                    ._col {


                        &:first-child {

                            .banner {
                                border-radius: 4px;
                                overflow: hidden;
                                    
                                @include media-breakpoint-up(md) {
                                    min-width: 435px;
                                }
                                
                                img {
                                    border-radius: 4px;
                                    max-width: 435px;
                                    width: $percent-100;
                                    height: $percent-100;
                                }
                            }
                        }

                        &:last-child {

                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: $percent-100;
                            
                            @include media-breakpoint-up(xs)  {
                                margin-top: 15px;
                            }
                            
                            @include media-breakpoint-up(md) {
                                margin-top: 5px;
                                margin-left: 20px;
                            }

                            .info {

                                @include media-breakpoint-up(xs) {
                                    padding-right: 15px;
                                }

                                @include media-breakpoint-up(md) {
                                    padding-right: 0px;
                                }

                                hgroup {
                                    h1,
                                    h2 {
                                        color: $color-black;
                                        font-weight: $fw700;
                                    }

                                    h1 {
                                        margin-bottom: 10px;
                                        
                                        @include media-breakpoint-up(xs) {
                                            font-size: 15px;
                                        }
                                        
                                        @include media-breakpoint-up(md) {
                                            font-size: 18px;
                                        }
                                    }

                                    h2 {

                                        @include media-breakpoint-up(xs) {
                                            font-size: 12px;
                                        }
                                        
                                        @include media-breakpoint-up(md) {
                                            font-size: 14px;
                                        }
                                    }
                                }

                                .dates {
                                    margin-top: 20px;

                                    h1 {
                                        color: $color-black;
                                        font-weight: $fw700;
                                        font-size: 12px;
                                    }

                                    .wrap {
                                        display: flex;
                                        align-items: flex-start;
                                        justify-content: flex-start;
                                        margin-top: 6px;

                                        .date-box {

                                            background-color: #f9f9f9;
                                            border-radius: 4px;
                                            font-family: 'Nunito', sans-serif;
                                            padding: 0.625rem;

                                            &:last-child {
                                                margin-left: 10px;
                                            }

                                            ._collum {

                                                &:first-child {
                                                    font-size: 11px;
                                                    text-transform: capitalize;
                                                    opacity: 0.8;
                                                }

                                                &:last-child {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: flex-start;
                                                    margin-top: 4px;
                                                
                                                    .icon {

                                                        svg {
                                                            width: 14px;
                                                            height: auto;
                                                            position: relative;
                                                            top: -1px;

                                                            path {

                                                                fill: $color-black;
                                                            }
                                                        }
                                                    }

                                                    .field {
                                                        font-size: 13px;
                                                        color: $color-black;
                                                        font-weight: $fw600;
                                                        margin-left: 6px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .icon {

                                button {
                                    background-color: transparent;
                                    max-width: 48px;
                                    padding-right: 0;

                                    &:active,
                                    &:focus {
                                        background-color: transparent !important;
                                    }

                                    svg {
                                        width: 16px;
                                        height: auto;

                                        path {
                                            fill: $color-black;
                                            opacity: 0.5;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }

            .promotion-content {
                border-top: 1px solid #F9F9F9;
                padding: 0.9375rem;

                .title {
                    font-size: 16px;
                    font-weight: $fw700;
                    color: $color-black;
                }

                .text {

                    margin-top: 10px;

                    p {
                        font-size: 13px;
                        font-weight: $fw700;
                        color: $color-black;
                        opacity: 0.9;
                        margin-bottom: 20px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

}