#view_add-payment {

#wrap-add-payment {

    padding-bottom: 30px;

    ._wrap-form-panel {

      ._form {
        ._payment-methods-list {
          margin-bottom: 20px;
        }

        .row {
          .form-group {
            margin-bottom: 1rem;
          }
        }

        ._row._fluid {
          @include media-breakpoint-up(xs) {
            flex-direction: column;
          }
  
          @include media-breakpoint-up(lg) {
            flex-direction: row;
          }
          .input-group {
            width: 100%;
            &:last-child {
              @include media-breakpoint-up(xs) {
                margin-left: 0;
              }
  
              @include media-breakpoint-up(lg) {
                margin-left: 10px;
              }
            }
          }
        }
  
        .form-group-button {
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
  
          @include media-breakpoint-up(xs) {
            flex-direction: column-reverse;
          }
  
          @include media-breakpoint-up(md) {
            flex-direction: row;
          }
  
          button.btn,
          a.btn {
            @include media-breakpoint-up(xs) {
              max-width: none;
            }
  
            @include media-breakpoint-up(md) {
              max-width: 200px;
            }
          }
        }
      }

    }

  .form-step {
    background-color: $color-white;
    ._wrap-form-header {
      ._title-box  {
        margin-bottom: 0;
      }
    }

    ._form {
      padding: 15px;
      border: 1px solid $color-ice;

      .row {
        .form-group {
          margin-bottom: 1rem;
        }
      }

      ._payment-methods-list {
        margin-bottom: 15px;
      }

      ._row._fluid {
        @include media-breakpoint-up(xs) {
          flex-direction: column;
        }

        @include media-breakpoint-up(lg) {
          flex-direction: row;
        }
        .input-group {
          width: 100%;
          &:last-child {
            @include media-breakpoint-up(xs) {
              margin-left: 0;
            }

            @include media-breakpoint-up(lg) {
              margin-left: 10px;
            }
          }
        }
      }

      .form-group-button {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        @include media-breakpoint-up(xs) {
          flex-direction: column-reverse;
        }

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }

        button.btn,
        a.btn {
          @include media-breakpoint-up(xs) {
            max-width: none;
          }

          @include media-breakpoint-up(md) {
            max-width: 200px;
          }
        }
      }
    }
  }
}
}