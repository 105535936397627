#view_common-page {
    
    margin-bottom: 30px;
    
    ._med-container {
        
        ._terms-conditions-content {

            background-color: $color-white;
            width: $percent-100;
            border-color: $color-ice;
            border-width: 1px;
            border-style: solid;
            padding: 30px 15px;

            strong {
                color: $color-black;
                font-family: $font-nunito;
            }
            
            .headline {
                font-size: 16px;
                font-weight: $fw700;
                color: $color-black;
                font-family: $font-nunito;
            }
            
            ._tag-date {
                font-size: 13px;
                color: $color-gray;
                margin-top: 15px;
            }
            
            ._wrap-topic._first {
                margin: 20px 0 30px 0;
            }
            
            ._wrap-topic {
                
                margin-bottom: 30px;
                
                &:last-child {
                    margin-bottom: 0;
                }
                
                ._topic-title {
                    font-size: 16px;
                    font-weight: $fw700;
                    color: $color-black;
                    margin-bottom: 10px;
                    font-family: $font-nunito;
                }
                
                ._topic-content {
                    font-size: 13px;
                    font-weight: $fw700;
                    color: $color-gray-bolder;
                    line-height: 1.8;
                    margin-bottom: 15px;
                }

                ul,ol {
                    margin: 20px 0;
                    li {
                        font-size: 13px;
                        font-weight: $fw700;
                        color: $color-gray-bolder;
                        line-height: 1.8;
                        margin-bottom: 15px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                ul {
                    padding-left: 25px;
                }

                .table {
                    thead,
                    tbody {
                        tr {
                            th, td { font-size: 13px; }
                        }
                    }
                }

                .table.mbottom {
                    margin-bottom: .100rem;
                }
                
            }

        }
    }
    
}