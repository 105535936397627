//=======================================
// UI * ModalBase
//=======================================
.modal-dialog {
    .modal-content {
        .modal-header {
            border-bottom: 0;
        }
        .modal-body {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            ._title {
                font-size: 16px;
                color: $color-black;
                font-weight: $fw700;
                text-align: center;
                max-width: 420px;
                width: $percent-100;
                margin: 0 auto;
                margin-bottom: 30px;
                ._subtitle {
                    display: block;
                    font-size: 13px;
                    color: $color-gray-bolder;
                    font-weight: $fw700;
                    margin-top: 5px;
                }
            }
        }
    }
}

//=======================================
// UI * ModalProposal
//=======================================
._form._form-proposal {
    max-width: 420px;
    width: $percent-100;
    margin: 0 auto;
    padding-bottom: 15px;
    @include padding-lt-rt;

    ._product {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        border: 1px solid $color-ice;
        margin-bottom: 15px;
        padding: {
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 10px;
        }

        ._product-image {
            height: 40px;
            width: 40px;
            border-radius: 4px;
            border: 1px solid $color-ice;
            overflow: hidden;

            img {
                width: auto;
                height: $percent-100;
            }
        }

        ._product-info {
            margin-left: 10px;
            ._store-title {
                font-size: 11px;
                font-weight: $fw700;
                margin-bottom: 2px;
            }

            ._product-title {
                font-size: 13px;
                font-weight: $fw700;
            }

            ._product-price {
                margin-top: 0;
            }
        }
    }

    .form-group {
        border: 1px solid $color-border;
        padding: 5px 10px 10px 10px;
        padding-bottom: 0;
        border-radius: 4px;

        label {
            font-size: 13px;
            color: $color-gray;
            font-weight: $fw700;
            font-family: $font-comfortaa;
        }

        .form-control {
            padding-top: 0;
            padding-bottom: 5px;
            padding-left: 0;
            border: 0;
            height: calc(0.962em + 0.75rem + 0px);
            font-size: 16px;
            color: $color-black;
            font-family: $font-comfortaa;

            &:focus,
            &:active {
                border: 0 !important;
            }

            &::placeholder {
                font-size: 11px;
                opacity: 0.7;
            }
        }

        span._error {
            color: $color-red;
            font-size: 12px;
        }

        textarea.form-control {
            max-height: 100px;
            height: 100vw;
            resize: none;
            margin-top: 4px;
            font-size: 13px;
        }
    }

    ._form-footer {
        text-align: center;
        button {
            max-width: none;
            width: $percent-100;
        }
    }
}

//=======================================
// UI * ModalTaxs
//=======================================
._title.modal-taxs {
    margin-bottom: 0 !important;
}

._taxs-list {
    li {
        border-bottom: 1px solid $color-ice;
        padding: 10px 15px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        &:nth-child(1) {
            ._taxs-list-title {
                color: $color-black;
                font-weight: $fw700;

                @include media-breakpoint-up(xs) {
                    font-size: 12px;
                }

                @include media-breakpoint-up(md) {
                    font-size: 14px;
                }
            }

            ._taxs-list-value {
                ._title {
                    font-size: 12px !important;
                    color: $color-black;
                    font-weight: $fw700;
                }
            }
        }

        &:last-child {
            border-bottom: 0;
            padding-bottom: 15px;
        }

        ._taxs-list-title {
            font-weight: $fw700;
            color: $color-gray-bolder;
            margin-bottom: 2px;

            @include media-breakpoint-up(xs) {
                font-size: 12px;
            }

            @include media-breakpoint-up(md) {
                font-size: 13px;
            }
        }

        ._taxs-list-value {
            ._title {
                color: $color-gray;
                text-transform: capitalize;
                font-size: 13px;
                font-weight: $fw600;
            }

            ._value {
                color: $color-gray-bolder;
                font-weight: $fw700;

                @include media-breakpoint-up(xs) {
                    font-size: 12px;
                }

                @include media-breakpoint-up(md) {
                    font-size: 14px;
                }
            }

            ._value._price {
                font-size: 18px;
            }
        }
    }

    li._wbg {
        background-color: $color-ice-light;
        border-bottom: 2px solid $color-ice;
    }
}

//=======================================
// UI * Flull Width
//=======================================

.full-width {
    @include media-breakpoint-up(xs) {
        padding-right: 0;
    }

    @include media-breakpoint-up(md) {
        padding-right: 15px;
    }

    div.modal-dialog {
        @include media-breakpoint-up(xs) {
            margin: 0 !important;
            height: $percent-100 !important;
        }

        @include media-breakpoint-up(md) {
            margin: 1.75rem auto !important;
            height: auto !important;
        }

        div.modal-content {
            @include media-breakpoint-up(xs) {
                border: 0 !important;
                border-radius: 0 !important;
                height: $percent-100 !important;
            }

            @include media-breakpoint-up(md) {
                border: 1px solid rgba(0, 0, 0, 0.2) !important;
                border-radius: 0.3rem !important;
            }

            div.modal-header {
                @include media-breakpoint-up(xs) {
                    padding: 0 !important;
                }

                @include media-breakpoint-up(md) {
                    padding: 1rem 1rem !important;
                    padding-bottom: 0 !important;
                }

                .close {
                    @include media-breakpoint-up(xs) {
                        display: none !important;
                    }

                    @include media-breakpoint-up(md) {
                        display: block !important;
                    }
                }

                ._view-head {
                    overflow: hidden;
                    width: $percent-100;

                    @include media-breakpoint-up(xs) {
                        padding-bottom: 10px;
                    }
                    @include media-breakpoint-up(md) {
                        padding-bottom: 0;
                    }

                    ._view-head-content {
                        background-color: $color-white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;

                        @include media-breakpoint-up(xs) {
                            height: 50px;
                            -webkit-box-shadow: 0 8px 8px 4px rgba(120, 120, 120, 0.1);
                            box-shadow: 0 1px 8px 4px rgba(120, 120, 120, 0.1);
                        }
                        @include media-breakpoint-up(md) {
                            height: auto;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                        }

                        ._back-history {
                            height: 50px;
                            width: 38px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            background-color: $color-white;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            @include media-breakpoint-up(xs) {
                                height: 50px;
                            }
                            @include media-breakpoint-up(md) {
                                height: 40px;
                            }

                            svg {
                                width: 8px;
                                height: auto;

                                path {
                                    fill: $color-gray;
                                }
                            }
                        }

                        ._back-history {
                            @include media-breakpoint-up(xs) {
                                display: flex;
                            }
                            @include media-breakpoint-up(md) {
                                display: none;
                            }
                        }

                        .modal-title {
                            font-weight: $fw700;
                            color: $color-gray-bolder;

                            @include media-breakpoint-up(xs) {
                                font-size: 13px;
                            }
                            @include media-breakpoint-up(md) {
                                font-size: 18px;
                                margin-top: 40px;
                                margin-right: -40px;
                            }
                        }
                    }
                }
            }

            div.modal-body {
                padding-left: 15px;
                padding-right: 15px;
                background-color: $color-white;

                @include media-breakpoint-up(xs) {
                    padding-top: 1rem;
                }
                @include media-breakpoint-up(md) {
                    padding-top: 0;
                }

                form {
                    ._content-title {
                        font-size: 13px;
                        color: $color-gray-bolder;
                        line-height: 24px;
                        max-width: 320px;
                        width: $percent-100;
                        margin: 0 auto;
                        margin-bottom: 20px;
                        text-align: center;
                        margin-top: 5px;

                        @include media-breakpoint-up(xs) {
                            font-weight: $fw700;
                        }

                        @include media-breakpoint-up(md) {
                            font-weight: $fw400;
                        }
                    }

                    height: auto; //$percent-100;

                    section._form-body {
                        height: calc(100% - 125px);
                    }

                    section._form-body._credit-card-form {
                        height: calc(100% - 160px);
                    }

                    .btn.btn-submit-modal {
                        margin-top: 20px;

                        @include media-breakpoint-up(xs) {
                            padding: 0.719rem 0.75rem;
                        }

                        @include media-breakpoint-up(md) {
                            padding: 0.469rem 0.75rem;
                        }
                    }
                }
            }
        }
    }
}

._form._change-addrress {
    max-width: 320px;
    margin: 0 auto;
    padding: 15px;
}

//=======================================
// UI * Material ui Modal Diloag
//=======================================

.MuiDialogTitle-root.dialog-title._rm-margin {
    h2.MuiTypography-root.MuiTypography-h6 {
        margin-bottom: 0 !important;
    }
}

.MuiDialogTitle-root.dialog-title {
    padding: 0 !important;

    h2.MuiTypography-root.MuiTypography-h6 {
        @include media-breakpoint-up(xs) {
            margin-bottom: 15px;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 0px;
        }

        ._view-head-content {
            width: $percent-100;
            display: flex;
            align-items: center;
            justify-content: center;
            @include padding-lt-rt;
            position: relative;
            height: 50px;

            @include media-breakpoint-up(xs) {
                box-shadow: 0 1px 8px 4px rgba(120, 120, 120, 0.1);
            }

            @include media-breakpoint-up(md) {
                box-shadow: none;
            }

            .btn {
                max-width: none;
                width: auto;
                position: absolute;
                top: 0;
                bottom: 0;

                @include media-breakpoint-up(xs) {
                    display: block;
                    left: 5px;
                }

                @include media-breakpoint-up(md) {
                    left: auto;
                    right: 15px;
                }

                svg {
                    height: auto;

                    @include media-breakpoint-up(xs) {
                        width: 8px;
                    }

                    @include media-breakpoint-up(md) {
                        width: 10px;
                    }

                    path {
                        fill: #909093;
                    }
                }
            }

            ._title-view {
                font-family: $font-comfortaa;
                font-weight: $fw700;
                color: $color-gray-bolder;

                @include media-breakpoint-up(xs) {
                    font-size: 13.3px;
                }

                @include media-breakpoint-up(md) {
                    font-size: 18px;
                    margin-top: 30px;
                }
            }
        }
    }
}

//=======================================
// UI * Material ui Modal Pass Recovery
//=======================================

.MuiDialogContent-root._recovery-password {
    .MuiTypography-root {
        p._sub-title {
            font-family: $font-comfortaa;
            font-weight: $fw700;
            color: $color-gray-bolder;
            text-align: center;
            margin: 5px 0 15px 0;
        }
    }
}

//=======================================
// UI * Modal ( Product Proposal)
//=======================================
._product {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: {
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 5px;
    }

    ._product-image {
        height: 40px;
        width: 40px;
        border-radius: 4px;
        border: 1px solid $color-ice;
        overflow: hidden;

        img {
            width: auto;
            height: $percent-100;
        }
    }

    ._product-info {
        margin-left: 10px;
        ._store-title,
        ._product-title,
        ._product-price {
            font-family: $font-comfortaa;
            color: $color-gray-bolder;
            font-weight: $fw700;
        }

        ._store-title {
            font-size: 11px;
            margin-bottom: 2px;
        }

        ._product-title {
            font-size: 13px;
        }

        ._product-price {
            margin-top: 0;
        }
    }
}

.manifest {
    .image-gallery {
        .image-gallery-content {
            .image-gallery-slide-wrapper {
                .image-gallery-icon.image-gallery-left-nav,
                .image-gallery-icon.image-gallery-right-nav {
                    svg {
                        width: 44px;
                        height: auto;
                    }

                    &:hover {
                        color: $color-red;
                    }
                }

                .image-gallery-bullets {
                    display: flex !important;
                    bottom: 10px;

                    .image-gallery-bullets-container {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

._title.manifest {
    margin-bottom: 0 !important;

    @include media-breakpoint-up(xs) {
        font-size: 18px !important;
    }

    @include media-breakpoint-up(md) {
        font-size: 28px !important;
    }
}

.betta-modal {
    margin: 0 !important;

    ._subtitle {
        display: none !important;
    }
}

//=======================================
// UI * Custom Width Bootstrap Modal
//=======================================

.modal-size-45w {
    max-width: none !important;

    @include media-breakpoint-up(xs) {
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        width: 60%;
    }
}

.modal-size-32w {
    max-width: none !important;

    @include media-breakpoint-up(xs) {
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        width: 86%;
    }

    @include media-breakpoint-up(lg) {
        width: 60%;
    }

    @include media-breakpoint-up(xl) {
        width: 40%;
    }
}

//=======================================
// UI * Modal Filter
//=======================================

._modal-filter.modal-body {
    margin: 0;
    padding: 0 !important;
    // max-height: 100vw;

    @include media-breakpoint-up(xs) {
        overflow: hidden;
        overflow-y: scroll;
    }

    @include media-breakpoint-up(lg) {
        overflow-y: hidden;
    }

    form {
        max-width: none !important;
        padding: 0 !important;
        max-height: 442px;
        // overflow: hidden;
        // overflow-y: scroll;

        ._filter-item {
            label._filter-label {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #f1f1f1;
                padding: 0.469rem 0.9375rem;
                width: $percent-100;
                cursor: pointer;

                input[type="radio"],
                input[type="checkbox"] {
                    display: none;
                    visibility: hidden;
                }

                ._filter-title {
                    color: $color-gray-bolder;
                    font-weight: $fw700;
                    font-size: 14px;
                }

                svg {
                    width: 7px;
                    height: auto;

                    path {
                        fill: $color-gray;
                        opacity: 0.8;
                    }
                }
            }

            &:last-child {
                label._filter-label {
                    border-bottom: 0;
                }
            }
        }
    }
}

//=======================================
// UI * My Purchases & My Sales
//    * Product Actions Material UI
//    * Modal
//=======================================

._modal-action-product {
    .subtitle {
        font-size: 13px;
        font-weight: $fw700;
        color: $color-gray-bolder;
        font-family: $font-comfortaa;
        text-align: center;

        @include media-breakpoint-up(xs) {
            margin-top: 10px;
            margin-bottom: 20px;
        }

        @include media-breakpoint-up(md) {
            margin-top: 5px;
            margin-bottom: 25px;
        }
    }

    .MuiTypography-root {
        ._form-modal-product {
            ._check-box {
                margin-bottom: 10px !important;
            }

            .btn-submit {
                font-family: $font-comfortaa;
                font-weight: $fw700;
                color: $color-white;
                margin: auto;
                display: block;
                margin-top: 20px;

                svg {
                    position: relative;
                    // top: 2px;
                    margin-left: 10px;

                    path {
                        fill: $color-white;
                    }
                }

                &:disabled {
                    color: #777777;

                    svg {
                        path {
                            fill: #777777 !important;
                        }
                    }
                }
            }
        }

        .btn-submit {
            font-family: $font-comfortaa;
            font-weight: $fw700;
            color: $color-white;
            margin: auto;
            display: block;
            margin-top: 20px;

            svg {
                position: relative;
                // top: 2px;
                margin-left: 10px;

                path {
                    fill: $color-white;
                }
            }

            &:disabled {
                color: #777777;

                svg {
                    path {
                        fill: #777777 !important;
                    }
                }
            }
        }
    }
}

._modal-action-product.product-return {
    .MuiTypography-root.MuiTypography-body2 {
        ._body-modal {
            ._block {
                max-width: 380px;
                width: 100%;
                margin: auto;
                padding: 0 0.75rem;

                ._block-title {
                    font-size: 16px;
                    font-weight: $fw400;
                    color: $color-blue;
                    padding: 0.95rem 0;
                    font-family: $font-nunito;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .btn-add-payment {
                        font-size: 12px;
                        font-weight: $fw700;
                        color: $color-white;
                        font-family: $font-comfortaa;
                        padding: 0.375rem 0.75rem;
                        max-width: 122px;

                        svg {
                            path {
                                fill: $color-white;
                            }
                        }
                    }
                }

                ._list-content {
                    li {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }

                .select-box {
                    margin-bottom: 0.625rem;
                }

                .select-box.active._new-cc {
                    ._cols {
                        label {
                            &:focus,
                            &:active {
                                background-color: #f6f6f9 !important;
                            }

                            .icon {
                                position: relative;

                                .plus-add {
                                    position: absolute;
                                    background-color: $color-white;
                                    height: 14px;
                                    width: 14px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 100px;
                                    top: 6px;
                                    right: 12px;

                                    svg {
                                        width: 8px;
                                        height: auto;
                                    }
                                }
                            }

                            .text {
                                font-size: 0.8125rem;
                                font-weight: $fw700;
                                color: $color-black;
                                font-family: $font-comfortaa;
                            }

                            .check {
                                opacity: 0;
                                visibility: hidden;
                            }

                            .overlay {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

._modal-action-product._tracking-return-product {
    @include media-breakpoint-up(xs) {
        padding: 0rem;
    }

    @include media-breakpoint-up(md) {
        padding: 0.5rem 0rem;
    }

    .MuiTypography-root {
        // .subtitle {
        //     display: none;
        // }

        ._list-content {
            li {
                ._item-content {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-start;

                    ._frete {
                        font-size: 12px;
                        position: relative;
                        top: -2px;
                        margin-left: 6px;

                        svg {
                            width: 18px;
                            height: auto;
                            position: relative;
                            top: -1px;
                            margin-right: 4px;
                        }
                    }
                }
            }
        }
    }
}

//=======================================
// UI * Add Shopping Cart Modal
//=======================================
.shopping-cart-dialog {
    padding: 0 !important;

    @include media-breakpoint-up(xs) {
        margin-top: -15px;
    }

    @include media-breakpoint-up(md) {
        margin-top: 20px;
    }

    .MuiTypography-root {
        ._advertiser-card {
            padding-left: 15px;
            padding-right: 15px;
            border-bottom: 1px solid #f1f1f1;

            @include media-breakpoint-up(md) {
                border-top: 1px solid #f1f1f1;
            }
        }

        ._product-thumbnail {
            padding: 15px;
            border-bottom: 1px solid #f1f1f1;
        }
    }
}

//=======================================
// UI * Material UI Dialog Footer
//=======================================

.MuiDialogActions-root.dialog-actions.MuiDialogActions-spacing {
    flex-direction: column;

    @include media-breakpoint-up(md) {
        padding: 15px 0;
    }

    button {
        font-weight: $fw700;

        &:not(:first-child) {
            margin-left: 0;
            margin-top: 8px;
        }
    }

    button.accept {
        svg {
            margin-left: 8px;
            path {
                fill: $color-white;
            }
        }
    }

    button.cancel {
        background-color: #f1f1f1 !important;
        color: #777 !important;

        svg {
            margin-left: 8px;
            path {
                fill: $color-gray;
            }
        }
    }
}

//=======================================
// UI * Material UI Dialog - Wallet
//=======================================
.MuiDialogContent-root._dialog-details {
    padding: 0;

    .MuiTypography-root.MuiTypography-body2 {
        ._accordion {
            ._accordion-head {
                padding: 0;
                padding-right: 15px;
                border-bottom: 0;

                ._visible-content {
                    ._list-content {
                        li {
                            padding-top: 5px;
                            padding-bottom: 5px;

                            ._item-title {
                                margin-bottom: 0;
                            }

                            ._item-content {
                                margin-top: -2px;
                            }
                        }
                    }
                }
            }

            ._accordion-content {
                ._history-block {
                    padding: 0.9375rem;
                    margin: 0;
                    box-shadow: none !important;

                    &:first-child {
                        border-top: 0;
                    }

                    ._cols {
                        ._col {
                            &:last-child {
                                display: none !important;
                                opacity: 0 !important;
                            }
                        }
                    }
                }
            }
        }

        ._list-content {
            li.flex {
                ._item-content {
                    .detail-type {
                        svg {
                            margin-right: 6px;
                        }
                    }
                }
            }
        }
    }
}

//=======================================
// UI * Sweet Alert - Force zIndex
//=======================================
.swal2-container {
    z-index: 1331 !important;
}

.swal2-html-container {
    .details-swall-list {
        li {
            text-align: left;
            font-weight: $fw700;
            font-size: 12px;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px dashed #f9f9f9;

            &:last-child {
                margin-bottom: 0;
            }

            div {
                font-size: 14px;
            }
        }
    }
}

.modal-change-address {
    @include media-breakpoint-up(xs) {
        padding-bottom: 1rem !important;
        padding-top: 0 !important;
    }

    @include media-breakpoint-up(md) {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }

    ._block-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-white;
        margin-bottom: 1px;
        border-bottom: 1px solid #f9f9f9;

        @include media-breakpoint-up(xs) {
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            border-top: 1px solid #f9f9f9;
            margin-top: 15px;
        }

        ._title-box {
            margin-bottom: 0;
            width: 80%;
        }

        ._action {
            margin-left: 0;
            width: $percent-50;
            text-align: right;
            padding-right: 15px;

            .btn-add-payment {
                font-size: 12px;
                font-weight: $fw700;
                color: $color-white;
                font-family: $font-comfortaa;
                padding: 0.375rem 0.75rem;
                max-width: 122px;

                svg {
                    path {
                        fill: $color-white;
                    }
                }
            }
        }
    }

    form {
        max-width: 800px !important;
    }
}

//=======================================
// UI * Sweet Alert - Error Custom
//=======================================
.swal-modal.swall-error {
    .swal-icon.swal-icon--info {
        width: 50px;
        height: 50px;
        border-width: 2px;
        border-color: $color-nude;

        &::before {
            width: 3px;
            height: 16px;
            bottom: 10px;
            border-radius: 2px;
            margin-left: -1px;
        }

        &::after {
            top: 15px;
            width: 5px;
            height: 5px;
            margin-left: -2px;
        }

        &::before,
        &::after {
            background-color: $color-nude;
        }
    }

    .swal-title {
        padding-top: 0;
        color: $color-blue;
        font-size: 14px;
    }

    .swal-text {
        font-size: 13px;
        font-weight: $fw700;
    }
}

//=======================================
// UI * Material UI Dialog - Cupom
//=======================================
._dialog-cupom {
    padding: 0 !important;

    .MuiTypography-root {
        ._advertiser-card {
            font-family: "Comfortaa", cursive;
            padding: 0.9375rem;
            background-color: #f9f9f9;

            @include media-breakpoint-up(md) {
                margin-top: 15px;
            }
        }

        .discount-box {
            box-shadow: none;
            padding-left: 0;
            padding-right: 0;
            padding: 0.9375rem;
            margin-top: 15px;

            .top {
                ._cols {
                    ._col {
                        .button-action {
                            margin: 0;
                        }
                    }
                }
            }
        }

        ._generate-coupon-box {
            padding: 0.9375rem;
            margin-bottom: 15px;

            .wrap-field {
                border: 2px dashed rgba(43, 57, 81, 0.7);
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 4px;

                ._field {
                    width: $percent-100;
                    text-align: center;
                    font-family: "Nunito", sans-serif;
                    text-transform: uppercase;
                    font-size: 1.125rem;
                    font-weight: $fw700;
                    padding: 0 0.4085rem;
                    -webkit-transition: all 0.25s ease-in-out;
                    -moz-transition: all 0.25s ease-in-out;
                    -ms-transition: all 0.25s ease-in-out;
                    -o-transition: all 0.25s ease-in-out;
                    transition: all 0.25s ease-in-out;

                    img {
                        height: 38px;
                        width: $percent-100;
                    }

                    input {
                        border: 0;
                        background-color: transparent;
                        text-align: center;
                    }
                }

                ._blur._field {
                    filter: blur(4px) !important;
                    -webkit-filter: blur(4px) !important;
                }

                .field-coupon.blink {
                    animation: blinker 0.9s linear 3;
                }

                @keyframes blinker {
                    50% {
                        opacity: 0;
                    }
                }

                button {
                    padding: 0;
                    width: 50%;
                    font-family: "Nunito", sans-serif;
                    font-size: 14px;
                    font-weight: $fw600 !important;
                    color: $color-blue;
                    background-color: #f1f1f1;
                    border-radius: 4px;
                    // margin-left: 10px;

                    @include media-breakpoint-up(xs) {
                        padding: 0.5319rem;
                    }

                    @include media-breakpoint-up(md) {
                        padding: 0.6085rem;
                    }

                    &:active,
                    &:focus {
                        background-color: #f1f1f1 !important;
                    }
                }
            }
        }

        ._tabs {
            .MuiTabs-root._material-tabs {
                border-bottom: 1px solid #f1f1f1;

                .MuiTabs-scroller.MuiTabs-fixed {
                    .MuiTabs-flexContainer {
                        justify-content: flex-start;

                        .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
                            width: $percent-100;
                            max-width: 50%;

                            .MuiTab-wrapper {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .tabpanel {
                font-family: "Comfortaa", cursive;
                font-weight: $fw700;
                color: $color-black;
                padding: 0.9375rem;

                ._section {
                    .title {
                        font-family: "Nunito", sans-serif;
                        font-size: 16px;
                        color: $color-blue;
                        font-weight: $fw400;
                        margin-bottom: 8px;
                    }

                    .list {
                        li {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 0.425rem 0;

                            .icon {
                                background-color: $color-white;
                                height: 16px;
                                width: 14px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                top: -1px;

                                svg {
                                    width: 12px;
                                    height: auto;

                                    path {
                                        fill: $color-blue;
                                    }
                                }
                            }

                            .text {
                                font-size: 13px;
                                font-weight: $fw700;
                                margin-left: 6px;

                                span.partner-name {
                                    color: $color-red;
                                }
                            }
                        }
                    }

                    ._list-socials {
                        margin-top: 14px;

                        @include media-breakpoint-up(xs) {
                            display: flex;
                            justify-content: flex-start;
                            width: $percent-100;
                        }

                        @include media-breakpoint-up(md) {
                            width: auto;
                        }

                        ul {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;

                            li {
                                margin-left: 10px;

                                &:first-child {
                                    margin-left: 0;
                                }

                                a,
                                button {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: $color-blue !important;
                                    height: 40px;
                                    width: 40px;
                                    min-width: 40px;
                                    border-radius: 100px;

                                    svg {
                                        height: 20px;
                                        width: auto;

                                        path {
                                            fill: $color-white;
                                        }
                                    }
                                }

                                &:nth-child(2) {
                                    a,
                                    button {
                                        svg {
                                            height: 18px;
                                        }
                                    }
                                }

                                &:nth-child(3) {
                                    a,
                                    button {
                                        svg {
                                            height: 16px;
                                        }
                                    }
                                }

                                &:nth-child(4) {
                                    a,
                                    button {
                                        svg {
                                            height: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//=============================================
// UI * Bootstrap Modal Dialog - Filter Sizes
//=============================================

._wrap-items._sizes {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    padding-top: 15px;

    .FilterHead {
        background-color: $color-ice;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.625rem 0.9375rem;
        margin-top: 40px;
        margin-bottom: 15px;

        &:first-child {
            margin-top: 0;
        }

        ._filter-selected {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: $fw700;
            font-size: 12px;

            span.dot {
                margin: 0 0.3125rem;
                font-size: 1rem;
                position: relative;
                top: 0.125rem;
            }

            &:last-child {
                span.dot {
                    display: none;
                }
            }
        }
    }
}

.missingPointsModal {
    margin-bottom: 0 !important;

    ._subtitle {
        display: none !important;
    }
}

//=============================================
// UI * Dialog - Mobile User Menu
//=============================================

.MuiDialog-root.UserMenuDialog {

    .MuiDialog-container {

        .MuiPaper-root {

            .MuiDialogTitle-root.DialogTitle {
                padding: 0;
                
                .MuiTypography-root {
                    position: relative;

                    .icon-with-label {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        background-color: $color-ice;
                        height: 40px;
                        width: 40px;
                        z-index: 2;
                    }
                }
            }

            .MuiDialogContent-root.ModalContent {
                padding-top: 0;
                padding-left: 0;
                padding-right: 0;

                .user-cover {
                    height: auto;
                    width: $percent-100;
                    position: relative;

                    img {
                        width: $percent-100;
                        height: $percent-100;
                        object-fit: cover;
                        border: 0;
                        max-width: $percent-100;
                        display: inline-block;
                        vertical-align: middle;
                    }
                
                    .icon-with-label {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        background-color: $color-ice;
                        height: 40px;
                        width: 40px;
                    }

                }
                
                .scamb-user {
                    text-align: center;
                    margin-top: -28px;
                    position: relative;
                    z-index: 1;
                    
                    .username {
                        font-weight: $fw700;
                        font-size: 12px;
                        color: $color-black;
                        margin-top: 6px;
                        text-transform: capitalize;
                    }

                    .scamb-points {
                        margin-top: 5px;
                    }
                }

                .menu-content {
                    margin-top: 15px;
                    margin-bottom: 30px;
                    
                    .actions {
                        text-align: center;
                        
                        button {
                            
                            &:last-child {
                                margin-top: 10px;
                            }
                        }
                    }
                }

                .menu-title {
                    font-size: 10px;
                    font-family: $font-nunito !important;
                    font-weight: $fw700;
                    opacity: 0.5;
                    text-transform: uppercase;
                }
                
                .menu-content,
                .menu-title,
                .scamb-menu {
                    padding-left: 0.9375rem;
                    padding-right: 0.9375rem;
                }

                .scamb-menu {

                    ul {

                        li {

                            &:last-child {
                                a {

                                    .menu-item-columns {

                                        .menu-item-column {

                                            &:first-child {

                                                .icon {

                                                    svg {

                                                        path {

                                                            fill: $color-red;
                                                        }
                                                    }
                                                }

                                                .menu-description {

                                                    .title {
                                                        color: $color-red;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.MuiDialog-root.UserMenuDialog.user-menu-shopping {
    .MuiDialog-container {

        .MuiPaper-root {

            .MuiDialogContent-root.ModalContent {

                .MuiTypography-root {

                    .menu-content {

                        .actions {
                            button {
                                &:nth-child(1) {
                                    background-color: $color-violet !important;
                                    border-color: $color-violet !important;
                                }

                                &:nth-child(2) {
                                    border-color: $color-violet !important;
                                    
                                    a {
                                        color: $color-violet !important;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}

//=============================================
// UI * Dialog - Mobile User Menu
//=============================================

.MuiDialog-root.UserMenuDialog.CategoriesMenuDiloag {

    .MuiDialog-container {

        .MuiPaper-root {

            .MuiDialogTitle-root.DialogTitle {
                padding: 0;
                // border-bottom: 1px solid rgba(204, 204, 204, 0.2);
                
                .MuiTypography-root {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 70px;

                    .title {
                        font-size: 0.75rem;
                        font-family: $font-nunito !important;
                        font-weight: $fw700;
                        color: $color-black;
                        text-transform: uppercase;
                        opacity: 0.8;
                    }

                    .icon-with-label {
                        position: absolute;
                        top: 15px;
                        left: 15px;
                        background-color: $color-ice;
                        height: 40px;
                        width: 40px;
                        z-index: 2;
                    }
                }
            }

            .MuiDialogContent-root.ModalContent {

                .MuiTypography-root {

                    .categories-menu {

                        .wrap-back-list {
                            position: relative;
                            width: $percent-100;
                            display: block;
                            
                            &::after {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 0;
                                right: 0;
                                height: $percent-100;
                                width: 50px;
                                background: rgb(246,246,249);
                                background: -moz-linear-gradient(90deg, rgba(246,246,249,0) 0%, rgba(249,249,251,0.7) 43%, rgba(255,255,255,0.7959558823529411) 61%);
                                background: -webkit-linear-gradient(90deg, rgba(246,246,249,0) 0%, rgba(249,249,251,0.7) 43%, rgba(255,255,255,0.7959558823529411) 61%);
                                background: linear-gradient(90deg, rgba(246,246,249,0) 0%, rgba(249,249,251,0.7) 43%, rgba(255,255,255,0.7959558823529411) 61%);
                                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f6f9",endColorstr="#ffffff",GradientType=1);
                            }

                            .back-list {
                                background-color: $color-ice;
                                display: inline-flex;
                                align-items: center;
                                justify-content: flex-start;
                                width: $percent-100;
                                overflow: scroll;
                                position: relative;

                                .back-list-item {
                                    border: 0;
                                    padding: 0.75rem 0.4688rem 0.75rem 0.4688rem;
                                    background-color: $color-ice;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    &:first-child {
                                        padding-left: 0.9375rem;
                                    }

                                    .text {
                                        font-weight: $fw700;
                                        color: $color-black;
                                        font-size: 0.6875rem;
                                        margin-left: 0.625rem;
                                        opacity: 0.9;
                                        text-align: left;
                                        white-space: nowrap;
                                    }

                                    .icon {
                                        svg {
                                            height: 13px;
                                            width: auto;

                                            path {

                                                fill: $color-black;
                                                opacity: 0.5;
                                            }
                                        }
                                    }
                                }

                            }
                        }

                        .menu-list {

                            li {
                                border-bottom: 1px solid rgba(204, 204, 204, 0.2);
                                
                                &:first-child {
                                    border-top: 1px solid rgba(204, 204, 204, 0.2);
                                }

                                label {
                                    margin: 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    width: $percent-100;
                                    padding: 0.75rem 0.9375rem;

                                    input[type=radio],
                                    input[type=checkbox] {
                                        display: none !important;
                                        visibility: hidden !important;
                                    }

                                    .text {
                                        font-weight: $fw700;
                                        color: $color-black;
                                        font-size: 12px;
                                    }

                                    .icon {
                                        svg {
                                            height: 13px;
                                            width: auto;

                                            path {

                                                fill: $color-black;
                                                opacity: 0.5;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//=============================================
// UI * Dialog - Extract Details
//=============================================
.MuiDialog-root.DialogExtractDetail {

    .MuiDialog-container {

        .MuiPaper-root {

            .MuiDialogTitle-root {

                .MuiTypography-root {

                    ._view-head-content {
                        position: relative;
                        height: 58px;

                        .icon-with-label {
                            position: absolute;
                            max-height: 40px;
                            top: 0;
                            bottom: 0;
                            right: 10px;
                            margin-top: auto;
                            margin-bottom: auto;
                            background-color: $color-ice;
                        }
                    }
                }
            }

            .MuiDialogContent-root {

                .MuiTypography-root {

                    .title {
                        font-size: 14px;
                        font-weight: $fw700;
                        color: $color-black;
                        text-align: center;
                        padding: 5px 0;
                    }

                    ._list-content {

                        li {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            
                            ._item-content {

                                ._product-thumbnail {
                                    
                                    a {
                                        margin-right: 15px;

                                        ._image {
                                            height: 40px;
                                            width: 40px;
                                            min-width: 40px;
                                        }

                                        ._product-info {

                                            .title {
                                                font-size: 0.75rem;
                                                white-space: nowrap;
                                                margin-bottom: 0;
                                            }

                                            .date,
                                            ._product-price._1x {
                                                display: none !important;
                                            }
                                        }
                                    }

                                    ._col {

                                        &:last-child {
                                        
                                            svg {
                                                
                                                path {
                                                    opacity: 0.5;
                                                    fill: $color-black;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.MuiDialogContent-root.__account-bank-details {

    .MuiTypography-root {

        .form-transfer-money {
            
            .field-money {
                border: 1px solid $color-ice-bold;
                padding: 0.3125rem;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;
                
                @include media-breakpoint-up(xs) {
                    padding-left: 50px;
                }
                
                @include media-breakpoint-up(md) {
                    padding-left: 100px;
                }

                input {
                    width: 50%;
                    text-align: left;
                    font-size: 1.6875rem;
                    color: $color-black;
                    font-weight: $fw700;
                    border: 0;
                }

                .placeholder {
                    // position: absolute;
                    top: 0;
                }
            }

            .field-money.error {
                border-color: $color-red;
            }

            .minimun-value-transfer {
                color: $color-gray;
                display: block;
                text-align: center;
                font-size: 12px;
                font-weight: $fw700;
                font-family: $font-comfortaa;
                position: relative;
                top: -16px;

                span {
                    color: $color-blue;
                }
            }
            
            .address-card {
    
                label {
    
                    ._cols {
    
                        ._col {
    
                            &:first-child {
    
                                .icon {
                                    background-color: $color-blue;
                                    height: 26px;
                                    width: 26px;
                                    min-width: 26px;
    
                                    svg {
                                        path {
                                            fill: $color-white;
                                        }
                                    }
                                }
                            }
    
                            &:last-child {
    
                                .tag {
                                    margin-bottom: 0.625rem;
                                }
    
                                ul {
    
                                    li {
                                        font-size: 0.8125rem;
                                        font-weight: $fw700;
                                        color: $color-gray-bolder;
                                        margin-bottom: 0.25rem;
                                        
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                        
                                        span {
                                            color: $color-black;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .wrap-submit {
                text-align: center;
                margin-top: 20px;

                button {
                    font-family: $font-comfortaa !important;

                    &:disabled {
                        opacity: 0.5;
                    }
                }
            }
        }

    }
}