#view_invite-friends {

    ._title-box {
        margin-bottom: 1px;
    }

    ._columns {

        ._column {
            background-color: $color-white;
            @include padding-lt-rt;

            ._block-title {
                font-size: 16px;
                font-weight: 600;
                color: #3d3d40;
                padding: 0.95rem 0.9375rem 0 0;
                font-family: "Nunito", sans-serif;
                margin: 0;
            }

            ._block {

                hgroup {
                    margin-bottom: 15px;


                    h1, h2 {
                        font-weight: $fw700;
                        color: $color-blue;
                    }
                    h1 {
                        margin-bottom: 10px;
                        
                        @include media-breakpoint-up(xs)  {
                            font-size: 16px;
                        }
                        
                        @include media-breakpoint-up(md) {
                            font-size: 20px;
                        }
                    }
                    
                    h2 {
                        font-size: 13px;
                        opacity: 0.7;
                        line-height: 1.6;

                        @include media-breakpoint-up(xs) {
                            max-width: 218px;
                        }

                        @include media-breakpoint-up(md) {
                            max-width: inherit;
                        }
                    }
                }

                ._invite-field {

                    display: flex;
                    
                    @include media-breakpoint-up(xs) {
                        align-items: flex-start;
                        flex-direction: column;
                    }
                    
                    @include media-breakpoint-up(md) {
                        align-items: center;
                        flex-direction: row;
                    }

                    ._form {
                        padding-bottom: 0;
                        width: $percent-100;
                        max-width: 420px;

                        .form-group {
                            border: 1px solid $color-ice;
                            display: flex;
                            border-radius: 4px;
                            margin-bottom: 0;

                            input {
                                padding-left: 15px;
                            }

                            button {
                                max-width: 48px;
                            }

                            button.check {
                                svg {
                                    path {
                                        fill: $color-green;
                                    }
                                }
                            }
                        }
                    }

                    ._list-socials {

                        
                        @include media-breakpoint-up(xs)  {
                            margin-top: 20px;
                            display: flex;
                            justify-content: center;
                            width: $percent-100;
                        }
                        
                        @include media-breakpoint-up(md)  {
                            margin-top: 0;
                            margin-left: 15px;
                            width: auto;
                        }

                        ul {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;

                            li {
                                margin-left: 10px;

                                &:first-child {
                                    margin-left: 0;
                                }

                                a,
                                button {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: $color-blue !important;
                                    height: 40px;
                                    width: 40px;
                                    min-width: 40px;
                                    border-radius: 100px;

                                    svg {
                                        height: 20px;
                                        width: auto;

                                        path {
                                            fill: $color-white;
                                        }
                                    }

                                }

                                &:nth-child(2) {
                                    a,
                                    button {
                                        svg {
                                            height: 18px;
                                        }
                                    }
                                }

                                &:nth-child(3) {
                                    a,
                                    button {
                                        svg {
                                            height: 16px;
                                        }
                                    }
                                }

                                &:nth-child(4) {
                                    a,
                                    button {
                                        svg {
                                            height: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                ._history-block {

                    margin-left: 0;
                    margin-right: 0;

                    ._cols {

                        ._col {

                            .items {

                                .item {

                                    height: 24px;
                                    .icon {
                                        height: 24px;
                                    }
                                    &:nth-child(3) {

                                        .icon {
                                            svg {
                                                height: 10px;
                                                width: auto;
                                                position: relative;
                                                top: 0px;

                                                path { opacity: 1; }
                                            }
                                        }

                                        .icon.green {
                                            svg {
                                                path { fill: $color-green; }
                                            }
                                        }
                                        .icon.nude {
                                            svg {
                                                path { fill: $color-nude; }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        ._column.hero {
            padding-top: 1.875rem;
            padding-bottom: 1.875rem;
            margin-bottom: 1px;
        }

        ._column._points {
            background-color: $color-white;
            border-bottom: 1px dashed #F9FAFE;
      
            ._block {

                ._list-content {
                li {
                    padding-left: 0;
                    
                  ._item-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
      
                    @include media-breakpoint-up(md) {
                      max-width: 200px;
                    }
      
                    svg {
                      height: 16px;
                      width: auto;
                      path {
                        fill: $color-gray;
                        opacity: 0.8;
                      }
                    }
      
                    ._product-price {
                      .ui-item__price {
                        .price-tag-fraction {
                          @include media-breakpoint-up(xs) {
                            font-size: 20px;
                          }
                        }
      
                        .price-tag-symbol {
                            display: none !important;
                            visibility: hidden !important;
                            opacity: 0 !important;
                          @include media-breakpoint-up(xs) {
                            font-size: 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        }

        ._column._activity {
            padding-bottom: 1.875rem;

            ._block {

                ._block-title {
                    margin-bottom: 15px;
                }
            }
        }
    }

}