#myads {
  padding: 0;

  .nav.nav-tabs {
    padding-top: 6px;
    border-bottom: 1px solid $color-ice;
    background-color: $color-white;
  }

  .tab-content {
    padding: 15px;
    background-color: $color-white;

    ._wrap-my-shopping-item {
	  border: 1px solid $color-ice;
	  margin-bottom: 10px;

      ._my-shopping-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 10px;
        position: relative;

        ._col {
          .product {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            .product-image {
              overflow: hidden;
              border: 1px solid $color-ice;

              @include media-breakpoint-up(xs) {
                height: 88px;
                width: 88px;
              }

              @include media-breakpoint-up(sm) {
                height: 108px;
                width: 108px;
              }

              img {
                height: 100%;
                width: auto;
              }
            }

            .product-info {
              margin-top: 10px;
              margin-left: 10px;
              font-weight: $fw700;

              .product-info-title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @include media-breakpoint-up(xs) {
                  width: 130px;
                }

                @include media-breakpoint-up(sm) {
                  width: 190px;
                }
              }

              .published-in {
                color: $color-gray;
                margin-top: 10px;

                @include media-breakpoint-up(xs) {
                  font-size: 10px;
                }

                @include media-breakpoint-up(md) {
                  font-size: 12px;
                }

                span {
                  color: $color-black;
                }
              }
            }
          }

          .proposals-received-title {
            text-align: center;
            margin-top: 10px;

            @include media-breakpoint-up(xs) {
              display: none;
            }

            @include media-breakpoint-up(md) {
              display: block;
            }
            span {
              display: block;
              margin-top: 5px;
            }
          }

          ._action-buttons {
            padding: 0;
            display: flex;
            align-items: center;

            @include media-breakpoint-up(xs) {
              flex-direction: column;
              position: absolute;
              right: 0px;
              top: 0;
              height: 100%;
              border-left: 1px solid #fbfbfb;
            }

            @include media-breakpoint-up(md) {
              position: relative;
              flex-direction: row;
            }

            .btn {
              font-size: 13px;
              font-weight: $fw700;
              border: 0;
              svg {
                position: relative;
                top: -2px;
              }

              @include media-breakpoint-up(xs) {
                height: 100%;
              }

              @include media-breakpoint-up(md) {
                background-color: $color-ice;
              }

              &:last-child {
                @include media-breakpoint-up(xs) {
                  margin-left: 0;
                  border-top: 1px solid #fbfbfb;
                }

                @include media-breakpoint-up(md) {
                  margin-left: 5px;
                }
              }

              span {
                @include media-breakpoint-up(xs) {
                  display: none;
                }

                @include media-breakpoint-up(md) {
                  display: inline-block;
                }
              }
            }
          }
        }
      }

      ._my-shopping-item._inactive {
        &::after {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.7);
          width: 100%;
          height: 100%;
        }

        ._action-inactive {
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: {
            top: auto;
            bottom: auto;
            left: auto;
            right: auto;
          }
          height: 28px;
          width: 80px;

          ._action-buttons {
            button {
              background-color: $color-ice;
            }
          }
        }
      }
    }

    ._recent_messages {
      ._wrap-message {
        border-top: 1px solid $color-ice;
        display: flex;
        justify-content: space-between;
        padding: 20px 0 10px 0;

        @include media-breakpoint-up(xs) {
          flex-direction: column;
          align-items: flex-start;
        }

        @include media-breakpoint-up(md) {
          flex-direction: row;
          align-items: center;
        }

        .contact-infos {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 10px;

          .contact-informations {
            margin-left: 10px;

            .contact-name,
            .contact-message-preview {
              font-size: 12px;
              font-weight: $fw700;
              line-height: 1.2;
            }

            .contact-name {
              color: $color-black;
            }

            .contact-message-preview {
              color: $color-gray;
              margin-top: 3px;
              @include media-breakpoint-up(xs) {
                white-space: nowrap;
                width: 210px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }

        .message-date {
          padding-right: 10px;
          font-size: 11px;
          color: $color-gray;

          @include media-breakpoint-up(xs) {
            margin-left: 58px;
            margin-top: 5px;
          }

          @include media-breakpoint-up(md) {
            margin-left: 0;
            margin-top: 0;
          }
        }
      }
    }

    ._load-more-messages {
      border-top: 1px solid $color-ice;
      text-align: center;
      padding: 10px;

      ._load-more {
        font-weight: $fw700;
        font-size: 13px;
        color: $color-blue-light;
      }
    }
  }
}
