#view_partners {

    background-color: $color-white;
        
    ._wrap-help-search {

        background-color: #f9fafe;

        @include media-breakpoint-up(xs) {
            padding: 30px 0;
        }

        @include media-breakpoint-up(md) {
            padding: 40px 0;
        }

        ._med-container {
            
            max-width: 430px;
            width: $percent-100;
            
            ._title {
                font-weight: $fw700;
                color: $color-black;
                text-align: center;
                line-height: 1.7;
                
                @include media-breakpoint-up(xs) {
                    font-size: 15px;
                    max-width: 220px;
                    margin: 0 auto;
                    margin-bottom: 10px;
                }

                @include media-breakpoint-up(md) {
                    max-width: none;
                    font-size: 18px;
                }
            }

            .search-menu {

                .form {
                    padding-bottom: 0;
                }
            }
        }
    }

    ._wrap-content {
        background-color: $color-white;

        ._med-container {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;

        @include media-breakpoint-up(xs) {
            flex-direction: column;
        }

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        .filter {
            min-width: 275px;

            @include media-breakpoint-up(xs) {
                padding-bottom: 15px;
            }

            form {

                ._active-filters--mobile {
                    margin-bottom: 0;

                    button {

                        &:first-child {
                            
                            svg {
                                width: 14px;
                                position: relative;
                                top: 1px;
                            }
                        }
                    }
                }

                ._filter-item {

                    ._filter-selected {
                        @include media-breakpoint-up(md) {
                            margin-bottom: 14px;
                        }
                    }

                    .MuiGrid-container {

                        .MuiGrid-item {

                            @include media-breakpoint-up(md) {
                                padding-top: 0;
                                padding-bottom: 0;
                            }

                            ._filter-label._brand {

                                
                                @include media-breakpoint-up(xs) {
                                    text-align: center;
                                } 
                                
                                @include media-breakpoint-up(md) {
                                    text-align: left;
                                }

                                .brand-image {
                                    height: 65px;
                                    width: $percent-100;
                                    border-radius: 4px;
                                    overflow: hidden;
                                    position: relative;

                                    @include media-breakpoint-up(md) {
                                        display: none;
                                    }

                                    img {
                                        height: auto;
                                        width: $percent-100;
                                    }

                                    .mark {
                                        position: absolute;
                                        top: 3px;
                                        right: 3px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        height: 22px;
                                        width: 22px;
                                        background-color: #F1F1F1;
                                        border-radius: 100px;
                                        border: 0;

                                        svg {

                                            height: 13px;
                                            width: auto;

                                            path {

                                                fill: #CCCCCC;
                                            }
                                        }
                                    }
                                }

                                input:checked ~ .brand-image {

                                    .mark {
                                        background-color: $color-green;
                                        svg {

                                            path { fill: $color-white; }
                                        }
                                    }
                                }

                                ._filter-title {
                                    
                                    @include media-breakpoint-up(xs) {
                                        font-size: 11px;
                                    } 

                                    @include media-breakpoint-up(md) {
                                        font-size: 13px;
                                    } 
                                }
                            }
                        }
                    }
                }

                ._apply-filters {
                    margin-top: 25px;

                    @include media-breakpoint-up(xs) {
                        display: none;
                    }

                    @include media-breakpoint-up(md) {
                        display: block;
                    }

                    button {

                        svg {

                            path {

                                fill: $color-white;
                            }
                        }
                    }
                }
            }
        }

        ._content {
            width: $percent-100;
            padding-bottom: 30px;

            @include media-breakpoint-up(md) {
                padding-top: 30px;
            }
            ._title {

                @include media-breakpoint-up(xs) {
                    padding-top: 10px;
                    padding-bottom: 15px;
                }
                
                @include media-breakpoint-up(md) {
                    padding-top: 0;
                    margin-bottom: 0;
                }

                h1 {
                    font-weight: $fw700;
                    color: $color-black;
                    
                    @include media-breakpoint-up(xs) {
                        font-size: 15px;
                    }
                    
                    @include media-breakpoint-up(md) {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    }

    
}

#view_promotion-discount {
    padding-bottom: 30px;

    ._content {

        ._title {

            @include media-breakpoint-up(xs) {
                padding-top: 20px;
                padding-bottom: 15px;
            }
            
            @include media-breakpoint-up(md) {
                padding-top: 10px;
                margin-bottom: 0;
            }

            h1 {
                font-weight: $fw700;
                color: $color-black;
                
                @include media-breakpoint-up(xs) {
                    font-size: 15px;
                }
                
                @include media-breakpoint-up(md) {
                    font-size: 18px;
                }
            }
        }
    }
}

//==============================================================================
// UI Components * Discount Box
//==============================================================================
.discount-box {
    background-color: $color-white;
    padding: 1.25rem 0.9375rem;
    border-radius: 4px;
    -webkit-box-shadow: 0 4px 8px 4px rgba(200,200,200,0.1);
    box-shadow: 0 4px 8px 4px rgba(200,200,200,0.1);
    font-family: 'Comfortaa', cursive;

    .top {

        ._cols {

            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            ._col {

                &:first-child {

                    border-right: 1px solid $color-ice;
                    padding: 0 0.625rem;

                    .percent-off {
                        font-size: 28px;
                        font-weight: $fw700;
                        color: $color-red;
                        text-transform: uppercase;
                        text-align: center;
                        line-height: 1;
                        margin-top: 5px;

                        span {
                            display: block;
                            font-size: 14px;
                        }
                    }
                }

                &:last-child {
                    margin-left: 0.625rem;
                    width: $percent-100;
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    
                    @include media-breakpoint-up(xs) {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    
                    @include media-breakpoint-up(md) {
                        flex-direction: row;
                        align-items: center;
                    }

                    .infos {

                        p {
                            font-weight: $fw700;
                        }

                        p.text {
                            font-size: 0.875rem;
                            color: $color-black;
                        }
                        
                        p.small {
                            font-size: 	0.75rem;
                            color: $color-blue;
                            margin-top: 0.75rem;
                        }
                    }

                    .button-action {
                        
                        @include media-breakpoint-up(xs) {
                            margin-top: 22px;
                            width: $percent-100;
                        }
                        
                        @include media-breakpoint-up(md) {
                            margin-top: 0;
                            width: 30%;
                            margin-left: 1rem;
                        }

                        .see-offer.btn.btn-primary {
                            color: $color-white;
                            padding: 0.669rem 0.75rem;
                            max-width: 620px;
                        }
                    }
                }
            }
        }
    }
}