#fixed-menu {
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: map-get($z-index, fixed-menu);
	-webkit-box-shadow: 0 -4px 10px 2px rgba(150, 150, 150, 0.3);
	box-shadow: 0 -4px 10px 2px rgba(150, 150, 150, 0.3);

	@include media-breakpoint-up(xs) {
		display: block;
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}

	ul {

		display: flex;
		align-items: center;
		justify-content: space-between;


		li {
			height: 50px;
			min-width: 70px;
			width: $percent-100;
			display: flex;
			align-items: center;
			justify-content: center;

			.wrap {

				.icon {
					background-color: transparent;
					height: 22px;
					width: $percent-100;
					display: flex;
					align-items: center;
					justify-content: center;

					svg {

						height: 18px;
						width: auto;

						path {
							fill: $color-white;
						}
					}

					.avatar-small {
						border: 0;
						padding: 0;
						background-color: transparent;

						.image {
							height: 20px;
							width: 20px;
							border-radius: 100px;
							overflow: hidden;
						
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
								border: 0;
								max-width: 100%;
								display: inline-block;
								vertical-align: middle;
								position: relative;
								top: -1px;
							}
						}
					}
				}

				.text {
					color: $color-white;
					font-family: $font-nunito;
					font-size: 10px;
					font-weight: $fw300;
					text-align: center;
					line-height: 1;
					margin-top: 2px;
					text-transform: capitalize;
				}
			}
		}

		li.active {
			
			.wrap {

				.icon {

					svg {

						path {
							fill: $color-red;
						}
					}
				}

				.text {
					color: $color-red;
					font-weight: $fw700;
				}
			}
		}
	}
}

.fixed-menu-scamb {
	background-color: $color-blue;
}

.fixed-menu-shopping {
	background-color: $color-violet;
}
