// Google Fonts Link: https://fonts.google.com/specimen/Comfortaa?selection.family=Comfortaa:400,500,700
//@import url('https://fonts.googleapis.com/css?family=Comfortaa:400,500,700&display=swap');

// Google Fonts Link: https://fonts.google.com/specimen/Poppins?selection.family=Poppins:800
@import url("https://fonts.googleapis.com/css?family=Poppins:600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap");

// @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

/* cyrillic-ext */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4QIFqPfE.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
// /* cyrillic */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4SYFqPfE.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
// /* greek */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4ToFqPfE.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
// /* vietnamese */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4QoFqPfE.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
// /* latin-ext */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4Q4FqPfE.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
// /* latin */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4TYFq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
// /* cyrillic-ext */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4QIFqPfE.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
// /* cyrillic */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4SYFqPfE.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
// /* greek */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4ToFqPfE.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
// /* vietnamese */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4QoFqPfE.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
// /* latin-ext */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4Q4FqPfE.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
// /* latin */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4TYFq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
// /* cyrillic-ext */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4QIFqPfE.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
// /* cyrillic */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4SYFqPfE.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
// /* greek */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4ToFqPfE.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
// /* vietnamese */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4QoFqPfE.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
// /* latin-ext */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4Q4FqPfE.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
// /* latin */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4TYFq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
// /* cyrillic-ext */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4QIFqPfE.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
// /* cyrillic */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4SYFqPfE.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
// /* greek */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4ToFqPfE.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
// /* vietnamese */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4QoFqPfE.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
// /* latin-ext */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4Q4FqPfE.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
// /* latin */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url(https://fonts.gstatic.com/s/comfortaa/v28/1Ptsg8LJRfWJmhDAuUs4TYFq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
