form {
  #view_create-ad {
    .wrap-view._screen-conf.create-new-add {
      @include media-breakpoint-up(xs) {
        z-index: map-get($z-index, completeRegistration);
      }
      @include media-breakpoint-up(md) {
        position: relative;
        margin-top: 60px;
        margin-bottom: 60px;
        padding-top: 40px;
        padding-bottom: 0;
        border: 1px solid rgba(241, 241, 241, 0.4);
        -webkit-box-shadow: 0 8px 8px 4px rgba(120, 120, 120, 0.1);
        box-shadow: 0 1px 8px 4px rgba(160, 160, 1260, 0.1);
        z-index: auto;
      }

      overflow: hidden;
      overflow-y: auto;

      height: 100%;

      ._view-content {
        padding-left: 0;
        padding-right: 0;

        ._content-title {
          @include media-breakpoint-up(xs) {
            display: none;
          }
          @include media-breakpoint-up(md) {
            display: block;
          }
        }

        ._add-photos {
          padding: {
            top: 10px;
            left: 15px;
            right: 15px;
            bottom: 5px;
          }

          @include media-breakpoint-up(xs) {
            background-color: $color-box;
          }

          @include media-breakpoint-up(md) {
            background-color: $color-white;
          }

          ._photos {
            margin-bottom: 0;
            
            ._wrap-photo-item {
              margin-top: 0;
            }

            ._wrap-photos {
              margin-top: 5px;
            }
          }
        }

        .form-body {
          margin-top: 20px;
          padding-bottom: 20px;

          padding: {
            left: 15px;
            right: 15px;
          }

          ._select-freight {
            margin-top: 20px;

            ._wrap-freight {
              margin-top: -6px;
            }
          }

          .btn.btn-step {
            display: block;
            position: relative;
            max-width: 320px;
            width: $percent-100;
            margin: auto;
            margin-top: 30px;
          }

          ._label {
            margin-bottom: 5px;
            color: #515157;
            font-size: 12px;
            font-weight: $fw500;
          }
        }
      }
    }
  }
}

.wrap-size {
  background-color: $color-white;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 4px;
  border: 1px solid $color-border;
  cursor: pointer;

  ._mark {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-white;
    width: $percent-100;
    height: $percent-100;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: $color-gray-bolder;
    font-weight: $fw700;
    font-family: $font-comfortaa;

    @include media-breakpoint-up(xs) {
      font-size: 10.5px;
    }
    @include media-breakpoint-up(md) {
      font-size: 12px;
    }
  }

  input:checked ~ ._mark {
    background-color: $color-blue;
    color: $color-white;
  }
}

._adornment-price {
  p {
    font-size: 13px;
    color: $color-gray;
  }
}

//=======================================
// UI * Flull Width - Select Category
//=======================================

.full-width.modal-category {
  div.modal-dialog {
    div.modal-content {
      div.modal-body {
        height: $percent-100;

        @include media-breakpoint-up(md) {
          padding-bottom: 15px;
        }

        ._full-height {
          height: calc(100% - 50px);

          @include media-breakpoint-up(md) {
            padding-bottom: 15px;
          }

          a.tag-selected-radio {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 15px;
            text-decoration: none;

            div._title-selected {
              font-family: "Comfortaa", cursive;
              background-color: #f1f1f1;
              padding-top: 4px;
              padding-bottom: 4px;
              padding-left: 20px;
              padding-right: 20px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              color: #909093;
              font-size: 12px;
              font-weight: 700;
              position: relative;

              white-space: nowrap;
              width: 50px;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            div._title-selected {
              &:last-child {
                background-color: #f1f1f1;
                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;
                padding-top: 4px;
                padding-bottom: 4px;
                padding-right: 20px;
              }
            }

            div._title-selected {
              &::after {
                content: "";
                display: block;
                width: 15px;
                height: 100%;
                background-color: royalblue;
                background: rgb(2, 0, 36);
                background: -moz-linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(241, 241, 241, 1) 60%);
                background: -webkit-linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(241, 241, 241, 1) 60%);
                background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(241, 241, 241, 1) 60%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#f1f1f1",GradientType=1);
                position: absolute;
                right: 0;
                top: 0;
              }
            }

            ._title-selected {
              span._text {
                color: royalblue;
              }
            }

            ._title-selected {
              span._text {
                &::after {
                  display: block;
                  content: "";
                  height: 4px;
                  width: 4px;
                  background-color: #515157;
                  border-radius: 100px;
                  margin: 0 4px;
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  margin-top: auto;
                  margin-bottom: auto;
                  z-index: 9;
                }
              }
            }
          }

          ul#drilldown {
            li {
              label {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 9.82px 0;
                margin: 0;
                cursor: pointer;

                input[type="radio"],
                input[type="checkbox"] {
                  display: none;
                  visibility: hidden;
                }

                span._name {
                  display: block;
                  color: $color-gray;
                  font-size: 13px;
                  font-weight: $fw400;
                }

                input:checked ~ span._name {
                  color: $color-gray-bolder;
                  font-weight: $fw700;
                }

                div.icon {
                  svg {
                    width: 8px;
                    height: auto;

                    path {
                      fill: $color-gray-bolder;
                    }
                  }
                }
              }
            }
          }

          .btn-step {
            margin-top: 20px !important;
          }
        }
      }
    }
  }
}

//=======================================
// UI * Flull Width - Select Size
//=======================================

.full-width.modal-size {
  div.modal-dialog {
    div.modal-content {
      div.modal-body {
        height: $percent-100;

        @include media-breakpoint-up(md) {
          padding-bottom: 15px;
        }

        ._full-height {
          height: calc(100% - 50px);

          @include media-breakpoint-up(md) {
            padding-bottom: 15px;
          }

          .tag-size {
            background-color: #f1f1f1;
            font-size: 13px;
            font-weight: $fw700;
            color: $color-black;
            text-align: center;
            padding: 4px 0;
            margin-bottom: 10px;

            @include media-breakpoint-up(md) {
              margin-top: 15px;
            }
          }

          .btn-step {
            margin-top: 20px !important;
          }
        }
      }
    }
  }
}

//=======================================
// UI * Flull Width - Select Price
//=======================================

.full-width.modal-price {
  div.modal-dialog {
    div.modal-content {
      div.modal-body {
        height: $percent-100;

        @include media-breakpoint-up(md) {
          padding-bottom: 15px;
        }

        ._full-height {
          height: calc(100% - 50px);

          @include media-breakpoint-up(md) {
            padding-bottom: 15px;
          }

          ._label {
            margin-bottom: 8px;
            font-family: $font-comfortaa;
          }

          .price-info {
            background-color: #f1f1f1;
            font-size: 13px;
            font-weight: $fw400;
            color: $color-black;
            text-align: left;
            padding: 20px 10px;
            margin-top: 10px;

            @include media-breakpoint-up(md) {
              margin-top: 15px;
            }

            h3 {
              font-size: 13px;
              font-weight: $fw500;
              color: $color-gray-bolder;
              line-height: 1.6;

              &:first-child {
                margin-bottom: 5px;
              }
            }
          }

          .btn-step {
            margin-top: 20px !important;
          }
        }
      }
    }
  }
}

//===========================================
// UI * Material ui Modal Diloag - Weight
//===========================================
.MuiDialogContent-root._modal-weight {
  .MuiTypography-root {
    ._content {
      .tag-size {
        background-color: #f1f1f1;
        font-size: 13px;
        font-weight: $fw700;
        color: $color-black;
        font-family: $font-comfortaa;
        text-align: center;
        padding: 4px 0;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
          margin-top: 15px;
        }
      }
    }
  }
}

//===========================================
// UI * Material ui Modal Diloag - Category
//===========================================
.MuiDialogContent-root._modal-category {
  .MuiTypography-root {
    ._content {
      a.tag-selected-radio {
        div._title-selected._only {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          background-color: #f1f1f1;
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
          padding-top: 2px;
          padding-bottom: 2px;
          padding-right: 10px;
          overflow: hidden;
        }
      }

      a.tag-selected-radio {
        div._title-selected._only._multiple {
          display: block;
          align-items: center;
          justify-content: flex-start;

          background-color: #f1f1f1;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
          padding-top: 2px;
          padding-bottom: 2px;
          overflow: hidden;

          @include media-breakpoint-up(xs) {
            padding-right: 3px;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 68px;
          }

          @include media-breakpoint-up(md) {
            padding-right: 10px;
            white-space: normal;
            text-overflow: inherit;
            width: auto;
          }
        }
      }

      a.tag-selected-radio {
        background-color: #f1f1f1;
        display: inline-flex;
        width: auto;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px;
        text-decoration: none;
        border-radius: 25px;

        div._title-selected {
          font-family: "Comfortaa", cursive;
          background-color: #f1f1f1;
          padding-top: 2px;
          padding-bottom: 2px;
          padding-left: 20px;
          padding-right: 0;
          color: #909093;
          font-size: 11px;
          font-weight: 700;
          position: relative;
          overflow: hidden;
        }

        div._title-selected {
          &:first-child {
            padding-left: 4px;
          }

          &::after {
            content: "";
            display: none;
            width: 15px;
            height: 100%;
            background-color: royalblue;
            background: rgb(2, 0, 36);
            background: -moz-linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(241, 241, 241, 1) 60%);
            background: -webkit-linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(241, 241, 241, 1) 60%);
            background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(241, 241, 241, 1) 60%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#f1f1f1",GradientType=1);
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        ._title-selected {
          span._text {
            color: $color-gray;
          }
        }

        ._title-selected {
          span._text {
            &::after {
              display: block;
              content: "";
              height: 4px;
              width: 4px;
              background-color: #515157;
              border-radius: 100px;
              margin: 0 2px;
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              margin-top: auto;
              margin-bottom: auto;
              z-index: 9;
            }
          }
          &:first-child {
            padding-left: 5px;

            svg {
              margin-right: 5px;
              margin-left: 5px;

              path {
                fill: $color-gray;
              }
            }
            span._text {
              &::after {
                display: none;
              }
            }
          }
        }
      }

      ul#drilldown {
        li {
          label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 9.82px 0;
            margin: 0;
            cursor: pointer;

            input[type="radio"],
            input[type="checkbox"] {
              display: none;
              visibility: hidden;
            }

            span._name {
              display: block;
              color: $color-gray;
              font-size: 13px;
              font-weight: $fw400;
              font-family: $font-comfortaa;
            }

            input:checked ~ span._name {
              color: $color-gray-bolder;
              font-weight: $fw700;
            }

            div.icon {
              svg {
                width: 8px;
                height: auto;

                path {
                  fill: $color-gray-bolder;
                }
              }
            }
          }
        }
      }
    }
  }
}

//===========================================
// UI * Material ui Modal Diloag - Price
//===========================================
.MuiDialogContent-root._modal-price {
  .MuiTypography-root {
    ._content {
      ._label {
        font-family: $font-comfortaa;
        margin-bottom: 8px;
      }

      .price-info {
        font-family: $font-comfortaa;
        background-color: $color-box;
        font-size: 13px;
        font-weight: $fw400;
        color: $color-black;
        text-align: left;
        // padding: 20px 10px;
        margin-top: 10px;

        @include media-breakpoint-up(md) {
          margin-top: 15px;
        }

        h3 {
          font-size: 13px;
          font-weight: $fw500;
          color: $color-gray-bolder;
          line-height: 1.6;
          margin-top: 10px;
          padding: {
            left: 10px;
            right: 10px;
          }

          &:first-child {
            margin-top: 0;
            background: $color-ice;
            padding: {
              top: 10px;
              bottom: 10px;
              left: 10px;
              right: 10px;
            }
            font-weight: 700;
          }
        }

        ._accordion {
          ._accordion-head {
            border-left: 1px solid $color-ice;
            border-right: 1px solid $color-ice;

            ._visible-content {
              font-family: $font-nunito;
              font-weight: $fw600;
              opacity: 0.85;
              font-size: 0.875rem;
            }
          }
        }

        ul {
          padding: 10px;
          padding-bottom: 0;

          li {
            padding: 10px 0;
            border-bottom: 1px solid $color-ice;
            p {
              opacity: 1;
              font-size: 12px;
              font-weight: $fw700;
            }
          }
        }
      }
    }
  }
}
