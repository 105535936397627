#view_landing-page {
    position: fixed;
    top: 0;
    left: 0;
    width: $percent-100;
    height: $percent-100;
    z-index: 999;
    background-color: $color-white;
    overflow: hidden;
    overflow-y: scroll;

    .header {
        
        -webkit-box-shadow: 0 8px 14px 4px rgba(150,150,150,0.1);
        box-shadow: 0 8px 14px 4px rgba(150,150,150,0.1);
        border-bottom: 1px solid rgba(250,250,250,0.2);
        position: relative;
        z-index: 9;
        
        ._med-container {
            
            .wrap-header {
                
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 90px;
                
                @media (min-width: 240px) {
                    flex-direction: column;
                }
                
                @media (min-width: 768px) {
                    flex-direction: row;
                } 
                
                .logo-and-icon {
                    
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                    @media (min-width: 240px) {
                        max-width: 768px;
                        width: 100%;
                        margin-top: 20px;
                    }

                    @media (min-width: 768px) {
                        max-width: none;
                        width: auto;
                        margin-top: inherit;
                    }
                    
                    .logo {

                        a {

                            img {
                                width: 32px;
                            }

                        }

                    }
                    
                    .i-burger.active {
                        background-color: #ffffff;
                        
                        span {
                            
                             &:nth-child(1) {
                                transform: rotate(45deg);
                                transform-origin: 8px 2px;
                            }

                            &:nth-child(2) {
                                display: none;
                            }

                            &:nth-child(3) {
                                transform: rotate(-45deg);
                                transform-origin: 8px -2px;
                            } 
                        } 
                    }
                    
                    .i-burger {
                        display: block;
                        margin: 0;
                        padding: 8px 6px;
                        border: 0;
                        border-radius: 0;
                        background-color: transparent;
                        outline: 0;
                        
                        @media (min-width: 768px) {
                            display: none;
                        }
                        
                        span {
                            display: block;
                            height: 1px;
                            width: 24px;
                            background-color: #404040;
                            margin-bottom: 5px;
                            
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                
                .menu {
                    
                    @media (min-width: 240px) {
                        display: none;
                    }

                    @media (min-width: 768px) {
                        display: block !important;
                    }
                    
                    .nav {
                        
                        ul.list.toggle-flex {
                            flex-direction: column;
                        }
                        
                        ul.list {
                            
                            align-items: center;
                            justify-content: flex-start;
                            
                            @media (min-width: 768px) {
                                display: flex !important;
                            }
                            
                            li {
                                
                                @media (min-width: 240px) {
                                    text-align: center; 
                                }
                                
                                @media (min-width: 768px) {
                                   margin-left: 30px;
                                } 
                                
                                &:first-child {
                                    margin-left: 0;
                                }
                                
                                a {
                                    display: block;
                                    padding: 10px 0;
                                }
                            }
                        }
                        
                    }
                    
                }
                
            }
            
        }
        
    }
    
    .main-content {
        
        .image-gallery {

            max-width: 1350px;
            width: 100%;
            margin: 0 auto;

            .image-gallery-content {

                .image-gallery-icon.image-gallery-left-nav,
                .image-gallery-icon.image-gallery-right-nav {
                    svg {
                        width: 40px;
                        height: auto;
                    }

                    &:hover {
                        color: $color-red;
                    }
                }

                .image-gallery-bullets {
                    bottom: -24px !important;
                    display: block !important;

                    .image-gallery-bullets-container {

                        .image-gallery-bullet {
                            background-color: $color-nude;
                            height: 8px;
                            width: 8px;
                            border-color: $color-nude;
                        }
                        
                        .image-gallery-bullet.active {
                            width: 20px;
                            background-color: $color-violet;
                            border-color: $color-violet;
                        }
                    }
                }

                .image-gallery-swipe {

                    .image-gallery-slides {

                        div {

                            div {

                                img {
                                    border-radius: 0;
                                }

                            }

                        }

                    }

                }

            }

        }
        
        .hero-1 {
            
            
            @media (min-width: 240px) {
                padding: 40px 0 40px 0;
            }
            
            @media (min-width: 768px) {
                padding: 60px 0 40px 0;
            }
            
            @media (min-width: 992px) {
                padding: 100px 0 60px 0;
            }
            
            ._med-container {
                
                .hero-content {
                    
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    
                    @media (min-width: 240px) {
                        flex-direction: column;
                    }
                    
                    @media (min-width: 768px) {
                        flex-direction: row;
                    }
                    
                    
                    .column-left {
                        //margin-right: 70px;
                        
                        @media (min-width: 768px) {
                            min-width: 360px;
                            margin-right: 30px;
                        }
                        
                        @media (min-width: 992px) {
                            min-width: 428px;
                            margin-right: 60px;
                        }
                        
                        @media (min-width: 1140px) {
                            min-width: 528px;
                        }
                        
                        img {
                            max-width: 528px;
                            width: 100%;
                            
                            @media (min-width: 240px) {
                                max-width: 240px;
                            }
                            
                            @media (min-width: 768px) {
                                max-width: 428px;
                            }
                            
                            @media (min-width: 1140px) {
                                max-width: 528px;
                            }
                            
                        }
                    }
                    
                    .column-right {
                        
                        @media (min-width: 240px) {
                            margin-top: 30px;
                        }
                        
                        h1 {
                            
                            font-size: 44px;
                            @media (min-width: 240px) {
                                font-size: 24px;
                                text-align: center;
                            }
                            
                            @media (min-width: 768px) {
                                font-size: 28px;
                                text-align: left;
                            }
                            
                            @media (min-width: 992px) {
                                font-size: 36px;
                            }
                            
                            @media (min-width: 1140px) {
                                font-size: 44px;
                            }
                            
                            br {
                                display: inline-block;
                            }
                        }
                        
                        h2 {
                            font-size: 20px;
                            line-height: 1.8;
                            margin-top: 10px;
                            
                            @media (min-width: 240px) {
                                font-size: 14px;
                                text-align: center;
                                margin-bottom: 10px;
                            }
                            
                            @media (min-width: 768px) {
                                max-width: 640px;
                                font-size: 15px;
                                text-align: left;
                            }
                            
                            @media (min-width: 992px) {
                                max-width: none;
                                font-size: 18px;
                                margin-bottom: 30px;
                            }
                            
                            @media (min-width: 1140px) {
                                font-size: 20px;
                            }
                        }
                        
                        @media (min-width: 240px) {
                            .main-btn {
                                display: none;
                            }
                        }
                        
                        @media (min-width: 768px) {
                            .main-btn {
                                display: block;
                            }
                        }
                        
                    }
                    
                }
                
            }
            
        }
        
        .hero-2 {
            
            @media (min-width: 240px) {
                padding: 15px 0 30px 0;
            }
            
            @media (min-width: 768px) {
                padding: 60px 0 40px 0;
            }
            
            @media (min-width: 992px) {
                padding: 60px 0 60px 0;
            }
            
            ._med-container {
                
                .hero-content {
                    
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    
                    @media (min-width: 240px) {
                        flex-direction: column;
                    }
                    
                    @media (min-width: 768px) {
                        flex-direction: row;
                    }
                    
                    .column-left {
                        
                        @media (min-width: 240px) {
                            margin-top: 30px;
                        }
                        
                        h1 {
                            
                            font-size: 44px;
                            @media (min-width: 240px) {
                                font-size: 24px;
                                text-align: center;
                            }
                            
                            @media (min-width: 768px) {
                                font-size: 28px;
                                text-align: left;
                            }
                            
                            @media (min-width: 992px) {
                                font-size: 36px;
                            }
                            
                            @media (min-width: 1140px) {
                                font-size: 44px;
                            }
                            
                            br {
                                display: inline-block;
                            }
                        }
                        
                        h2 {
                            font-size: 20px;
                            line-height: 1.8;
                            margin-top: 10px;
                            
                            @media (min-width: 240px) {
                                font-size: 14px;
                                text-align: center;
                                margin-bottom: 20px;
                            }
                            
                            @media (min-width: 768px) {
                                max-width: 640px;
                                font-size: 15px;
                                text-align: left;
                            }
                            
                            @media (min-width: 992px) {
                                max-width: none;
                                font-size: 18px;
                                margin-bottom: 30px;
                            }
                            
                            @media (min-width: 1140px) {
                                font-size: 20px;
                            }
                        }
                        
                        @media (min-width: 240px) {
                            .main-btn {
                                display: none;
                            }
                        }
                        
                        @media (min-width: 768px) {
                            .main-btn {
                                display: block;
                            }
                        }
                    }
                    
                    .column-right {
                        //margin-right: 70px;
                        
                        @media (min-width: 768px) {
                            min-width: 360px;
                            margin-right: 30px;
                        }
                        
                        @media (min-width: 992px) {
                            min-width: 428px;
                            margin-right: 60px;
                        }
                        
                        @media (min-width: 1140px) {
                            min-width: 528px;
                        }
                        
                        img {
                            max-width: 528px;
                            width: 100%;
                            
                            @media (min-width: 240px) {
                                max-width: 240px;
                            }
                            
                            @media (min-width: 768px) {
                                max-width: 428px;
                            }
                            
                            @media (min-width: 1140px) {
                                max-width: 488px;
                            }
                            
                        }
                    }
                    
                }
                
            }
            
        }
        
        .between-sessions {
            
            ._med-container {
                
                .main-btn {
                    margin-bottom: 10px;
                }
                
                @media (min-width: 240px) {
                    .main-btn {
                        display: block;
                    }
                }

                @media (min-width: 768px) {
                    .main-btn {
                        display: none;
                    }
                }
                
            }
            
        }
        
        // .it-works {
            
        //     padding: 60px 0;
            
        //     ._med-container {
                
        //         @media (min-width: 240px) {
        //             padding: 0;
        //         }
                
        //         .headline {
        //             margin-bottom: 230px;
                    
        //             @media (min-width: 240px) {
        //                 font-size: 30px;
        //                 margin-bottom: 140px;
        //             }
                    
        //             @media (min-width: 768px) {
        //                 font-size: 36px;
        //                 margin-bottom: 180px;
        //             }
                    
        //             @media (min-width: 992px) {
        //                 font-size: 40px;
        //                 margin-bottom: 200px;
        //             }
                    
        //             @media (min-width: 1200px) {
        //                 font-size: 44px;
        //                 margin-bottom: 230px;
        //             }
        //         }
                
        //         .slider-works {
                    
        //             position: relative;
                    
        //             .slider-works-item {
                        
        //                 display: flex;
        //                 align-items: center;
        //                 justify-content: space-between;
        //                 max-width: 846px;
        //                 margin: 0 auto;
        //                 margin-top: 0;
                        
        //                 @media (min-width: 240px) {
        //                     flex-direction: column;
        //                     text-align: center;
        //                 }
                        
        //                 @media (min-width: 992px) {
        //                     flex-direction: row;
        //                     text-align: left;
        //                 }
                        
        //                 .info {
                            
        //                     max-width: 400px;
        //                     min-width: 400px;
                            
        //                     h1 {
        //                         font-size: 30px;
        //                         margin-bottom: 10px;
                                
        //                         @media (min-width: 240px) {
        //                             font-size: 26px;
        //                         }
                                
        //                         @media (min-width: 768px) {
        //                             font-size: 28px;
        //                         }
                                
        //                         @media (min-width: 992px) {
        //                             font-size: 30px;
        //                         }
        //                     }
                            
        //                     h2 {
        //                         line-height: 1.7;
                                
        //                         @media (min-width: 240px) {
        //                             font-size: 16px;
        //                             max-width: 290px;
        //                             margin: 0 auto;
        //                         }
                                
        //                         @media (min-width: 768px) {
        //                             font-size: 18px;
        //                             max-width: 380px;
        //                         }
                                
        //                         @media (min-width: 768px) {
        //                             margin-left: 0;
        //                         }
        //                     }
                            
        //                 }
                        
        //                 .image {
                            
        //                     img {
        //                         @media (min-width: 240px) {
        //                             max-width: 240px;
        //                             margin-top: 20px;
        //                         }
                                
        //                         @media (min-width: 768px) {
        //                             max-width: 280px;
        //                             margin-top: 20px;
        //                         }
                                
        //                         @media (min-width: 992px) {
        //                             max-width: 390px;
        //                             margin-top: 0;
        //                         }
        //                     }
                            
        //                 }
                        
        //             }
                    
        //             .owl-dots {
        //                 display: block;
        //                 position: absolute;
        //                 top: -160px;
        //                 left: 0;
        //                 right: 0;
                        
        //                 @media (min-width: 240px) {
        //                     top: -105px;
        //                 }
                        
        //                 @media (min-width: 768px) {
        //                     top: -140px;
        //                 }
                        
        //                 @media (min-width: 992px) {
        //                     top: -150px;
        //                 }
                        
        //                 @media (min-width: 1200px) {
        //                     top: -160px;
        //                 }
                        
        //                 .owl-dot.active {
        //                     span {
        //                         height: 124px;
        //                         width: 124px;
        //                         border: 10px solid #f9fafc;
        //                         font-size: 66px;
                                
        //                         @media (min-width: 240px) {
        //                             width: 64px;
        //                             height: 64px;
        //                             font-size: 26px;
        //                             border: 6px solid #f9fafc;
        //                         }
                                
        //                         @media (min-width: 768px) {
        //                             width: 84px;
        //                             height: 84px;
        //                             font-size: 32px;
        //                             border: 8px solid #f9fafc;
        //                         }
                                
        //                         @media (min-width: 992px) {
        //                             width: 104px;
        //                             height: 104px;
        //                             font-size: 44px;
        //                             border: 10px solid #f9fafc;
        //                         }
                                
        //                         @media (min-width: 1200px) {
        //                             width: 124px;
        //                             height: 124px;
        //                             font-size: 64px;
        //                         }
        //                     }
        //                 }
                        
        //                 .owl-dot {
        //                     position: relative;
        //                     span {
        //                         height: 84px;
        //                         width: 84px;
        //                         background-color: #f5f6fa;
        //                         border: 10px solid #f9fafc;
        //                         border-radius: 100%;
        //                         display: flex;
        //                         align-items: center;
        //                         justify-content: center;
        //                         font-family: $font-comfortaa;
        //                         font-size: 44px;
        //                         color: $color-red;
        //                         font-weight: $fw700;
                                
        //                         @media (min-width: 240px) {
        //                             width: 44px;
        //                             height: 44px;
        //                             font-size: 20px;
        //                             border: 4px solid #f9fafc;
        //                         }
                                
        //                         @media (min-width: 768px) {
        //                             width: 54px;
        //                             height: 54px;
        //                             font-size: 24px;
        //                             border: 8px solid #f9fafc;
        //                         }
                                
        //                         @media (min-width: 992px) {
        //                             width: 64px;
        //                             height: 64px;
        //                             font-size: 32px;
        //                             border: 10px solid #f9fafc;
        //                         }
                                
        //                         @media (min-width: 1200px) {
        //                             width: 84px;
        //                             height: 84px;
        //                             font-size: 44px;
        //                         }
        //                     }
        //                 }
                        
        //                 .owl-dot:nth-child(1) {
        //                     span:after {
        //                         content: '1';
        //                         display: block;
        //                     }
        //                 }
                        
        //                 .owl-dot:nth-child(2) {
        //                     span:after {
        //                         content: '2';
        //                         display: block;
        //                     }
        //                 }
                        
        //                 .owl-dot:nth-child(3) {
        //                     span:after {
        //                         content: '3';
        //                         display: block;
        //                     }
        //                 }
                        
        //                 .owl-dot:nth-child(4) {
        //                     span:after {
        //                         content: '4';
        //                         display: block;
        //                     }
        //                 }
                        
        //                 .owl-dot:nth-child(5) {
        //                     span:after {
        //                         content: '5';
        //                         display: block;
        //                     }
        //                 }
        //             }
                    
        //             .owl-nav {
                        
        //                 display: flex;
        //                 align-items: center;
        //                 justify-content: space-between;
        //                 position: absolute;
        //                 width: 100%;
        //                 top: 0;
        //                 bottom: 0;
                        
        //                 .owl-next,
        //                 .owl-prev {
        //                     outline: 0;
        //                     width: 50px;
        //                     height: 170px;
        //                     background-color: $color-blue;
        //                     border-radius: 0;
        //                     display: flex;
        //                     align-items: center;
        //                     justify-content: center;
                            
        //                     @media (min-width: 240px) {
        //                         width: 30px;
        //                         height: 100px;
        //                     }
                            
        //                     @media (min-width: 768px) {
        //                         width: 40px;
        //                         height: 130px;
        //                     }
                            
        //                     @media (min-width: 1200px) {
        //                         width: 50px;
        //                         height: 170px;
        //                     }
                            
        //                     span {
        //                         font-size: 94px;
        //                         color: #fff;
        //                         height: 160px;
        //                         width: 50px;
                                
        //                         @media (min-width: 240px) {
        //                             width: 25px;
        //                             height: 80px;
        //                             font-size: 44px;
        //                         }
                                
        //                         @media (min-width: 768px) {
        //                             width: 40px;
        //                             height: 85px;
        //                             font-size: 44px;
        //                         }
                                
        //                         @media (min-width: 992px) {
        //                             font-size: 54px;
        //                             height: 95px;
        //                         }
                                
        //                         @media (min-width: 1200px) {
        //                             font-size: 84px;
        //                             height: 160px;
        //                         }
        //                     }
        //                 }
                        
        //             }
                    
        //         }
                
        //     }
            
        // }
        
        .faq {
            
            @media (min-width: 240px) {
                padding: 40px 0 40px 0;
            }
            
            @media (min-width: 768px) {
                padding: 60px 0 40px 0;
            }
            
            @media (min-width: 992px) {
                padding: 60px 0 60px 0;
            }
            
            ._med-container {
                
                .headline {
                    justify-content: center;
                    @media (min-width: 240px) {
                        font-size: 30px;
                    }
                    
                    @media (min-width: 768px) {
                        font-size: 36px;
                    }
                    
                    @media (min-width: 992px) {
                        font-size: 40px;
                    }
                    
                    @media (min-width: 1200px) {
                        font-size: 44px;
                    }
                }
                
                #accordion {
                    
                    margin-top: 60px;
                    
                    @media (min-width: 240px) {
                        margin-top: 30px;
                    }

                    @media (min-width: 768px) {
                        margin-top: 40px;
                    }

                    @media (min-width: 992px) {
                        margin-top: 60px;
                    }
                    
                    .card {

                        border: 0;
                        border-bottom: 1px solid #f1f1f1;

                        .card-header {
                            background-color: $color-white;
                            padding-left: 0;

                            button.btn.btn-link {
                                
                                color: $color-black;
                                width: $percent-100;
                                max-width: none;
                                text-align: left;
                                padding-left: 0;

                                @media (min-width: 240px) {
                                    font-size: 14px;
                                    padding-right: 15px;
                                }
                                
                                @media (min-width: 768px) {
                                    font-size: 20px;
                                }

                                &:hover,
                                &:focus {
                                    text-decoration: none;
                                }
                            }
                        }

                        .collapse {
                            .card-body {
                                padding-left: 0;
                                max-width: 800px;
                                line-height: 1.7;
                                
                                @media (min-width: 240px) {
                                    font-size: 14px;
                                }

                                @media (min-width: 768px) {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                    
                }
                
            }
            
        }
        
        .mosaic {
            
             @media (min-width: 240px) {
                padding: 20px 0 40px 0;
            }
            
            @media (min-width: 768px) {
                padding: 60px 0;
            }
            
            .mosaic-image {
                img {
                    max-width: 1349px;
                    margin: 0 auto;
                }
            }
            
        }
        
        .newsletter {
            
            padding: 60px 0;
            
            ._med-container {
                
                .newsletter-content {
                    
                    max-width: 670px;
                    margin: 0 auto;
                    
                    h1 {
                        
                        margin-bottom: 15px;
                        
                        @media (min-width: 240px) {
                            font-size: 26px;
                        }
                        
                        @media (min-width: 992px) {
                            font-size: 30px;
                        }
                        
                        @media (min-width: 1200px) {
                            font-size: 36px;
                        }
                        
                    }
                    
                    h2 {
                        
                         @media (min-width: 240px) {
                            font-size: 16px;
                        }
                        
                        @media (min-width: 992px) {
                            font-size: 18px;
                        }
                        
                        @media (min-width: 1200px) {
                            font-size: 20px;
                        }
                        
                    }
                    
                    .newsletter-form {
                        
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 30px;
                        
                        @media (min-width: 240px) {
                            flex-direction: column;
                        }
                        
                        @media (min-width: 768px) {
                            flex-direction: row;
                        }
                        
                        .form-group {
                            input[type=text],
                            input[type=email],
                            button[type=submit],
                            button[type=button] {
                                font-family: $font-comfortaa;
                            }
                        }
                        
                        .form-group {
                            
                            input {
                                max-width: 472px;
                                min-width: 472px;
                                width: 100%;
                                border: 1px solid #f2f3f5;
                                height: 50px;
                                border-radius: 50px;
                                font-size: 14px;
                                padding: 0 15px;
                                outline: 0;
                                
                                @media (min-width: 240px) {
                                    min-width: 290px;
                                    height: 44px;
                                }
                                
                                @media (min-width: 768px) {
                                    min-width: 472px;
                                    height: 48px;
                                }
                                
                                @media (min-width: 992px) {
                                    height: 50px;
                                }
                            }
                            
                            button {
                                display: block;
                                height: 50px;
                                max-width: 187px;
                                min-width: 187px;
                                width: 100%;
                                background-color: $color-red;
                                border-radius: 50px;
                                border: 0;
                                color: $color-white;
                                outline: 0;
                                font-weight: $fw700;
                                font-size: 15px;
                                
                                @media (min-width: 240px) {
                                    height: 44px;
                                }
                                
                                @media (min-width: 768px) {
                                    height: 48px;
                                }
                                
                                @media (min-width: 992px) {
                                    height: 50px;
                                }
                            }
                            
                        }
                        
                    }
                    
                }
                
            }
            
        }
        
        
    }
    
    .footer {

        background-color: #f5f6fa;

        @media (min-width: 240px) {
            padding: 40px 0;
        }

        @media (min-width: 768px) {
            padding: 60px 0;
        }

        ._med-container {

            .row {

                .scamb-col.border-none {
                    border-bottom: 0;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }

                .scamb-col {
                    @media (min-width: 240px) {
                        padding-bottom: 15px;
                        margin-bottom: 15px;
                        border-bottom: 1px solid #f1f1f1;
                    }

                    @media (min-width: 768px) {
                        padding-bottom: 0;
                        margin-bottom: 0;
                        border-bottom: 0;
                    }    
                }

                .scamb-col-1 {

                    @media (min-width: 240px) {
                        padding-bottom: 30px;
                        margin-bottom: 30px;
                        border-bottom: 1px solid #f1f1f1;
                    }

                    @media (min-width: 768px) {
                        padding-bottom: 0;
                        margin-bottom: 0;
                        border-bottom: 0;
                    }

                    .logo-footer {

                        a {
                            display: block;
                            margin-bottom: 15px;
                            margin-top: 10px;

                            img {
                                @media (min-width: 240px) {
                                    width: 36px;
                                }
                            }
                        }

                        p {

                            @media (min-width: 240px) {
                                font-size: 12px;
                            }

                        }

                    }

                }

                .scamb-col {

                    nav {
                        ul {
                            li {
                                padding: 10px ;

                                a {
                                    color: #6e6e71;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    font-size: 15px;

                                    img {
                                        margin-right: 8px;
                                    }
                                }
                            }
                        }
                    }
                    
                    nav.social {
                        
                        ul {
                            
                            li {
                                margin-left: 15px;
                                
                                a {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: $color-blue;
                                    height: 44px;
                                    width: 44px;
                                    min-height: 44px;
                                    min-width: 44px;
                                    border-radius: 100%;
                                    
                                    img {
                                        width: 18px;
                                        margin: 0;
                                    }
                                }
                                
                                &:first-child {
                                    margin-left: 0;
                                }

                            }
                            
                            li:nth-child(1) {
                                a {
                                    &:hover {
                                        background-color: #16599b;
                                    }
                                }
                            }

                            li:nth-child(2) {
                                a {
                                    &:hover {
                                        background-color: #e1306c;
                                    }
                                }
                            }

                            li:nth-child(3) {
                                a {
                                    &:hover {
                                        background-color: #2867b2;
                                    }
                                }
                            }
                            
                        }
                        
                    }

                }

                .scamb-col.scamb-col-4 {

                    h1 {
                        font-size: 16px;
                        margin-top: 10px;
                    }

                    nav {

                        margin-top: 15px;

                        ul {

                            display: flex;
                            align-items: center;
                            justify-content: flex-start;

                            li {
                                padding: 0;
                            }

                        }

                    }

                }

            }

        }

    }
        
        .footnote {
        
        background-color: #f1f2f5;
        
        ._med-container {
            
            .footnote-content {
                
                display: flex;
                align-items: center;
                
                
                @media (min-width: 240px) {
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    height: 75px;
                }
                
                @media (min-width: 768px) {
                    flex-direction: row;
                    text-align: center;
                    height: 50px;
                }
                
                .copy-c {
                    font-size: 13px;
                    color: #6d6d70;
                }
                
                .links {
                    
                    color: #909ba6;
                    
                    span {
                        display: inline-block;
                        margin: 0 5px;
                    }
                    
                    a {
                        color: #6d6d70;
                        font-size: 13px;
                    }
                    
                }
                
            }
            
        }
        
    }
   
}



//====================================
// Aditional Styles
//====================================

// Btn
.main-btn {
    
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-red;
        color: $color-white;
        font-family: $font-poppins;
        font-weight: 800;
        text-transform: uppercase;
        position: relative;
        
        @media (min-width: 240px) {
            width: 200px;
            height: 40px;
            margin: 0 auto;
        }
        
        @media (min-width: 768px) {
            margin-left: 0;
        }
        
        @media (min-width: 768px) {
            width: 240px;
            height: 50px;
        }
        
        span {
            font-family: $font-comfortaa;
            display: inline-block;
//            position: absolute;
//            bottom: 10px;
//            left: 15px;
            line-height: 0.9;
            
            @media (min-width: 240px) {
                font-size: 18px;
                letter-spacing: 1px;
            }
            
            @media (min-width: 992px) {
                font-size: 22px;
                letter-spacing: 1px;
            }
        }
        
        &:after {
            display: block;
            content: '';
            position: absolute;
            background-color: $color-red;
            opacity: 0.5;
            height: 100%;
            width: 100%;
            top: 5px !important;
            left: -5px !important;
            z-index: -1;
            -webkit-transition: all 0.25s ease;
            -moz-transition: all 0.25s ease;
            -ms-transition: all 0.25s ease;
            -o-transition: all 0.25s ease;
            transition: all 0.25s ease;
        }
        
        &:hover {
            
            &:after {
                top: 0 !important;
                left: 0 !important;
                -webkit-transition: all 0.25s ease;
                -moz-transition: all 0.25s ease;
                -ms-transition: all 0.25s ease;
                -o-transition: all 0.25s ease;
                transition: all 0.25s ease;
            }
            
        }
    }
    
}

// Submit Btn
.submit-btn {
    display: block;
    background-color: $color-red;
    color: $color-white;
    font-family: $font-poppins;
    font-weight: 800;
    text-transform: uppercase;
    position: relative;
    border: 0;

    @media (min-width: 240px) {
        width: 200px;
        height: 40px;
        margin: 0 auto;
    }

    @media (min-width: 768px) {
        margin-left: 0;
    }

    @media (min-width: 768px) {
        width: 240px;
        height: 50px;
    }

    span {
        display: block;
        position: absolute;
        bottom: 10px;
        left: 15px;
        line-height: 0.9;
        font-family: $font-comfortaa;

        @media (min-width: 240px) {
            font-size: 18px;
            letter-spacing: 1px;
        }

        @media (min-width: 992px) {
            font-size: 22px;
            letter-spacing: 1px;
        }
    }

    &:after {
        display: block;
        content: '';
        position: absolute;
        background-color: $color-red;
        opacity: 0.5;
        height: 100%;
        width: 100%;
        top: 5px;
        left: -5px;
        z-index: -1;
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -ms-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
    }

    &:hover {

        &:after {
            top: 0;
            left: 0;
            -webkit-transition: all 0.25s ease;
            -moz-transition: all 0.25s ease;
            -ms-transition: all 0.25s ease;
            -o-transition: all 0.25s ease;
            transition: all 0.25s ease;
        }

    }
}
    

// Accordion Bootstrap

.panel-title > a:before {
    float: right !important;
    display: block;
    // content: url("../../#{$path-image}/institucional/landing-page/chevron-down.png");
    transform: rotate(0);
    padding-right: 0;
}
.panel-title > a.collapsed:before {
    float: right !important;
    // content: url("../../images/institucional/landing-page/chevron-down.png");
    transform: rotate(0deg);
}
.panel-title > a:hover, 
.panel-title > a:active, 
.panel-title > a:focus  {
    text-decoration:none;
}

// Modal

.modal.modal-scamb {
    
    .modal-dialog {
        
        .modal-content {
            
            .modal-header {
                border-bottom: 0;
            }
            
            .modal-body.scamb-contact-form {
                
                h1 {
                    margin-bottom: 30px;
                    
                    @media (min-width: 240px) {
                        font-size: 26px;
                    }
                    
                    @media (min-width: 768px) {
                        font-size: 30px;
                    }
                    
                    @media (min-width: 992px) {
                        font-size: 34px;
                    }
                }
                
                .manifest {
                    
                    .screen {
                        img {
                            width: 100%;
                        }
                    }
                    
                }
                
                form {
                    
                    max-width: 730px;
                    margin: 0 auto;
                    padding-bottom: 20px;
                    
                    div,
                    p {
                        
                        label {
                            
                            width: 100%;
                            
                            span {
                                
                                input {
                                    width: 100%;
                                }
                                
                                textarea {
                                    height: 118px;
                                    resize: none;
                                    width: 100%;
                                }
                                
                                input,
                                textarea {
                                    font-family: $font-comfortaa;
                                    border: 1px solid #f2f3f5;
                                    font-size: 14px;
                                    padding: 11px;
                                        
                                    &::placeholder {
                                        color: #868688;
                                    }
                                }
                                
                            }
                            
                        }
                        
                    }
                    
                    .form-group {
                        
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        
                        @media (min-width: 240px) {
                            flex-direction: column;
                        }
                        
                        @media (min-width: 992px) {
                            flex-direction: row;
                        }
                        
                        .form-group-item {
                            
                            @media (min-width: 240px) {
                                &:first-child {
                                    margin-bottom: 10px;
                                }
                            }
                            
                            @media (min-width: 992px) {
                                &:first-child {
                                    margin-bottom: 0;
                                }
                            }
                            
                            span {
                                
                                input {
                                    
                                    @media (min-width: 240px) {
                                        max-width: inherit;    
                                        min-width: inherit;
                                        width: 100%;
                                    }
                                    
                                    @media (min-width: 992px) {
                                        max-width: 360px;    
                                        min-width: 360px;
                                    }
                                }
                                
                                input,
                                textarea {
                                    font-family: $font-comfortaa;
                                    border: 1px solid #f2f3f5;
                                    font-size: 14px;
                                    padding: 11px;
                                        
                                    &::placeholder {
                                        color: #868688;
                                    }
                                }
                                
                            }
                            
                        }
                        
                    }
                    
                    .wrap-submit-btn {
                        
                        .submit-btn {
                            margin: 0 auto;
                        }
                        
                        p {
                            text-align: center;
                            
                            .submit-btn {
                                margin: 0 auto;
                            }
                            
                        }
                        
                    }
                    
                }
                
            }
            
        }
        
    }
    
}


#view_landing-page  {
	.main-content {
		.it-works {
			._med-container {
				.headline {
                    margin-bottom: 230px;
                    justify-content: center;
				}
				.works-box {
					margin-bottom: 60px;
					.works-box-group.flex {
						display: flex;
						align-items: center;
						.box-image {
							img {
								width: 100%;
							}
						}
						.box-text {
							max-width: 594px;
							width: 100%;
							p {
								line-height: 1.8;
								span.first-letter {
									display: inline-block;
									line-height: 1;
									position: relative;
									top: 8px;
									color: #202a44;
								}
								.white-space {
									display: block;
									height: 20px;
									width: 100%;
								}
							}
						}
					}
					.works-box-group.flex.reverse {
						.box-text {
							max-width: 594px;
							width: 100%;
						}
					}
				}
			}
		}
	}
}
@media (min-width: 240px) {
	#view_landing-page  {
		.main-content {
			.it-works {
				._med-container {
					.headline {
						font-size: 24px;
						margin-bottom: 30px;
					}
					.works-box {
						.works-box-group.flex {
							flex-direction: column;
							justify-content: center;
							.box-image {
								max-width: 200px;
								min-width: 200px;
								margin-bottom: 10px;
							}
							.box-text {
								p {
									font-size: 14px;
									text-align: center;
									span.first-letter {
										font-size: 32px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 768px) {
	#view_landing-page  {
		.main-content {
			.it-works {
				._med-container {
					.headline {
						font-size: 28px;
						margin-bottom: 50px;
					}
					.works-box {
						.works-box-group.flex {
							flex-direction: row;
							justify-content: center;
							.box-image {
								max-width: 320px;
								min-width: 320px;
								margin-bottom: 0;
								max-width: 435px;
								min-width: 435px;
							}
							.box-text {
								margin-left: 30px;
								p {
									font-size: 16px;
									text-align: left;
									span.first-letter {
										font-size: 42px;
									}
								}
							}
						}
						.works-box-group.flex.reverse {
							flex-direction: row-reverse;
							.box-text {
								margin-left: 0;
								margin-right: 30px;
							}
						}
						.works-box-group.flex.t3 {
							.box-image {
								max-width: 390px;
								min-width: 390px;
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 992px) {
	#view_landing-page  {
		.main-content {
			.it-works {
				._med-container {
					.headline {
						font-size: 36px;
						margin-bottom: 60px;
					}
					.works-box {
						.works-box-group.flex {
							.box-text {
								margin-left: 50px;
								p {
									font-size: 18px;
								}
							}
						}
						.works-box-group.flex.reverse {
							.box-text {
								margin-right: 50px;
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 1200px) {
	#view_landing-page  {
		.main-content {
			.it-works {
				._med-container {
					.headline {
						font-size: 44px;
					}
					.works-box {
						.works-box-group.flex {
							.box-text {
								p {
									font-size: 20px;
								}
							}
						}
					}
				}
			}
		}
	}
}


// #################################################
// NEWSLETTER
// #################################################

#view_landing-page {
	.main-content {
		.newsletter {
			._med-container {
				.newsletter-content {
					max-width: 670px;
					margin: 0 auto;
					h1 {
						margin-bottom: 15px;
					}
					#mc_embed_signup {
						.newsletter-form {
							#mc_embed_signup_scroll {
								display: flex;
								align-items: center;
								justify-content: space-between;
								margin-top: 30px;
								.form-group {
									input[type=text] {
										font-family: "Comfortaa";
									}
									input[type=email] {
										font-family: "Comfortaa";
									}
									button[type=submit] {
										font-family: "Comfortaa";
									}
									button[type=button] {
										font-family: "Comfortaa";
									}
									input {
										max-width: 472px;
										min-width: 472px;
										width: 100%;
										border: 1px solid #f2f3f5;
										height: 50px;
										border-radius: 50px;
										font-size: 14px;
										padding: 0 15px;
										outline: 0;
										&:focus {
											outline: 0;
										}
									}
									input[type=submit] {
										display: block;
										height: 50px;
										max-width: 187px;
										min-width: 187px;
										width: 100%;
										background-color: #c2403f;
										border-radius: 50px;
										border: 0;
										color: #fff;
										outline: 0;
										font-weight: 700;
										font-size: 15px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 240px) {
	#view_landing-page {
		.main-content {
			.newsletter {
				._med-container {
					.newsletter-content {
						h1 {
							font-size: 26px;
						}
						h2 {
							font-size: 16px;
						}
						#mc_embed_signup {
							.newsletter-form {

                                margin-top: 0;
                                
								#mc_embed_signup_scroll {
									flex-direction: column;
									.form-group {
										input {
											min-width: 290px;
											height: 44px;
										}
										input[type=submit] {
											height: 44px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 992px) {
	#view_landing-page {
		.main-content {
			.newsletter {
				._med-container {
					.newsletter-content {
						h1 {
							font-size: 30px;
						}
						h2 {
							font-size: 18px;
						}
						#mc_embed_signup {
							.newsletter-form {
								#mc_embed_signup_scroll {
									.form-group {
                                        
										input {
											height: 50px;
										}
										input[type=submit] {
											height: 50px;
										}
                                    }

                                    .form-group.clear {
                                        margin-left: 10px;
                                    }
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 1200px) {
	#view_landing-page {
		.main-content {
			.newsletter {
				._med-container {
					.newsletter-content {
						h1 {
							font-size: 36px;
						}
						h2 {
							font-size: 20px;
						}
					}
				}
			}
		}
	}
}
@media (min-width: 768px) {
	#view_landing-page {
		.main-content {
			.newsletter {
				._med-container {
					.newsletter-content {
						#mc_embed_signup {
							.newsletter-form {
								#mc_embed_signup_scroll {
									flex-direction: row;
									.form-group {
										input {
											min-width: 472px;
											height: 48px;
										}
										input[type=submit] {
											height: 48px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
